var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "destinations-component" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "destinations-container" }, [
      _c(
        "div",
        { staticClass: "destinations-inner-container" },
        _vm._l(_vm.destinations, function (destination, key) {
          return _c("div", { key: key, staticClass: "flex width-percent" }, [
            key % 4 == 0
              ? _c("div", { staticClass: "top" }, [
                  _c(
                    "div",
                    { staticClass: "destination-component-container" },
                    [
                      _c("destination-component", {
                        attrs: {
                          name: destination.name,
                          img: destination.img,
                          id: key,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : key % 4 == 1
              ? _c("div", { staticClass: "middle" }, [
                  _c(
                    "div",
                    { staticClass: "destination-component-container" },
                    [
                      _c("destination-component", {
                        attrs: {
                          name: destination.name,
                          img: destination.img,
                          id: key,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : key % 4 == 2
              ? _c("div", { staticClass: "bottom" }, [
                  _c(
                    "div",
                    { staticClass: "destination-component-container" },
                    [
                      _c("destination-component", {
                        attrs: {
                          name: destination.name,
                          img: destination.img,
                          id: key,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : key % 4 == 3
              ? _c("div", { staticClass: "middle" }, [
                  _c(
                    "div",
                    { staticClass: "destination-component-container" },
                    [
                      _c("destination-component", {
                        attrs: {
                          name: destination.name,
                          img: destination.img,
                          id: key,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titles-container" }, [
      _c("h3", [
        _vm._v("CON MAS DE 50 DESTINOS POSIBLES ENTRE ESPAÑA Y EUROPA"),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Vive tu viaje sorpresa en pareja")]),
      _vm._v(" "),
      _c("h3", { staticStyle: { "margin-top": "10px", color: "#212529" } }, [
        _vm._v(
          "Escapada sorpresa que incluye vuelos de Ida y Vuelta | 2 noches de hotel | toda la emoción\n            de vivir un viaje sorpresa que no descubrirás hasta 48 horas antes.\n        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }