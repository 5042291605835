var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "finalize-component" }, [
    _c("div", { staticClass: "breadcrumb-container" }, [
      _c("ul", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Inicio")])],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/calendar" } }, [
              _vm._v("Fechas"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/destinos" } }, [
              _vm._v("Destinos"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/extras" } }, [_vm._v("Extras")])],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "finalize-container" }, [
      _c(
        "div",
        { staticClass: "contact-data-container" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.adults, function (adult, key) {
            return _c("div", { key: "adult" + key, staticClass: "adults" }, [
              _c("div", { staticClass: "traveler-header" }, [
                _c("h3", [_vm._v("Adulto - Pasajero " + _vm._s(key + 1))]),
                _vm._v(" "),
                !_vm.is_gift
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.openForm(key)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/imgs/angle-down-solid.svg",
                            alt: "",
                            id: "open-traveler" + key,
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: key + 1 > 2 ? "hidden traveler-form" : "traveler-form",
                  attrs: { id: "form" + key },
                },
                [
                  _c("div", [
                    _c("input", {
                      attrs: {
                        type: "radio",
                        name: "gender" + key,
                        value: "Hombre",
                        checked: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "Hombre" } }, [
                      _vm._v("Hombre"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "radio",
                        name: "gender" + key,
                        value: "Mujer",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "Mujer" } }, [_vm._v("Mujer")]),
                  ]),
                  _vm._v(" "),
                  _vm._m(1, true),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", { attrs: { for: "birthday" } }, [
                      _vm._v("Fecha de nacimiento:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "birthday-container" }, [
                      _vm._m(2, true),
                      _vm._v(" "),
                      _vm._m(3, true),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          attrs: {
                            type: "number",
                            name: "birthday-year",
                            min: "1800",
                            max: _vm.current_year,
                            placeholder: "aaaa",
                            required: "",
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(4, true),
                  _vm._v(" "),
                  _vm._m(5, true),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", { attrs: { for: "expire-date" } }, [
                      _vm._v("Fecha de expiración:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "expire-date-container" }, [
                      _vm._m(6, true),
                      _vm._v(" "),
                      _vm._m(7, true),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          staticClass: "expire-date-year",
                          attrs: {
                            type: "number",
                            name: "expire-date-year",
                            min: _vm.current_year,
                            placeholder: "aaaa",
                            required: "",
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.kids, function (kid, key) {
            return _c("div", { key: "kid" + key, staticClass: "kids" }, [
              _c("div", { staticClass: "traveler-header" }, [
                _c("h3", [
                  _vm._v("Niño - Pasajero " + _vm._s(key + 1 + _vm.adults)),
                ]),
                _vm._v(" "),
                !_vm.is_gift
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.openKidForm(key)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/imgs/angle-down-solid.svg",
                            alt: "",
                            id: "open-kid-traveler" + key,
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class:
                    key + 1 + _vm.adults > 2
                      ? "hidden traveler-form"
                      : "traveler-form",
                  attrs: { id: "kid-form" + key },
                },
                [
                  _c("div", [
                    _c("input", {
                      attrs: {
                        type: "radio",
                        name: "genderkid" + key,
                        value: "Hombre",
                        checked: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "Hombre" } }, [
                      _vm._v("Hombre"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "radio",
                        name: "genderkid" + key,
                        value: "Mujer",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "Mujer" } }, [_vm._v("Mujer")]),
                  ]),
                  _vm._v(" "),
                  _vm._m(8, true),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", { attrs: { for: "birthday" } }, [
                      _vm._v("Fecha de nacimiento:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "birthday-container" }, [
                      _vm._m(9, true),
                      _vm._v(" "),
                      _vm._m(10, true),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          attrs: {
                            type: "number",
                            name: "birthday-year",
                            min: "1800",
                            max: _vm.current_year,
                            placeholder: "aaaa",
                            required: "",
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(11, true),
                  _vm._v(" "),
                  _vm._m(12, true),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", { attrs: { for: "expire-date" } }, [
                      _vm._v("Fecha de expiración:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "expire-date-container" }, [
                      _vm._m(13, true),
                      _vm._v(" "),
                      _vm._m(14, true),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          staticClass: "expire-date-year",
                          attrs: {
                            type: "number",
                            name: "expire-date-year",
                            min: _vm.current_year,
                            placeholder: "aaaa",
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.babies, function (baby, key) {
            return _c("div", { key: "baby" + key, staticClass: "babies" }, [
              _c("div", { staticClass: "traveler-header" }, [
                _c("h3", [
                  _vm._v(
                    "Bebé - Pasajero " + _vm._s(key + 1 + _vm.adults + _vm.kids)
                  ),
                ]),
                _vm._v(" "),
                !_vm.is_gift
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.openBabyForm(key)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/imgs/angle-down-solid.svg",
                            alt: "",
                            id: "open-baby-traveler" + key,
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class:
                    key + 1 + _vm.adults + _vm.kids > 2
                      ? "hidden traveler-form"
                      : "traveler-form",
                  attrs: { id: "baby-form" + key },
                },
                [
                  _c("div", [
                    _c("input", {
                      attrs: {
                        type: "radio",
                        name: "genderbaby" + key,
                        value: "Hombre",
                        checked: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "Hombre" } }, [
                      _vm._v("Hombre"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "radio",
                        name: "genderbaby" + key,
                        value: "Mujer",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "Mujer" } }, [_vm._v("Mujer")]),
                  ]),
                  _vm._v(" "),
                  _vm._m(15, true),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", { attrs: { for: "birthday" } }, [
                      _vm._v("Fecha de nacimiento:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "birthday-container" }, [
                      _vm._m(16, true),
                      _vm._v(" "),
                      _vm._m(17, true),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          attrs: {
                            type: "number",
                            name: "birthday-year",
                            min: "1800",
                            max: _vm.current_year,
                            placeholder: "aaaa",
                            required: "",
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(18, true),
                  _vm._v(" "),
                  _vm._m(19, true),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", { attrs: { for: "expire-date" } }, [
                      _vm._v("Fecha de expiración:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "expire-date-container" }, [
                      _vm._m(20, true),
                      _vm._v(" "),
                      _vm._m(21, true),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          staticClass: "expire-date-year",
                          attrs: {
                            type: "number",
                            name: "expire-date-year",
                            min: _vm.current_year,
                            placeholder: "aaaa",
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ])
          }),
          _vm._v(" "),
          _vm._m(22),
        ],
        2
      ),
      _vm._v(" "),
      _c("aside", { staticClass: "booking-data" }, [
        _c("h4", [_vm._v("TU RESERVA")]),
        _vm._v(" "),
        _c("div", { staticClass: "booking-container" }, [
          _c("h5", [_vm._v("INFORMACIÓN DEL VIAJE")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.adults) + " viajeros adultos")]),
          _vm._v(" "),
          _vm.kids > 0
            ? _c("p", [_vm._v(_vm._s(_vm.kids) + " viajeros niños")])
            : _vm._e(),
          _vm._v(" "),
          _vm.babies > 0
            ? _c("p", [_vm._v(_vm._s(_vm.babies) + " viajeros bebés")])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "booking-container" }, [
          _c("h5", [_vm._v("VUELO DE IDA: " + _vm._s(_vm.departure_date))]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Entre las " +
                _vm._s(_vm.departure_hour.start) +
                " y las " +
                _vm._s(_vm.departure_hour.end)
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-gap-20" }, [
            _c("span", { staticClass: "bold" }, [_vm._v(_vm._s(_vm.origin))]),
            _vm._v(" "),
            _vm._m(23),
            _vm._v(" "),
            _c("span", { staticClass: "bold" }, [_vm._v("SORPRESA")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "booking-container" }, [
          _c("h5", [_vm._v("VUELO DE VUELTA: " + _vm._s(_vm.return_date))]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Entre las " +
                _vm._s(_vm.return_hour.start) +
                " y las " +
                _vm._s(_vm.return_hour.end)
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-gap-20" }, [
            _c("span", { staticClass: "bold" }, [_vm._v("SORPRESA")]),
            _vm._v(" "),
            _vm._m(24),
            _vm._v(" "),
            _c("span", { staticClass: "bold" }, [_vm._v(_vm._s(_vm.origin))]),
          ]),
        ]),
        _vm._v(" "),
        _vm.discarded_destinations.length > 0
          ? _c("div", { staticClass: "booking-container" }, [
              _c("h5", [_vm._v("DESTINOS DESCARTADOS")]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(
                  _vm.discarded_destinations,
                  function (discarded_destination, key) {
                    return _c("li", { key: "destination" + key }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(discarded_destination)
                      ),
                    ])
                  }
                ),
                0
              ),
              _vm._v(" "),
              _c("p", [
                _c("b", [_vm._v("Precio:")]),
                _vm._v(" "),
                _c("span", { staticClass: "price-text" }, [
                  _vm._v(_vm._s(_vm.discarded_price) + "€"),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "booking-container" }, [
          _c("h5", [_vm._v("EXTRAS")]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Hotel:")]),
            _vm._v(" " + _vm._s(_vm.hotel) + "\n                    "),
            _vm.hotel_price > 0
              ? _c("span", [
                  _vm._v(" - "),
                  _c("span", { staticClass: "price-text" }, [
                    _vm._v(_vm._s(_vm.hotel_price) + "€"),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Seguro de cancelación:")]),
            _vm._v(
              " " +
                _vm._s(_vm.cancelation_insurance ? "Si" : "No") +
                "\n                    "
            ),
            _vm.cancelation_insurance
              ? _c("span", [
                  _vm._v(" - "),
                  _c("span", { staticClass: "price-text" }, [
                    _vm._v(
                      _vm._s(_vm.cancelation_insurance_price.toFixed(2)) + "€"
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("p", { staticStyle: { display: "none" } }, [
            _c("b", [_vm._v("Seguro de viaje:")]),
            _vm._v(
              " " +
                _vm._s(_vm.trip_insurance ? "Si" : "No") +
                "\n                    "
            ),
            _vm.trip_insurance
              ? _c("span", [
                  _vm._v(" - "),
                  _c("span", { staticClass: "price-text" }, [
                    _vm._v(_vm._s(_vm.trip_insurance_price) + "€"),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Viajar juntos:")]),
            _vm._v(
              " " +
                _vm._s(_vm.seat_together ? "Si" : "No") +
                "\n                    "
            ),
            _vm.seat_together
              ? _c("span", [
                  _vm._v(" - "),
                  _c("span", { staticClass: "price-text" }, [
                    _vm._v(_vm._s(_vm.seat_price) + "€"),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Maletas:")]),
            _vm._v(" " + _vm._s(_vm.baggage) + "\n                    "),
            _vm.baggage > 0
              ? _c("span", [
                  _vm._v(" - "),
                  _c("span", { staticClass: "price-text" }, [
                    _vm._v(_vm._s(_vm.baggage_price) + "€"),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.departure_hour_price > 0 || _vm.return_hour_price > 0
            ? _c("div", [
                _vm._m(25),
                _vm._v(" "),
                _vm.departure_hour_price > 0
                  ? _c("p", [
                      _vm._v(" Ida: "),
                      _c("span", { staticClass: "price-text" }, [
                        _vm._v(_vm._s(_vm.departure_hour_price) + "€"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.return_hour_price > 0
                  ? _c("p", [
                      _vm._v(" Vuelta: "),
                      _c("span", { staticClass: "price-text" }, [
                        _vm._v(_vm._s(_vm.return_hour_price) + "€"),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "booking-container" }, [
          _c("p", [
            _c("b", [_vm._v("Precio total:")]),
            _vm._v(" "),
            !_vm.discount_period
              ? _c("span", { staticClass: "price-text" }, [
                  _vm._v(_vm._s(_vm.total_price.toFixed(2)) + "€"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.discount_period
              ? _c(
                  "span",
                  {
                    staticClass: "price-text",
                    staticStyle: { "text-decoration": "line-through" },
                  },
                  [_vm._v(_vm._s((_vm.total_price + 30).toFixed(2)) + " € ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.discount_period
              ? _c("span", { staticClass: "price-text" }, [
                  _vm._v(_vm._s(_vm.total_price.toFixed(2)) + "€"),
                ])
              : _vm._e(),
            _vm._v(";\n                "),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.is_gift
      ? _c("div", [
          _c("p", { staticClass: "gift-message" }, [
            _vm._v(
              "\n            Te mandaremos la tarjeta de regalo personalizada a tu email, que incluirá un código para poder canjear\n            el viaje sorpresa.\n        "
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "accept-terms-container" }, [
      _vm.terms
        ? _c("input", {
            attrs: {
              type: "checkbox",
              name: "accept-terms",
              id: "accept-terms",
              checked: "",
            },
            on: {
              change: function ($event) {
                return _vm.toggleAcceptTerms()
              },
            },
          })
        : _c("input", {
            attrs: {
              type: "checkbox",
              name: "dont-accept-terms",
              id: "accept-terms",
            },
            on: {
              change: function ($event) {
                return _vm.toggleAcceptTerms()
              },
            },
          }),
      _vm._v("\n        Acepto los "),
      _c(
        "a",
        { attrs: { target: "v_blank", href: "imgs/TerminosYCondiciones.pdf" } },
        [_vm._v("terminos y condiciones.")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "finalize-button-container" }, [
      _c(
        "button",
        {
          staticClass: "disabled-button",
          attrs: { id: "finalize-button" },
          on: {
            click: function ($event) {
              return _vm.finalize("")
            },
          },
        },
        [_vm._v("Enviar petición")]
      ),
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "p",
        {
          class: _vm.error_message
            ? "finalize-message error-message"
            : "finalize-message",
        },
        [_vm._v(_vm._s(_vm.message))]
      ),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticStyle: { display: "none" },
        attrs: { action: "/payment", method: "POST", id: "form" },
      },
      [
        _c("input", {
          attrs: { type: "hidden", name: "_token" },
          domProps: { value: _vm.csrf },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "hidden",
            id: "bookingid",
            name: "bookingid",
            value: "",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact-data" }, [
      _c("h3", [_vm._v("Datos de contacto")]),
      _vm._v(" "),
      _c("div", { staticClass: "name-surname-grid" }, [
        _c("div", [
          _c("div", [
            _c("label", { attrs: { for: "client-name" } }, [_vm._v("Nombre")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "client-name" }, [
            _c("input", {
              attrs: {
                type: "text",
                id: "client-name",
                name: "client-name",
                required: "",
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", [
            _c("label", { attrs: { for: "client-surname" } }, [
              _vm._v("Apellidos"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "client-surname" }, [
            _c("input", {
              attrs: {
                type: "text",
                id: "client-surname",
                name: "client-surname",
                required: "",
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "contact-data-grid" }, [
        _c("div", [
          _c("div", [
            _c("label", { attrs: { for: "email" } }, [_vm._v("Email")]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("input", {
              attrs: {
                type: "email",
                id: "email",
                name: "email",
                required: "",
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", [
            _c("label", { attrs: { for: "phone" } }, [_vm._v("Teléfono")]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("input", {
              attrs: { type: "text", id: "phone", name: "phone", required: "" },
            }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "name-container" }, [
        _c("div", [
          _c("label", { attrs: { for: "name" } }, [_vm._v("Nombre:")]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            staticClass: "name",
            attrs: { type: "text", name: "name", required: "" },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "surname-container" }, [
        _c("div", [
          _c("label", { attrs: { for: "surname" } }, [_vm._v("Apellidos:")]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            attrs: { type: "text", name: "surname", required: "" },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "number",
          name: "birthday-day",
          min: "1",
          max: "31",
          placeholder: "dd",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "number",
          name: "birthday-month",
          min: "1",
          max: "12",
          placeholder: "mm",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "nationality-container" }, [
        _c("div", [
          _c("label", { attrs: { for: "nacionality" } }, [
            _vm._v("Nacionalidad:"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            attrs: { type: "text", name: "nationality", required: "" },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { attrs: { for: "document" } }, [
        _vm._v("Documento de identidad:"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "document-container" }, [
        _c(
          "select",
          {
            staticClass: "document-type",
            attrs: { name: "document-type", id: "" },
          },
          [
            _c("option", { attrs: { value: "DNI" } }, [_vm._v("DNI")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Pasaporte" } }, [
              _vm._v("Pasaporte"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "document-number",
          attrs: { type: "text", name: "document", required: "" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "number",
          name: "expire-date-day",
          min: "1",
          max: "31",
          placeholder: "dd",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "number",
          name: "expire-date-month",
          min: "1",
          max: "12",
          placeholder: "mm",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "name-container" }, [
        _c("div", [
          _c("label", { attrs: { for: "name" } }, [_vm._v("Nombre:")]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", { attrs: { type: "text", name: "name", required: "" } }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "surname-container" }, [
        _c("div", [
          _c("label", { attrs: { for: "surname" } }, [_vm._v("Apellidos:")]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            attrs: { type: "text", name: "surname", required: "" },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "number",
          name: "birthday-day",
          min: "1",
          max: "31",
          placeholder: "dd",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "number",
          name: "birthday-month",
          min: "1",
          max: "12",
          placeholder: "mm",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "nationality-container" }, [
        _c("div", [
          _c("label", { attrs: { for: "nacionality" } }, [
            _vm._v("Nacionalidad:"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            attrs: { type: "text", name: "nationality", required: "" },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { attrs: { for: "document" } }, [
        _vm._v("Documento de identidad:"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "document-container" }, [
        _c(
          "select",
          {
            staticClass: "document-type",
            attrs: { name: "document-type", id: "" },
          },
          [
            _c("option", { attrs: { value: "DNI" } }, [_vm._v("DNI")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Pasaporte" } }, [
              _vm._v("Pasaporte"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "document-number",
          attrs: { type: "text", name: "document" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "number",
          name: "expire-date-day",
          min: "1",
          max: "31",
          placeholder: "dd",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "number",
          name: "expire-date-month",
          min: "1",
          max: "12",
          placeholder: "mm",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "name-container" }, [
        _c("div", [
          _c("label", { attrs: { for: "name" } }, [_vm._v("Nombre:")]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", { attrs: { type: "text", name: "name", required: "" } }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "surname-container" }, [
        _c("div", [
          _c("label", { attrs: { for: "surname" } }, [_vm._v("Apellidos:")]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            attrs: { type: "text", name: "surname", required: "" },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "number",
          name: "birthday-day",
          min: "1",
          max: "31",
          placeholder: "dd",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "number",
          name: "birthday-month",
          min: "1",
          max: "12",
          placeholder: "mm",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "nationality-container" }, [
        _c("div", [
          _c("label", { attrs: { for: "nacionality" } }, [
            _vm._v("Nacionalidad:"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            attrs: { type: "text", name: "nationality", required: "" },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { attrs: { for: "document" } }, [
        _vm._v("Documento de identidad:"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "document-container" }, [
        _c(
          "select",
          {
            staticClass: "document-type",
            attrs: { name: "document-type", id: "" },
          },
          [
            _c("option", { attrs: { value: "DNI" } }, [_vm._v("DNI")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Pasaporte" } }, [
              _vm._v("Pasaporte"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "document-number",
          attrs: { type: "text", name: "document" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "number",
          name: "expire-date-day",
          min: "1",
          max: "31",
          placeholder: "dd",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", {
        attrs: {
          type: "number",
          name: "expire-date-month",
          min: "1",
          max: "12",
          placeholder: "mm",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "observations" }, [
      _c("label", { attrs: { for: "observations" } }, [
        _vm._v("Observaciones"),
      ]),
      _c("br"),
      _vm._v(" "),
      _c("textarea", {
        staticClass: "observations__textarea",
        attrs: {
          name: "observations",
          id: "observations",
          placeholder:
            "¡Dinos alguna cosa que te resulte importante, todo lo que nos digas nos ayudará para poder ofrecerte el mejor viaje sorpresa!",
          rows: "10",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", { attrs: { src: "/imgs/plane-solid.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", { attrs: { src: "/imgs/plane-solid.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Modificación del horario de vuelo")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }