<template>
    <div class="airports-component">
        <h1>Aeropuertos</h1>
        <table>
            <tr>
                <th>
                    Nombre
                </th>
                <th>
                    Precio
                </th>
                <th>
                    Editar
                </th>
                <th>
                    Eliminar
                </th>
            </tr>
            <tr v-for="(airport, key) in airports" :key="key">
                <td>
                    <input :id="'name'+key" type="text" :value="airport.name" disabled class="disabled-input">
                </td>
                <td>
                    <input :id="'price'+key" type="number" :value="airport.price" disabled class="disabled-input">
                </td>
                <td><span @click="!editing ? enableEdit(key) : !adding ? saveEdit(key) : saveNew(key)" :id="'edit'+key" class="edit bold brand-color">Editar</span></td>
                <td><span @click="deleteAirport(key)" class="red-text bold">Eliminar</span></td>
            </tr>
        </table>
        <div class="add-airport-button-container">
            <button class="add-airport-button" @click="addAirport()">Añadir aeropuerto</button>
        </div>
    </div>
</template>
<script>
export default {
    data: function(){
        return{
            airports: [],
            editing:false,
            adding: false,
        }
    },
    beforeMount(){
        axios.get('/admin/airports')
        .then(res => {
            res.data.forEach((element, index) => {
                this.airports.push({
                    name: element.name,
                    price: element.price,
                    id: element.id,
                    key: index,
                })
            });
        })
    },
    methods:{
        enableEdit(key){
            if(!this.editing && !this.adding){
                let name = document.getElementById("name"+key);
                let price = document.getElementById("price"+key);
                let edit = document.getElementById("edit"+key);
                name.disabled=false;
                price.disabled=false;
                edit.innerHTML="Aplicar";
                name.classList.remove("disabled-input")
                price.classList.remove("disabled-input")
                this.editing=true;
            }
        },
        saveEdit(key){
            if(this.editing && !this.adding){
                let name = document.getElementById("name"+key);
                let price = document.getElementById("price"+key);
                let edit = document.getElementById("edit"+key);
                this.airports[key].name = name.value;
                this.airports[key].price = price.value;
                let id = this.airports.filter(obj => {
                    // console.log(obj.key, key)
                    return obj.key === key
                })[0].id;
                // console.log(id);
                name.disabled=true;
                price.disabled=true;
                edit.innerHTML="Editar";
                name.classList.add("disabled-input")
                price.classList.add("disabled-input")
                this.editing=false;

                axios.put(`/admin/airport/${id}`, {
                    name: name.value,
                    price: price.value,
                    id: id
                })
            }
        },
        async addAirport(){
            this.editing=true;
            this.adding=true;
            let key = this.airports.length;
            await this.airports.push({
                name:"",
                price: 0,
            });
            let edit = document.getElementById("edit"+key);
            let name = document.getElementById("name"+key);
            let price = document.getElementById("price"+key);
            edit.innerHTML="Guardar"
            name.disabled=false;
            price.disabled=false;
            name.classList.remove("disabled-input")
            price.classList.remove("disabled-input")

        },
        saveNew(key){
            let edit = document.getElementById("edit"+key);
            let name = document.getElementById("name"+key);
            let price = document.getElementById("price"+key);
            edit.innerHTML="Editar";
            name.classList.add("disabled-input")
            price.classList.add("disabled-input")
            this.adding=false;
            name.disabled=true;
            price.disabled=true;
            if(this.airports.length>1){
                this.airports[this.airports.length-1]={
                    name: name.value,
                    price: price.value,
                    id: this.airports[this.airports.length-2].id +1,
                    key: this.airports.length -1
                }
            }else{
                this.airports[this.airports.length-1]={
                    name: name.value,
                    price: price.value,
                    id: 0,
                    key: this.airports.length -1
                }
            }

            // console.log(this.airports)
            axios.post('/admin/airport', {name: name.value, price:price.value})
            .then(res => {
                window.location.reload();
            });
        },
        deleteAirport(key){
            let id = this.airports.filter(obj => {
                return obj.key === key
            })[0].id;
            axios.post('/admin/airport/'+id)
            .then(res=>{
                window.location.reload();
            });
        }
    }
}
</script>
