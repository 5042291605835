<template>
    <div class="about-us-view">
        <div class="hide-on-mobile">
            <header-component v-on="$listeners"></header-component>
        </div>
        <div class="main">
            <div class="section section-top">
                <p class="margin0"><b>Surprise travel</b></p>
                <h2>
                    emociónate, suit up
                </h2>
                <p>
                    ¿Que haces cuando quieres viajar y no sabes donde? Te pones a trabajar.
                </p>
                <p>
                    Somos una agencia de viajes sorpresa fundada por tres jóvenes emprendedores apasionados por explorar
                    el mundo y compartir experiencias únicas con otros viajeros. Nosotros, nos lanzamos a esta aventura
                    con la firme convicción de que viajar no solo debería ser una actividad, sino una experiencia que
                    despierte emociones, cree recuerdos inolvidables y fomente la exploración de lo desconocido.
                </p>
            </div>

            <div class="fly-img section">
                <img :src="img" alt="Viaje sorpresa España">
            </div>

            <div class="section">
                <h2>encantados de conocerte</h2>
                <p>
                    Nos encanta desafiar lo convencional y pensar fuera de la caja. Nos dimos cuenta de que muchos
                    viajes se convierten en rutinas predecibles, donde cada detalle está planeado de antemano,
                    eliminando la emoción y la sorpresa. Así nació RubikFly, con la idea de reinventar la forma en que
                    las personas experimentan el mundo. Nos esforzamos por ofrecer una alternativa emocionante y
                    misteriosa para aquellos que buscan escapar de lo ordinario y sumergirse en lo extraordinario.
                </p>
            </div>

            <div class="section">
                <h2>¿Que nos diferencia?</h2>
                <p>
                    En RubikFly, creemos en la magia de lo inesperado. Nuestro enfoque único consiste en diseñar viajes
                    personalizados, pero con un giro emocionante: ¡el destino final es una sorpresa! Nos encargamos de
                    todo el proceso, desde la planificación hasta la ejecución, permitiéndote relajarte y disfrutar del
                    viaje sin preocupaciones. Cada aventura con RubikFly es como resolver un rompecabezas: emocionante,
                    desafiante y lleno de gratas sorpresas.
                </p>
            </div>

            <div class="section last-section">
                <h2>
                    we're changing the way
                </h2>
                <p>
                    Nuestra misión es inspirar a las personas a descubrir el mundo de una manera completamente nueva.
                    Queremos fomentar la curiosidad, avivar la pasión por la aventura y crear recuerdos que duren toda
                    la vida. En RubikFly, creemos en la importancia de salir de tu zona de confort, explorar lo
                    desconocido y abrir tu mente a nuevas experiencias. Estamos aquí para convertir tus sueños de viaje
                    en realidades inolvidables. ¡Únete a nosotros en esta emocionante aventura y deja que RubikFly te
                    lleve a lugares que nunca imaginaste!
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            img: "imgs/rubikfly-viaje-sorpresa.webp",
        }
    },
    beforeMount() {
        window.scrollTo(0, 0);
        let width = document.body.clientWidth;
        if (width < 500) {
            this.img = "imgs/rubikfly-viaje-sorpresa-mbl.webp";
        }
        this.$emit('hasPrice', 0);
    }
}
</script>
