<template>
    <div class="howitworks-view">
        <div class="hide-on-mobile">
            <header-component v-on="$listeners"></header-component>
        </div>

        <div>
            <h5>COMO FUNCIONA</h5>
            <h1>Elige la fecha, descarta... añade extras y ¡Viaje SORPRESA!</h1>
            <div class="destinations-container">
                <div class="destinations-inner-container">
                    <div v-for="(destination, key) in destinations" :key="key" class="flex width-percent">
                        <div v-if="key%4==0" class="bottom">
                            <div class="destination-component-container">
                                <destination-component :name="destination.name" :img="destination.img" :id="key"></destination-component>
                            </div>
                        </div>
                        <div v-else-if="key%4==1" class="middle">
                            <div class="destination-component-container">
                                <destination-component :name="destination.name" :img="destination.img" :id="key"></destination-component>
                            </div>
                        </div>
                        <div v-else-if="key%4==2" class="top">
                            <div class="destination-component-container">
                                <destination-component :name="destination.name" :img="destination.img" :id="key"></destination-component>
                            </div>
                        </div>
                        <div v-else-if="key%4==3" class="middle">
                            <div class="destination-component-container">
                                <destination-component :name="destination.name" :img="destination.img" :id="key"></destination-component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="steps">
                <div class="flex flex__col center-text">
                    <span class="step__number">1.</span>
                    <h3 class="step__title">INICIA TU VIAJE</h3>
                    <p class="step__text">
                        Que prefieres ESPAÑA o EUROPA. Selecciona el número de pasajeros, el aeropuerto de salida y las fechas de tu viaje sorpresa.
                    </p>
                </div>
                <div class="flex flex__col center-text">
                    <span class="step__number">2.</span>
                    <h3 class="step__title">DESCARTA LOS DESTINOS</h3>
                    <p class="step__text">
                        Conocerás los posibles destinos y podrás descartar las ciudades que no quieras visitar.
                    </p>
                </div>
                <div class="flex flex__col center-text">
                    <span class="step__number">3.</span>
                    <h3 class="step__title">¡VIAJE SORPRESA!</h3>
                    <p class="step__text">
                        ¡No sabrás tu destino hasta 48 horas antes de viajar!
                    </p>
                </div>
            </div>
        </div>
        <div>
            <faq-component></faq-component>
        </div>
    </div>
</template>
<script>
import Destination from '../components/Destination.vue'
export default {
    components: { Destination },
    data: function(){
        return {
            destinations: [
                {
                    name: "Ibiza",
                    img: "url('../imgs/spain/IBIZA.webp')",
                },{
                    name: "Menorca",
                    img: "url('../imgs/spain/MENORCA.webp')",
                },{
                    name: "Paris",
                    img: "url('../imgs/europe/PARIS.webp')",
                },{
                    name: "Roma",
                    img: "url('../imgs/europe/ROMA.webp')",
                },{
                    name: "Santander",
                    img: "url('../imgs/spain/SANTANDER.webp')",
                }
            ]
        }
    },
    beforeMount(){
        this.$emit('hasPrice', 0);
    }
}
</script>
