<template>
    <div>
        <div class="contact-header">

        </div>
        <div class="contact-body">
            <div class="contact-info">
                <h1>Contacta con nosotros</h1>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestias laborum ratione iste dolorem voluptates consequuntur suscipit, non libero ad quam reprehenderit sed deserunt similique, magnam, quod et quidem aut inventore!</p>
            </div>
            <div class="contact-form">
                <form action="">
                    <div>
                        <label for="name">Nombre</label>
                        <br>
                        <input type="text" name="name">
                    </div>
                    <div>
                        <label for="email">Correo electónico</label>
                        <br>
                        <input type="email" name="email">
                    </div>
                    <div>
                        <label for="phone">Teléfono</label>
                        <br>
                        <input type="text" name="phone">
                    </div>
                    <div>
                        <label for="subject">Asunto</label>
                        <br>
                        <input type="text" name="subject">
                    </div>
                    <div>
                        <label for="comment">Comentario</label>
                        <br>
                        <textarea rows="10" ></textarea>
                    </div>

                    <vue-recaptcha :sitekey="recaptcha_key"></vue-recaptcha>

                    <div class="checkbox">
                        <input type="checkbox" name="checkbox">
                        <label for="checkbox">Autorizo el tratamiento de mis datos para futuras ofertas comerciales por parte de Rubikfly.</label>
                    </div>
                    <div class="checkbox">
                        <input type="checkbox" name="checkbox2">
                        <label for="checkbox2">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor tempore, distinctio error velit iure minus! Facilis voluptates nihil et nostrum voluptatem obcaecati consequuntur aspernatur deserunt. Iste iusto quo totam harum!</label>
                    </div>
                    <button type="submit">Enviar</button>
                </form>
            </div>
        </div>
        
    </div>
</template>
<script>
import {VueRecaptcha} from 'vue-recaptcha';

export default{
    data: function(){
        return{
            recaptcha_key:process.env.MIX_RECAPTCHA_KEY,
        }
    },
    components:{
        VueRecaptcha
    },
    beforeMount(){
        this.$emit('hasPrice', 0);
    },
    mounted(){
        console.log(this.recaptcha_key);
    }
}
</script>
