<template>
    <div>
        <div class="hide-on-mobile">
            <header-component v-on="$listeners"></header-component>
        </div>
    </div>
</template>
<script>
export default {
    beforeMount(){
        this.$emit('hasPrice', 0);
    }
}
</script>