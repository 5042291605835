var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "blog-view" }, [
    _c(
      "div",
      { staticClass: "hide-on-mobile" },
      [
        _c("header-component", _vm._g({}, _vm.$listeners)),
        _vm._v(" "),
        _c("link", { attrs: { rel: "icon", href: "/imgs/favicon.webp" } }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "main" }, [
      _c("h1", [_vm._v(_vm._s(_vm.blog.title))]),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.blog.content) } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }