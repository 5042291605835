<template>
    <div class="header-img-container">
        <div class="header-content">
            <div class="header-text-mobile">
                <h1>VIAJE SORPRESA</h1>
                <h2>VUELOS + ALOJAMIENTO <br> DESDE 150€</h2>
            </div>
            <div class="header-inputs-container">
                <div class="header-inputs-inner-container">
                    <div>
                        <label for="">¿PARA QUIEN?</label>
                        <div class="header-buttons">
                            <button @click="toggleGift($event)" id="not-gift" class="button-selected">Para mí</button>
                            <button @click="toggleGift($event)" id="gift">Regalo</button>
                        </div>
                    </div>
                    <div class="header-inputs">
                        <div class="header-input">
                            <label for="type">DESTINO</label>
                            <select name="type" id="type">
                                <option v-for="(type, key) in types" :key="'type' + key" :value="type.value">{{ type.type }}
                                </option>
                            </select>
                        </div>
                        <div class="header-input">
                            <label for="type">ORIGEN DE SALIDA</label>
                            <select name="airport" id="airport">
                                <option v-for="(airport, key) in airports" :key="'airport' + key"
                                    :value="airport.airport">{{ airport.airport + " (" + airport.price + "€)" }}</option>
                            </select>
                        </div>
                        <div class="header-input">
                            <label for="days">DIAS</label>
                            <select name="days" id="days">
                                <option v-for="(day, key) in days" :key="'day' + key" :value="day.value">{{ day.type }}
                                </option>
                            </select>
                        </div>
                        <div class="header-input">
                            <label for="people">CUANTOS VIAJEROS</label>
                            <div class="people-input">
                                <p class="people-number">{{ people_num }} {{ people_text }}<img class="up-down-img"
                                        @click="toggleUpDown()" :src="up_down_img" alt=""></p>
                                <div class="inner-people-input" id="inner-people-input">
                                    <ul>
                                        <li class="people-type-item">
                                            <div class="people-type-name">
                                                <b>Adultos</b><br>
                                                <span>(Mayores de 16 años)</span>
                                            </div>
                                            <div class="add-remove-people">
                                                <span @click="removeAdult()" class="change-people-number">-</span>
                                                <span>{{ adults }}</span>
                                                <span @click="addAdult()" class="change-people-number">+</span>
                                            </div>
                                        </li>
                                        <li class="people-type-item">
                                            <div class="people-type-name">
                                                <b>Niños</b><br>
                                                <span>(De 2 a 15 años)</span>
                                            </div>
                                            <div class="add-remove-people">
                                                <span @click="removeKid()" class="change-people-number">-</span>
                                                <span>{{ kids }}</span>
                                                <span @click="addKid()" class="change-people-number">+</span>
                                            </div>
                                        </li>
                                        <li class="people-type-item">
                                            <div class="people-type-name">
                                                <b>Bebés</b><br>
                                                <span>(Menores de 2 años)</span>
                                            </div>
                                            <div class="add-remove-people">
                                                <span @click="removeBaby()" class="change-people-number">-</span>
                                                <span>{{ babies }}</span>
                                                <span @click="addBaby()" class="change-people-number">+</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="book-now-container">
                    <button @click="emitData" class="book-now">RESERVA YA</button>
                </div>

            </div>
            <div class="header-text">
                <h1 style="margin-top: 5px;
    margin-bottom: 0.5rem;
    font-weight: 800;
    line-height: 1.2;
">VIAJE SORPRESA</h1>
                <h2>VUELOS + ALOJAMIENTO DESDE 150€</h2>
            </div>

        </div>
        <div class="header-img"></div>
    </div>
</template>
<script>

export default {
    data: function () {
        return {
            types: [
                {
                    value: "Europa",
                    type: "Europa"
                }
            ],
            airports: [

            ],
            days: [
                {
                    value: 3,
                    type: "3 días"
                }, {
                    value: 4,
                    type: "4 días"
                }, {
                    value: 5,
                    type: "5 días"
                }, {
                    value: 6,
                    type: "6 días"
                }, {
                    value: 7,
                    type: "7 días"
                },
            ],
            adults: 2,
            kids: 0,
            babies: 0,
            people_num: 2,
            people_text: "personas",
            down: false,
            up_down_img: "../imgs/angle-down-solid.svg",
            gift: false,
            destination: "",
            origin: "",
            days_num: 3
        }
    },
    beforeMount() {
        this.getOrigins();
    },
    mounted() {

    },
    methods: {
        getOrigins() {
            axios.get('/admin/airports')
                .then(res => {
                    res.data.forEach((element) => {
                        this.airports.push({
                            airport: element.name,
                            price: element.price,
                            value: element.price
                        })
                    });
                })
        },
        toggleGift(e) {
            let notGift = document.getElementById("not-gift");
            let gift = document.getElementById("gift");

            if (e.target.id == "gift") {
                this.gift = true;
                gift.classList.add("button-selected");
                notGift.classList.remove("button-selected");
            } else {
                this.gift = false;
                gift.classList.remove("button-selected");
                notGift.classList.add("button-selected");
            }

        },
        toggleUpDown() {
            let element = document.getElementById("inner-people-input");
            if (!this.down) {
                this.up_down_img = "../imgs/chevron-up-solid.svg";
                this.down = true;
                element.style.display = "block";
            } else {
                this.up_down_img = "../imgs/angle-down-solid.svg";
                this.down = false;
                element.style.display = "none";
            }
        },
        removeAdult() {
            if (this.adults > 1 && this.people_num > 2) {
                this.adults--;
                this.people_num--;
            }
        },
        addAdult() {
            if (this.people_num < 10) {
                this.adults++;
                this.people_num++;
            }
        },
        removeKid() {
            if (this.kids > 0 && this.people_num > 2) {
                this.kids--;
                this.people_num--;
            }
        },
        addKid() {
            if (this.people_num < 10) {
                this.kids++;
                this.people_num++;
            }
        },
        removeBaby() {
            if (this.babies > 0 && this.people_num > 2) {
                this.babies--;
                this.people_num--;
            }
        },
        addBaby() {
            if (this.people_num < 10) {
                this.babies++;
                this.people_num++;
            }
        },
        emitData() {
            const now = new Date()
            this.destination = document.getElementById("type").value;
            let place = document.getElementById("airport").value;
            this.origin = this.airports.find(obj => {

                return obj.airport === place;
            })

            this.days_num = document.getElementById("days").value;
            this.$emit('clicked', { destination: this.destination, origin: this.origin.airport, days_num: this.days_num, gift: this.gift, adults: this.adults, kids: this.kids, babies: this.babies });
            let LSObject = {
                destination: this.destination,
                origin: this.origin.airport,
                origin_price: this.origin.price,
                days_num: this.days_num,
                gift: this.gift,
                adults: this.adults,
                kids: this.kids,
                babies: this.babies,
                expires: now.getTime() + (1000 * 60 * 60) //1 hour
            }

            localStorage.setItem("main-data", JSON.stringify(LSObject));

            this.$router.push({ name: 'calendar' });
        }
    }
}
</script>
