var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "howitworks-view" }, [
    _c(
      "div",
      { staticClass: "hide-on-mobile" },
      [_c("header-component", _vm._g({}, _vm.$listeners))],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _c("h5", [_vm._v("COMO FUNCIONA")]),
      _vm._v(" "),
      _c("h1", [
        _vm._v("Elige la fecha, descarta... añade extras y ¡Viaje SORPRESA!"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "destinations-container" }, [
        _c(
          "div",
          { staticClass: "destinations-inner-container" },
          _vm._l(_vm.destinations, function (destination, key) {
            return _c("div", { key: key, staticClass: "flex width-percent" }, [
              key % 4 == 0
                ? _c("div", { staticClass: "bottom" }, [
                    _c(
                      "div",
                      { staticClass: "destination-component-container" },
                      [
                        _c("destination-component", {
                          attrs: {
                            name: destination.name,
                            img: destination.img,
                            id: key,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : key % 4 == 1
                ? _c("div", { staticClass: "middle" }, [
                    _c(
                      "div",
                      { staticClass: "destination-component-container" },
                      [
                        _c("destination-component", {
                          attrs: {
                            name: destination.name,
                            img: destination.img,
                            id: key,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : key % 4 == 2
                ? _c("div", { staticClass: "top" }, [
                    _c(
                      "div",
                      { staticClass: "destination-component-container" },
                      [
                        _c("destination-component", {
                          attrs: {
                            name: destination.name,
                            img: destination.img,
                            id: key,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : key % 4 == 3
                ? _c("div", { staticClass: "middle" }, [
                    _c(
                      "div",
                      { staticClass: "destination-component-container" },
                      [
                        _c("destination-component", {
                          attrs: {
                            name: destination.name,
                            img: destination.img,
                            id: key,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
    ]),
    _vm._v(" "),
    _c("div", [_c("faq-component")], 1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "steps" }, [
      _c("div", { staticClass: "flex flex__col center-text" }, [
        _c("span", { staticClass: "step__number" }, [_vm._v("1.")]),
        _vm._v(" "),
        _c("h3", { staticClass: "step__title" }, [_vm._v("INICIA TU VIAJE")]),
        _vm._v(" "),
        _c("p", { staticClass: "step__text" }, [
          _vm._v(
            "\n                    Que prefieres ESPAÑA o EUROPA. Selecciona el número de pasajeros, el aeropuerto de salida y las fechas de tu viaje sorpresa.\n                "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex flex__col center-text" }, [
        _c("span", { staticClass: "step__number" }, [_vm._v("2.")]),
        _vm._v(" "),
        _c("h3", { staticClass: "step__title" }, [
          _vm._v("DESCARTA LOS DESTINOS"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "step__text" }, [
          _vm._v(
            "\n                    Conocerás los posibles destinos y podrás descartar las ciudades que no quieras visitar.\n                "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex flex__col center-text" }, [
        _c("span", { staticClass: "step__number" }, [_vm._v("3.")]),
        _vm._v(" "),
        _c("h3", { staticClass: "step__title" }, [_vm._v("¡VIAJE SORPRESA!")]),
        _vm._v(" "),
        _c("p", { staticClass: "step__text" }, [
          _vm._v(
            "\n                    ¡No sabrás tu destino hasta 48 horas antes de viajar!\n                "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }