var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calendar-component" }, [
    _c("div", { staticClass: "breadcrumb-container" }, [
      _c("ul", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Inicio")])],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/calendar" } }, [
              _vm._v("Fechas"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", [_vm._v("Destinos")]),
        _vm._v(" "),
        _c("li", [_vm._v("Extras")]),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "calendar-container" },
      [
        _c("vc-calendar", {
          attrs: {
            "is-expanded": "",
            attributes: _vm.attrs,
            columns: _vm.$screens({ default: 1, sm: 2 }),
            rows: _vm.$screens({ default: 2, sm: 1 }),
            color: "red",
            "min-date": _vm.min_date,
            "max-date": _vm.max_date,
            step: 1,
          },
          on: { dayclick: _vm.onDayClick },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "rates-container" }, [
      _c("ul", { staticClass: "rates" }, [
        _c("li", [
          _c("div", { staticClass: "high-demand" }),
          _vm._v(" "),
          _c("span", [
            _vm._v("Alta demanda (+" + _vm._s(_vm.high_request_price) + "€)"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("div", { staticClass: "last-places" }),
          _vm._v(" "),
          _c("span", [
            _vm._v("Ultimas plazas (+" + _vm._s(_vm.last_seats_price) + "€)"),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "calendar-button-container" }, [
      _vm.days_selected.length != 0
        ? _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.goToNextStep()
                },
              },
            },
            [_vm._v("Avanzamos")]
          )
        : _c("button", { staticClass: "disabled" }, [_vm._v("Avanzamos")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "calendar-titles" }, [
      _c("h2", [_vm._v("Selecciona el día de salida")]),
      _vm._v(" "),
      _c("h4", [
        _vm._v(
          "Selecciona el día de inicio de tu viaje. Se aplicará la tarifa correspondiente a ese día."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "blocked" }),
      _vm._v(" "),
      _c("span", [_vm._v("Plazas agotadas")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "standard" }),
      _vm._v(" "),
      _c("span", [_vm._v("Tarifa estándar")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }