var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "destination-img-container flex",
      attrs: { id: "destination-img-container" + _vm.id },
    },
    [_c("h4", [_vm._v(_vm._s(_vm.name))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }