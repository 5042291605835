<template>
    <div>
        <header-component v-on="$listeners"></header-component>
        <destinations-component></destinations-component>
        <opinions-component></opinions-component>
        <experiences-component></experiences-component>
        <faq-component></faq-component>
        <promotions-component></promotions-component>
        <a href="https://api.whatsapp.com/send?phone=34631387514" target="_blank" rel="noopener noreferrer"><img src="/public/imgs/whatsapp-us.png" alt="WhatsApp" style="position: fixed; bottom: 20px; right: 20px;height: 80px;z-index:9999"></a>

    </div>
</template>
<script>
export default {
    data: function(){
        return{

        }
    },
    beforeMount(){
        this.$emit('hasPrice', 0);
    },
    methods: {

    }
}
</script>
