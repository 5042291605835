var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "extra-prices-component" }, [
    _c("h1", [_vm._v("Precios extras")]),
    _vm._v(" "),
    _c(
      "table",
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.extras, function (extra, key) {
          return _c("tr", { key: key }, [
            _c("td", [
              _c("input", {
                staticClass: "disabled-input",
                attrs: { id: "name" + key, type: "text", disabled: "" },
                domProps: { value: extra.name },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                staticClass: "disabled-input",
                attrs: { id: "price" + key, type: "number", disabled: "" },
                domProps: { value: extra.price },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                {
                  staticClass: "edit bold brand-color",
                  attrs: { id: "edit" + key },
                  on: {
                    click: function ($event) {
                      !_vm.editing ? _vm.enableEdit(key) : _vm.saveEdit(key)
                    },
                  },
                },
                [_vm._v("Editar")]
              ),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("\n                Nombre\n            ")]),
      _vm._v(" "),
      _c("th", [_vm._v("\n                Precio\n            ")]),
      _vm._v(" "),
      _c("th", [_vm._v("\n                Editar\n            ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }