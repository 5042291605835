/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import Vue from "vue";
import { VueRecaptcha } from "vue-recaptcha";
import router from "./router";
import i18n from "./i18n";
import VueCompositionAPI from '@vue/composition-api'
import VCalendar from 'v-calendar';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'



Vue.use(VueCompositionAPI)

Vue.use(VCalendar, {
    componentPrefix: 'vc',
})

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
Vue.component('vue-recaptcha', VueRecaptcha);
Vue.component('main-component', require('./App.vue').default);
Vue.component('home-component', require('./views/Home.vue').default);
Vue.component('header-component', require('./components/Header.vue').default);
Vue.component('destinations-component', require('./components/Destinations.vue').default);
Vue.component('destination-component', require('./components/Destination.vue').default);
Vue.component('opinions-component', require('./components/Opinions.vue').default);
Vue.component('opinion-component', require('./components/Opinion.vue').default);
Vue.component('experiences-component', require('./components/Experiences.vue').default);
Vue.component('faq-component', require('./components/Faq.vue').default);
Vue.component('promotions-component', require('./components/Promotions.vue').default);
Vue.component('list-of-blogs-component', require('./components/ListBlogs.vue').default);

Vue.component('hotels-view', require('./views/HotelsView.vue').default);
Vue.component('hotels-component', require('./components/Hotels.vue').default);
Vue.component('hotel-component', require('./components/Hotel.vue').default);

Vue.component('give-away-view', require('./views/GiveAwayRubikfly.vue').default);

Vue.component('how-it-works-view', require('./views/HowItWorks.vue').default);

Vue.component('how-it-works-view', require('./views/AboutUs.vue').default);

Vue.component('days-component', require('./views/Days.vue').default);
Vue.component('destinations-view', require('./views/DestinationsView.vue').default);
Vue.component('destination-view-component', require('./components/DestinationViewComponent.vue').default);

Vue.component('extras-view', require('./views/Extras.vue').default);

Vue.component('finalize-view', require('./views/Finalize.vue').default);

Vue.component('contact-component', require('./views/Contact.vue').default);


/*ADMIN*/
Vue.component('admin-view', require('./admin/App.vue').default);
Vue.component('admin-home-view', require('./admin/views/Home.vue').default);
Vue.component('admin-bookings-view', require('./admin/views/Bookings.vue').default);
Vue.component('admin-calendars-view', require('./admin/views/Calendars.vue').default);
Vue.component('admin-airports-view', require('./admin/views/Airports.vue').default);
Vue.component('admin-airports-view', require('./admin/views/ExtraPrices.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


const app = new Vue({
    el: '#app',
    router,
    i18n
});
