var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-img-container" }, [
    _c("div", { staticClass: "header-content" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "header-inputs-container" }, [
        _c("div", { staticClass: "header-inputs-inner-container" }, [
          _c("div", [
            _c("label", { attrs: { for: "" } }, [_vm._v("¿PARA QUIEN?")]),
            _vm._v(" "),
            _c("div", { staticClass: "header-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "button-selected",
                  attrs: { id: "not-gift" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleGift($event)
                    },
                  },
                },
                [_vm._v("Para mí")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  attrs: { id: "gift" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleGift($event)
                    },
                  },
                },
                [_vm._v("Regalo")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "header-inputs" }, [
            _c("div", { staticClass: "header-input" }, [
              _c("label", { attrs: { for: "type" } }, [_vm._v("DESTINO")]),
              _vm._v(" "),
              _c(
                "select",
                { attrs: { name: "type", id: "type" } },
                _vm._l(_vm.types, function (type, key) {
                  return _c(
                    "option",
                    { key: "type" + key, domProps: { value: type.value } },
                    [
                      _vm._v(
                        _vm._s(type.type) + "\n                                "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "header-input" }, [
              _c("label", { attrs: { for: "type" } }, [
                _vm._v("ORIGEN DE SALIDA"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                { attrs: { name: "airport", id: "airport" } },
                _vm._l(_vm.airports, function (airport, key) {
                  return _c(
                    "option",
                    {
                      key: "airport" + key,
                      domProps: { value: airport.airport },
                    },
                    [
                      _vm._v(
                        _vm._s(airport.airport + " (" + airport.price + "€)")
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "header-input" }, [
              _c("label", { attrs: { for: "days" } }, [_vm._v("DIAS")]),
              _vm._v(" "),
              _c(
                "select",
                { attrs: { name: "days", id: "days" } },
                _vm._l(_vm.days, function (day, key) {
                  return _c(
                    "option",
                    { key: "day" + key, domProps: { value: day.value } },
                    [
                      _vm._v(
                        _vm._s(day.type) + "\n                                "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "header-input" }, [
              _c("label", { attrs: { for: "people" } }, [
                _vm._v("CUANTOS VIAJEROS"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "people-input" }, [
                _c("p", { staticClass: "people-number" }, [
                  _vm._v(
                    _vm._s(_vm.people_num) + " " + _vm._s(_vm.people_text)
                  ),
                  _c("img", {
                    staticClass: "up-down-img",
                    attrs: { src: _vm.up_down_img, alt: "" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleUpDown()
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "inner-people-input",
                    attrs: { id: "inner-people-input" },
                  },
                  [
                    _c("ul", [
                      _c("li", { staticClass: "people-type-item" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("div", { staticClass: "add-remove-people" }, [
                          _c(
                            "span",
                            {
                              staticClass: "change-people-number",
                              on: {
                                click: function ($event) {
                                  return _vm.removeAdult()
                                },
                              },
                            },
                            [_vm._v("-")]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.adults))]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "change-people-number",
                              on: {
                                click: function ($event) {
                                  return _vm.addAdult()
                                },
                              },
                            },
                            [_vm._v("+")]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "people-type-item" }, [
                        _vm._m(2),
                        _vm._v(" "),
                        _c("div", { staticClass: "add-remove-people" }, [
                          _c(
                            "span",
                            {
                              staticClass: "change-people-number",
                              on: {
                                click: function ($event) {
                                  return _vm.removeKid()
                                },
                              },
                            },
                            [_vm._v("-")]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.kids))]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "change-people-number",
                              on: {
                                click: function ($event) {
                                  return _vm.addKid()
                                },
                              },
                            },
                            [_vm._v("+")]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "people-type-item" }, [
                        _vm._m(3),
                        _vm._v(" "),
                        _c("div", { staticClass: "add-remove-people" }, [
                          _c(
                            "span",
                            {
                              staticClass: "change-people-number",
                              on: {
                                click: function ($event) {
                                  return _vm.removeBaby()
                                },
                              },
                            },
                            [_vm._v("-")]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.babies))]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "change-people-number",
                              on: {
                                click: function ($event) {
                                  return _vm.addBaby()
                                },
                              },
                            },
                            [_vm._v("+")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "book-now-container" }, [
          _c(
            "button",
            { staticClass: "book-now", on: { click: _vm.emitData } },
            [_vm._v("RESERVA YA")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(4),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "header-img" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-text-mobile" }, [
      _c("h1", [_vm._v("VIAJE SORPRESA")]),
      _vm._v(" "),
      _c("h2", [
        _vm._v("VUELOS + ALOJAMIENTO "),
        _c("br"),
        _vm._v(" DESDE 150€"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "people-type-name" }, [
      _c("b", [_vm._v("Adultos")]),
      _c("br"),
      _vm._v(" "),
      _c("span", [_vm._v("(Mayores de 16 años)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "people-type-name" }, [
      _c("b", [_vm._v("Niños")]),
      _c("br"),
      _vm._v(" "),
      _c("span", [_vm._v("(De 2 a 15 años)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "people-type-name" }, [
      _c("b", [_vm._v("Bebés")]),
      _c("br"),
      _vm._v(" "),
      _c("span", [_vm._v("(Menores de 2 años)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-text" }, [
      _c(
        "h1",
        {
          staticStyle: {
            "margin-top": "5px",
            "margin-bottom": "0.5rem",
            "font-weight": "800",
            "line-height": "1.2",
          },
        },
        [_vm._v("VIAJE SORPRESA")]
      ),
      _vm._v(" "),
      _c("h2", [_vm._v("VUELOS + ALOJAMIENTO DESDE 150€")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }