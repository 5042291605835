<template>
    <div class="bookings-view">
        <h1>Reservas</h1>
        <table class="table">
            <tr>
                <th>
                    Id
                </th>
                <th>
                    Ida
                </th>
                <th>
                    Vuelta
                </th>
                <th>
                    Origen
                </th>
                <th>
                    Destino
                </th>
                <th>
                    Ver
                </th>
            </tr>
            <tr v-for="(booking, key) in bookings" :key="key">
                <td>
                    {{booking.id}}
                </td>
                <td>
                   {{booking.departure_date}}
                </td>
                <td>
                   {{booking.return_date}}
                </td>
                <td>
                   {{booking.origin}}
                </td>
                <td>
                   {{booking.destination}}
                </td>
                <td>
                    <a :href="'/admin/booking/'+booking.id">Ver</a>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    data: function(){
        return{
            bookings: [],
        }
    },
    beforeMount(){
        axios.get('/admin/bookings')
        .then(res => {
            res.data.forEach((element, index) => {
                axios.get('/admin/airport/'+element.airport_id)
                .then(res =>{
                    this.bookings.push({
                        id: element.id,
                        departure_date: element.departure_day,
                        return_date: element.return_day,
                        origin: res.data.name,
                        destination: element.destination,
                    })
                })
            });
        })
    },
    methods:{
        
    }
}
</script>