<template>
    <div class="destinations-component">

        <div class="titles-container">
            <h3>CON MAS DE 50 DESTINOS POSIBLES ENTRE ESPAÑA Y EUROPA</h3>
            <h2>Vive tu viaje sorpresa en pareja</h2>
            <h3 style="margin-top:10px;color:#212529;">Escapada sorpresa que incluye vuelos de Ida y Vuelta | 2 noches de hotel | toda la emoción
                de vivir un viaje sorpresa que no descubrirás hasta 48 horas antes.
            </h3>
        </div>

        <div class="destinations-container">
            <div class="destinations-inner-container">
                <div v-for="(destination, key) in destinations" :key="key" class="flex width-percent">
                    <div v-if="key%4==0" class="top">
                        <div class="destination-component-container">
                            <destination-component :name="destination.name" :img="destination.img" :id="key"></destination-component>
                        </div>
                    </div>
                    <div v-else-if="key%4==1" class="middle">
                        <div class="destination-component-container">
                            <destination-component :name="destination.name" :img="destination.img" :id="key"></destination-component>
                        </div>
                    </div>
                    <div v-else-if="key%4==2" class="bottom">
                        <div class="destination-component-container">
                            <destination-component :name="destination.name" :img="destination.img" :id="key"></destination-component>
                        </div>
                    </div>
                    <div v-else-if="key%4==3" class="middle">
                        <div class="destination-component-container">
                            <destination-component :name="destination.name" :img="destination.img" :id="key"></destination-component>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import Destination from './Destination.vue'
export default {
  components: { Destination },
    data: function(){
        return {
            destinations: [
                {
                    name: "Londres",
                    img: "url('../imgs/europe/LONDRES.webp')",
                },{
                    name: "Burdeos",
                    img: "url('../imgs/europe/BURDEOS-1.webp')",
                },{
                    name: "Ibiza",
                    img: "url('../imgs/spain/IBIZA.webp')",
                },{
                    name: "Menorca",
                    img: "url('../imgs/spain/MENORCA.webp')",
                },{
                    name: "Paris",
                    img: "url('../imgs/europe/PARIS.webp')",
                },{
                    name: "Roma",
                    img: "url('../imgs/europe/ROMA.webp')",
                },{
                    name: "Santander",
                    img: "url('../imgs/spain/SANTANDER.webp')",
                },{
                    name: "Santiago",
                    img: "url('../imgs/spain/SANTIAGO.webp')",
                },{
                    name: "Valencia",
                    img: "url('../imgs/spain/VALENCIA-1.webp')",
                },
            ]
        }
    }
}
</script>
