var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-component", _vm._g({}, _vm.$listeners)),
      _vm._v(" "),
      _c("destinations-component"),
      _vm._v(" "),
      _c("opinions-component"),
      _vm._v(" "),
      _c("experiences-component"),
      _vm._v(" "),
      _c("faq-component"),
      _vm._v(" "),
      _c("promotions-component"),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          href: "https://api.whatsapp.com/send?phone=34631387514",
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
      [
        _c("img", {
          staticStyle: {
            position: "fixed",
            bottom: "20px",
            right: "20px",
            height: "80px",
            "z-index": "9999",
          },
          attrs: { src: "/public/imgs/whatsapp-us.png", alt: "WhatsApp" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }