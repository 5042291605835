<template>
    <div>
        <div class="fixed navbar-container">
            <nav class="navbar main-nav navbar-expand-lg" id="main-nav">
                <div :class="'width100 navbar-logo-button' + (hasPrice ? ' flex__space-between' : '')">
                    <div class="navbar-brand">
                        <router-link to="/"><img src="/imgs/logo.webp"
                                alt="Logotipo RUBIKFLY.es, viajes sorpresa con vuelos y hotel incluidos"></router-link>
                        <h1 style="display: none;">RUBIKFLY.ES | Reserva tu viaje sorpresa online vuelos y hotel
                            incluidos | Desde 150€</h1>
                    </div>

                    <button @click="openMenu()" v-if="!hasPrice" class="navbar-toggler" type="button"
                        data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <img class="nav-bars-img" src="/imgs/bars-solid.svg" alt="">
                    </button>
                    <div v-else class="navbar-price">
                        <span class="navbar__total-price-text">Precio Total:</span>
                        <span class="color-rubikfly has-price">{{ hasPrice }}€</span>
                    </div>
                </div>

                <div class="collapse navbar-collapse" id="collapsable-main-menu">
                    <ul v-if="!hasPrice" class="navbar-nav ml-auto">
                        <li class="nav-item" @click="closeMenu()">
                            <router-link class="nav-link" to="/como-funciona"><span>COMO FUNCIONA</span></router-link>
                        </li>

                        <li class="nav-item" @click="closeMenu()">
                            <router-link class="nav-link" to="/hoteles"><span>HOTELES</span></router-link>
                        </li>
                        <li class="nav-item" @click="closeMenu()">
                            <router-link class="nav-link" to="/blogs"><span>BLOGS</span></router-link>
                        </li>

                        <li class="nav-item show-on-collapse" @click="closeMenu()">
                            <router-link class="nav-link" to="/about-us"><span>SOBRE NOSOTROS</span></router-link>
                        </li>

                        <li class="nav-item" @click="closeMenu()">
                            <router-link class="nav-link" to="/regala-rubikfly"><span class="rubikfly-gift"><img
                                        class="gift-img" src="/imgs/gift-solid.svg" alt="">REGALA
                                    RUBIKFLY</span></router-link>
                        </li>
                    </ul>
                </div>


                <!--<div class="hide-on-small show-on-med">
                    <div class="nav-items">
                        <router-link to="/"><span>Como funciona</span></router-link>
                        <router-link to="/"><span>Hoteles y Areorlíneas</span></router-link>
                        <router-link to="/"><span class="rubikfly-gift"><img class="gift-img" src="/imgs/gift-solid.svg" alt="">Regala RUBIKFLY</span></router-link>
                    </div>
                </div>-->
            </nav>
        </div>

        <div class="router-view">
            <router-view @clicked="onClickChild" @hasPrice="getHasPrice" :destination="destination" :origin="origin"
                :days_num="days_num" :gift="gift" :adults="adults" :kids="kids" :babies="babies"></router-view>
        </div>


        <div class="cookies-banner" id="cookies-banner">
            <p>
                Este sitio web utiliza cookies, tanto propias como de terceros, para recopilar información estadística
                sobre su navegación y mostrarle
                publicidad relacionada con sus preferencias, generada a partir de sus pautas de navegación. Si continúa
                navegando, consideramos que acepta su uso.
                Para mas información clique <a href="/politica-de-cookies">aquí</a>.
            </p>
            <div class="cookies-buttons">
                <div class="close-cookies-banner accept-cookies" @click="closeCookiesBanner()">Aceptar</div>
                <a class="" href="/politica-de-cookies">
                    <div class="close-cookies-banner decline-cookies" @click="closeCookiesBanner()">Rechazar</div>
                </a>
            </div>

        </div>

        <footer>
            <div class="footer-container">
                <div class="footer-menu">
                    <div>
                        <div class="footer-list">
                            <b>RUBIKFLY</b>
                            <ul>
                                <li><router-link to="/como-funciona">Como funciona</router-link></li>
                                <li><router-link to="/regala-rubikfly">Canjea tu cheque regalo</router-link></li>
                                <li><router-link to="/hoteles">Hoteles</router-link></li>
                                <li><router-link to="/about-us">Sobre nosotros</router-link></li>
                                <li><router-link to="/preguntas-frecuentes">Preguntas frecuentes</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div class="footer-list">
                            <b>EMPRESA</b>
                            <ul>
                                <li><router-link to="/aviso-legal">Aviso Legal</router-link></li>
                                <li><router-link to="/politica-de-cookies">Política de Cookies</router-link></li>
                                <li><router-link to="/politica-de-privacidad">Política de privacidad</router-link></li>
                                <li><a href="/trabaja-con-nosotros">Trabaja con nosotros</a></li>
                                <li><a target="v_blank" href="imgs/TerminosYCondiciones.pdf">Términos de Condiciones</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="payments">
                    <b>Pago Seguro</b>
                    <p>Paga con tu tarjeta habitual</p>
                    <div>
                        <div>
                            <img src="/imgs/tarjetas.webp" alt="">
                            <img src="/imgs/paypal.webp" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="center-text copyright">
                <p>
                <div class="contain globalReview">
                    <div id="review-aggregate" itemscope="" itemtype="https://schema.org/Product">
                        <span itemprop="name">Opiniones clientes</span>
                        <div class="rating" itemprop="aggregateRating" itemscope=""
                            itemtype="https://schema.org/AggregateRating">
                            <p style="p.span{ background-color: gold;}">
                                <span>★</span><span>★</span><span>★</span><span>★</span><span>★</span><br>
                                Nota: <span itemprop="ratingValue">4.9</span>/ 5
                                calculada de <span itemprop="reviewCount">150</span> opiniones
                            </p>
                        </div>
                    </div>
                </div>
                </p>
                <p>
                    ©{{ new Date().getFullYear() }} RUBIKFLY.ES © All rights reserved
                    <br>
                    CentreBit Menorca, local 4 1r planta. Av. des Camp Verd, 4, 07730 Alaior, Illes Balears
                    <br>
                    Agencia registrada AV0030ME
                    <br>
                    <br>
                    Design and Development by <a target="v_blank" href="https://navegaenbarco.com/">Navega en Barco</a>
                </p>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            menu_opened: false,
            destination: "Europa",
            origin: "Barcelona",
            days_num: 3,
            gift: false,
            adults: 2,
            kids: 0,
            babies: 0,
            hasPrice: 0,
        }
    },
    beforeMount() {

    },
    mounted() {
        if (localStorage.getItem("hide-cookies-banner")) {
            document.getElementById("cookies-banner").style.display = "none";
        }
    },
    methods: {
        openHelpMenu() {
            document.getElementById("help-list-container").style.display = "block"
        },
        closeHelpMenu() {
            document.getElementById("help-list-container").style.display = "none"
        },
        openMenu() {
            if (!this.menu_opened) {
                document.getElementById("collapsable-main-menu").style.display = "block";
                document.getElementById("main-nav").classList.add("open");
                this.menu_opened = true;
            } else {
                document.getElementById("collapsable-main-menu").style.display = "none";
                document.getElementById("main-nav").classList.remove("open");
                this.menu_opened = false;
            }
        },
        closeMenu() {
            document.getElementById("collapsable-main-menu").style.display = "none";
            document.getElementById("main-nav").classList.remove("open");
            this.menu_opened = false;
        },
        onClickChild({ destination, origin, days_num, gift, adults, kids, babies }) {
            this.destination = destination;
            this.origin = origin;
            this.days_num = days_num;
            this.gift = gift;
            this.adults = adults;
            this.kids = kids,
                this.babies = babies
        },
        closeCookiesBanner() {
            document.getElementById("cookies-banner").style.display = "none";
            localStorage.setItem("hide-cookies-banner", true);
        },
        getHasPrice(price) {
            if (price !== false) {
                this.hasPrice = price;
            } else {
                this.hasPrice = 0;
            }
        }
    }
}
</script>
