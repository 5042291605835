var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "faq-view" }, [
    _c(
      "div",
      { staticClass: "hide-on-mobile" },
      [_c("header-component", _vm._g({}, _vm.$listeners))],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "faq" },
      [
        _c("h1", [_vm._v("Preguntas frecuentes")]),
        _vm._v(" "),
        _c("h5", [
          _vm._v(
            "Información para los viajeros. Resuelve todas tus dudas antes de viajar. Aquí te dejamos las respuestas a las preguntas más frecuentes."
          ),
        ]),
        _vm._v(" "),
        _vm._l(_vm.questions, function (question, key) {
          return _c("div", { key: key }, [
            _c("div", { staticClass: "question" }, [
              _c(
                "h3",
                {
                  staticClass: "question__title",
                  on: {
                    click: function ($event) {
                      return _vm.openClose(key)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "question__title-text" }, [
                    _vm._v(_vm._s(question.title)),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "relative" }, [
                    _c("img", {
                      staticClass: "up-down-img absolute",
                      attrs: { id: "img" + key, src: _vm.down_img, alt: "" },
                    }),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("p", {
              staticClass: "hidden",
              attrs: { id: "answer" + key },
              domProps: { innerHTML: _vm._s(question.answer) },
            }),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }