<template>
    <div class="faq-view">
      <div class="faq">
            <h1>Preguntas frecuentes</h1>
            <h5>Información para los viajeros. Resuelve todas tus dudas antes de viajar. Aquí te dejamos las respuestas a las preguntas más frecuentes.</h5>
            <div v-for="(question, key) in questions" :key="key">
                <div class="question">
                    <h3 class="question__title" @click="openClose(key)"><span class="question__title-text">{{question.title}}</span> <span class="relative"><img :id="'img'+key" class="up-down-img absolute" :src="down_img" alt=""></span></h3>
                </div>
                <p :id="'answer'+key" class="hidden" v-html="question.answer"></p>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        questions: [
            {
                title: "¿Qué es un viaje sorpresa y cómo puedo reservar uno con Rubikfly.es?",
                answer: "Un viaje sorpresa es una experiencia de viaje donde el destino es desconocido hasta poco antes de la fecha de salida. Para reservar un viaje sorpresa con Rubikfly.es, simplemente selecciona el paquete de tu preferencia, las fechas de tu viaje y el aeropuerto de salida. Nosotros nos encargaremos del resto, asegurando una aventura emocionante y espontánea."
            },
            {
                title: "¿Cómo selecciona Rubikfly.es los destinos para un viaje sorpresa por España?",
                answer: "En Rubikfly.es, elegimos los destinos de viaje sorpresa en España basándonos en una variedad de factores como la cultura, la gastronomía, y la belleza natural. Trabajamos con socios locales para asegurar experiencias auténticas y memorables, promoviendo así el turismo sostenible y enriquecedor dentro de España."
            },
            {
                title: "¿Qué puedo esperar de un viaje sorpresa por Europa con Rubikfly.es?",
                answer: "Un viaje sorpresa por Europa con Rubikfly.es te ofrece la oportunidad de explorar nuevas culturas, idiomas, y paisajes sin la preocupación de planificar cada detalle. Desde capitales vibrantes hasta joyas ocultas, cada destino sorpresa promete ser una aventura única llena de descubrimientos y experiencias inolvidables."
            },
            {
                title: "¿Cuáles son los beneficios de elegir Rubikfly.es para mi próximo viaje sorpresa?",
                answer: "Elegir Rubikfly.es para tu viaje sorpresa significa disfrutar de una experiencia de viaje sin estrés con todo organizado por nosotros. Desde vuelos y alojamientos hasta actividades únicas, nos aseguramos de que cada aspecto de tu viaje sorpresa esté cuidadosamente seleccionado para ofrecerte una experiencia de viaje emocionante y sin complicaciones."
            },
            {
                title: "¿Cómo maneja Rubikfly.es las preferencias y restricciones de viaje de los clientes en viajes sorpresa?",
                answer: "En Rubikfly.es, personalizamos cada viaje sorpresa tomando en cuenta tus preferencias y restricciones. Antes de finalizar tu reserva, puedes indicarnos cualquier requisito especial, como preferencias de alojamiento o necesidades dietéticas, para asegurarnos de que tu experiencia sea tan cómoda y disfrutable como emocionante."
            },
            {
                title: "¿Puedo regalar un viaje sorpresa a través de Rubikfly.es? ¿Cómo funciona?",
                answer: "Sí, puedes regalar un viaje sorpresa con Rubikfly.es. Simplemente elige la opción de regalo en nuestra página de reserva, selecciona el rango de fechas potencial y paga el viaje. El destinatario recibirá un bono regalo que puede activar en nuestra página web para descubrir su destino sorpresa y planificar su aventura."
            },
            {
                title: "¿Cómo garantiza Rubikfly.es la calidad de los alojamientos en los viajes sorpresa?",
                answer: "Todos los alojamientos seleccionados por Rubikfly.es para los viajes sorpresa cumplen con estándares de calidad rigurosos. Evaluamos todas las opciones de hospedaje basándonos en la comodidad, ubicación, servicios, y reseñas de clientes anteriores para asegurar que tu estancia sea parte de una experiencia de viaje excepcional."
            },
            {
                title: "¿Qué tipo de asistencia ofrece Rubikfly.es a los viajeros durante un viaje sorpresa?",
                answer: "Rubikfly.es proporciona asistencia completa durante tu viaje sorpresa. Desde soRubikfly.es proporciona asistencia completa durante tu viaje sorpresa. Desde soporte en línea 24/7 hasta guías detalladas sobre tu destino, aseguramos que tienes toda la información y apoyo necesarios para disfrutar de tu viaje. Además, en casos de emergencia o imprevistos, nuestro equipo está preparado para asistirte de inmediato, garantizando una experiencia segura y sin preocupaciones."
            },
            {
                title: "¿Cómo puedo hacer un seguimiento de mi viaje sorpresa con Rubikfly.es?",
                answer: "Para hacer un seguimiento de tu viaje sorpresa, Rubikfly.es ofrece una plataforma en línea donde puedes acceder a detalles de tu viaje, como confirmaciones de vuelo y reservaciones de hotel. Además, te enviaremos notificaciones por correo electrónico con todas las actualizaciones importantes sobre tu viaje."
            },
        ],
        down_img: "../imgs/angle-down-solid.svg",
        up_img: "../imgs/chevron-up-solid.svg",
      };
    },
    beforeMount() {
      this.$emit('hasPrice', 0);
      window.scrollTo(0,0);
    },
    methods:{
        openClose(key){
            let answer = document.getElementById("answer"+key);
            if(answer.classList.contains("hidden")){
                answer.classList.remove("hidden");
                this.toggleUpDown(key, this.up_img);
            }else{
                answer.classList.add("hidden");
                this.toggleUpDown(key, this.down_img);
            }
        },
        toggleUpDown(key, url){
            let img = document.getElementById("img"+key);
            img.src = url;
            if(url == this.up_img){
                img.style.paddingTop = "4px";
            }else{
                img.style.paddingTop = "0";
            }
        },
    }
  }
  </script>
  