var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "opinions-component-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", {}, [
      _c("div", { staticClass: "slider-container" }, [
        _c(
          "div",
          { staticClass: "slider", attrs: { id: "slider1" } },
          _vm._l(_vm.opinions, function (op, index) {
            return _c("opinion-component", {
              key: index,
              staticClass: "slider__element",
              attrs: {
                name: op.name,
                img: op.img,
                opinion: op.comment,
                date: op.date,
                stars: op.stars,
                id: index,
                index: op.index,
              },
            })
          }),
          1
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "slider", attrs: { id: "slider2" } },
          _vm._l(_vm.opinions2, function (op, index) {
            return _c("opinion-component", {
              key: index,
              staticClass: "slider__element",
              attrs: {
                name: op.name,
                img: op.img,
                opinion: op.comment,
                date: op.date,
                stars: op.stars,
                id: index,
                index: op.index,
              },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titles-container" }, [
      _c("h3", [
        _vm._v(
          "TODAS LAS OPINIONES SON VERIFICADAS POR NUESTRO EQUIPO, DEJA TU OPINION DE TU VIAJE SORPRESA..."
        ),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("¿Que opinan nuestros Rubikers?")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slider-buttons" }, [
      _c("button", { staticClass: "button", attrs: { id: "button-left" } }, [
        _vm._v("<"),
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "button", attrs: { id: "button-right" } }, [
        _vm._v(">"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }