<template>
    <div :id="'hotel-component'+id" class="hotel-component">
        <div class="img-container" :id="'hotel-img'+id">

        </div>
        <div class="hotel-info">
            <div class="main-info">
                <h6>
                    {{location}} - {{name}}
                    
                </h6>
            </div>
            <div>
                <p>{{description}}</p>
            </div>
            <div class="valorations-container">
                <div class="valorations">
                    <div>
                        <p class="opinion">{{opinion}}</p>
                        <p class="comments-number">{{comments_number}} comentarios</p>
                        <p class="ubication">Ubicación {{ubication}}</p>
                    </div>
                    <div class="punctuation">
                        <div class="punctuation-container">
                            {{punctuation}}
                        </div>
                    </div>
                    <div class="booking-punctuation">
                        <div class="stars">
                            <img src="/imgs/star-solid.svg" alt="" v-for="(star, key) in stars" :key="'star'+key">
                        </div>
                        <p>Puntuación en Booking</p>
                    </div>
                    
                </div>
            </div>
            
            
        </div>
    </div>
</template>

<script>
export default{
    props:[
        'img',
        'name',
        'stars',
        'location',
        'description',
        'opinion',
        'comments_number',
        'ubication',
        'punctuation',
        'id'
    ],
    mounted(){
        if(this.img!='none'){
            document.getElementById("hotel-img"+this.id).style.backgroundImage=`url('${this.img}')`;
        }
    }
}
</script>
