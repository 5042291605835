var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "fixed navbar-container" }, [
      _c(
        "nav",
        {
          staticClass: "navbar main-nav navbar-expand-lg",
          attrs: { id: "main-nav" },
        },
        [
          _c(
            "div",
            {
              class:
                "width100 navbar-logo-button" +
                (_vm.hasPrice ? " flex__space-between" : ""),
            },
            [
              _c(
                "div",
                { staticClass: "navbar-brand" },
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("img", {
                      attrs: {
                        src: "/imgs/logo.webp",
                        alt: "Logotipo RUBIKFLY.es, viajes sorpresa con vuelos y hotel incluidos",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("h1", { staticStyle: { display: "none" } }, [
                    _vm._v(
                      "RUBIKFLY.ES | Reserva tu viaje sorpresa online vuelos y hotel\n                        incluidos | Desde 150€"
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.hasPrice
                ? _c(
                    "button",
                    {
                      staticClass: "navbar-toggler",
                      attrs: {
                        type: "button",
                        "data-toggle": "collapse",
                        "data-target": "#navbarSupportedContent",
                        "aria-controls": "navbarSupportedContent",
                        "aria-expanded": "false",
                        "aria-label": "Toggle navigation",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openMenu()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "nav-bars-img",
                        attrs: { src: "/imgs/bars-solid.svg", alt: "" },
                      }),
                    ]
                  )
                : _c("div", { staticClass: "navbar-price" }, [
                    _c("span", { staticClass: "navbar__total-price-text" }, [
                      _vm._v("Precio Total:"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "color-rubikfly has-price" }, [
                      _vm._v(_vm._s(_vm.hasPrice) + "€"),
                    ]),
                  ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "collapsable-main-menu" },
            },
            [
              !_vm.hasPrice
                ? _c("ul", { staticClass: "navbar-nav ml-auto" }, [
                    _c(
                      "li",
                      {
                        staticClass: "nav-item",
                        on: {
                          click: function ($event) {
                            return _vm.closeMenu()
                          },
                        },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: { to: "/como-funciona" },
                          },
                          [_c("span", [_vm._v("COMO FUNCIONA")])]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "nav-item",
                        on: {
                          click: function ($event) {
                            return _vm.closeMenu()
                          },
                        },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: { to: "/hoteles" },
                          },
                          [_c("span", [_vm._v("HOTELES")])]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "nav-item",
                        on: {
                          click: function ($event) {
                            return _vm.closeMenu()
                          },
                        },
                      },
                      [
                        _c(
                          "router-link",
                          { staticClass: "nav-link", attrs: { to: "/blogs" } },
                          [_c("span", [_vm._v("BLOGS")])]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "nav-item show-on-collapse",
                        on: {
                          click: function ($event) {
                            return _vm.closeMenu()
                          },
                        },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: { to: "/about-us" },
                          },
                          [_c("span", [_vm._v("SOBRE NOSOTROS")])]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "nav-item",
                        on: {
                          click: function ($event) {
                            return _vm.closeMenu()
                          },
                        },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: { to: "/regala-rubikfly" },
                          },
                          [
                            _c("span", { staticClass: "rubikfly-gift" }, [
                              _c("img", {
                                staticClass: "gift-img",
                                attrs: { src: "/imgs/gift-solid.svg", alt: "" },
                              }),
                              _vm._v(
                                "REGALA\n                                RUBIKFLY"
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "router-view" },
      [
        _c("router-view", {
          attrs: {
            destination: _vm.destination,
            origin: _vm.origin,
            days_num: _vm.days_num,
            gift: _vm.gift,
            adults: _vm.adults,
            kids: _vm.kids,
            babies: _vm.babies,
          },
          on: { clicked: _vm.onClickChild, hasPrice: _vm.getHasPrice },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cookies-banner", attrs: { id: "cookies-banner" } },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "cookies-buttons" }, [
          _c(
            "div",
            {
              staticClass: "close-cookies-banner accept-cookies",
              on: {
                click: function ($event) {
                  return _vm.closeCookiesBanner()
                },
              },
            },
            [_vm._v("Aceptar")]
          ),
          _vm._v(" "),
          _c("a", { attrs: { href: "/politica-de-cookies" } }, [
            _c(
              "div",
              {
                staticClass: "close-cookies-banner decline-cookies",
                on: {
                  click: function ($event) {
                    return _vm.closeCookiesBanner()
                  },
                },
              },
              [_vm._v("Rechazar")]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("footer", [
      _c("div", { staticClass: "footer-container" }, [
        _c("div", { staticClass: "footer-menu" }, [
          _c("div", [
            _c("div", { staticClass: "footer-list" }, [
              _c("b", [_vm._v("RUBIKFLY")]),
              _vm._v(" "),
              _c("ul", [
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/como-funciona" } }, [
                      _vm._v("Como funciona"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/regala-rubikfly" } }, [
                      _vm._v("Canjea tu cheque regalo"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/hoteles" } }, [
                      _vm._v("Hoteles"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/about-us" } }, [
                      _vm._v("Sobre nosotros"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/preguntas-frecuentes" } },
                      [_vm._v("Preguntas frecuentes")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "footer-list" }, [
              _c("b", [_vm._v("EMPRESA")]),
              _vm._v(" "),
              _c("ul", [
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/aviso-legal" } }, [
                      _vm._v("Aviso Legal"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/politica-de-cookies" } },
                      [_vm._v("Política de Cookies")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/politica-de-privacidad" } },
                      [_vm._v("Política de privacidad")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(3),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "center-text copyright" }, [
        _c("p"),
        _vm._m(4),
        _vm._v(" "),
        _c("p"),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                ©" +
              _vm._s(new Date().getFullYear()) +
              " RUBIKFLY.ES © All rights reserved\n                "
          ),
          _c("br"),
          _vm._v(
            "\n                CentreBit Menorca, local 4 1r planta. Av. des Camp Verd, 4, 07730 Alaior, Illes Balears\n                "
          ),
          _c("br"),
          _vm._v(
            "\n                Agencia registrada AV0030ME\n                "
          ),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v("\n                Design and Development by "),
          _c(
            "a",
            {
              attrs: { target: "v_blank", href: "https://navegaenbarco.com/" },
            },
            [_vm._v("Navega en Barco")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            Este sitio web utiliza cookies, tanto propias como de terceros, para recopilar información estadística\n            sobre su navegación y mostrarle\n            publicidad relacionada con sus preferencias, generada a partir de sus pautas de navegación. Si continúa\n            navegando, consideramos que acepta su uso.\n            Para mas información clique "
      ),
      _c("a", { attrs: { href: "/politica-de-cookies" } }, [_vm._v("aquí")]),
      _vm._v(".\n        "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "/trabaja-con-nosotros" } }, [
        _vm._v("Trabaja con nosotros"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        { attrs: { target: "v_blank", href: "imgs/TerminosYCondiciones.pdf" } },
        [_vm._v("Términos de Condiciones")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "payments" }, [
      _c("b", [_vm._v("Pago Seguro")]),
      _vm._v(" "),
      _c("p", [_vm._v("Paga con tu tarjeta habitual")]),
      _vm._v(" "),
      _c("div", [
        _c("div", [
          _c("img", { attrs: { src: "/imgs/tarjetas.webp", alt: "" } }),
          _vm._v(" "),
          _c("img", { attrs: { src: "/imgs/paypal.webp", alt: "" } }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contain globalReview" }, [
      _c(
        "div",
        {
          attrs: {
            id: "review-aggregate",
            itemscope: "",
            itemtype: "https://schema.org/Product",
          },
        },
        [
          _c("span", { attrs: { itemprop: "name" } }, [
            _vm._v("Opiniones clientes"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "rating",
              attrs: {
                itemprop: "aggregateRating",
                itemscope: "",
                itemtype: "https://schema.org/AggregateRating",
              },
            },
            [
              _c("p", { staticStyle: { "p.span{ background-color": "gold" } }, [
                _c("span", [_vm._v("★")]),
                _c("span", [_vm._v("★")]),
                _c("span", [_vm._v("★")]),
                _c("span", [_vm._v("★")]),
                _c("span", [_vm._v("★")]),
                _c("br"),
                _vm._v("\n                            Nota: "),
                _c("span", { attrs: { itemprop: "ratingValue" } }, [
                  _vm._v("4.9"),
                ]),
                _vm._v("/ 5\n                            calculada de "),
                _c("span", { attrs: { itemprop: "reviewCount" } }, [
                  _vm._v("150"),
                ]),
                _vm._v(" opiniones\n                        "),
              ]),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }