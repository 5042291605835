<template>
    <div>
        <h1>Terminos y condiciones</h1>
    </div>
</template>
<script>
export default {
    beforeMount(){
        this.$emit('hasPrice', 0);
    }
}
</script>