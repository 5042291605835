<template>
    <div class="legal-advice">
        <h1>Aviso Legal</h1>
        <h2>
            CONTENIDO PÁGINA WEB
        </h2>
        <p>
            Cualquier página web está obligada a velar por las prescripciones establecidas en la Ley 34/2002, de Servicios de la Sociedad de la Información
            y de Comercio Electrónico (LSSICE), y en la Directiva Europea 2000/31/CE.
        </p>

        <p>
            Esta normativa establece una serie de obligaciones formales que debe cumplir cualquier página web destinada a la venta directa o indirecta de productos o servicios.
            Si además la página web incluye sistemas para la venta a distancia de productos o servicios (comercio electrónico), deberán cumplirse unas obligaciones adicionales.
            Estas son, a modo resumen, las obligaciones formales que se deberán aplicar a la hora de implantar un e-commerce.
        </p>

        <h2>
            1. OBLIGACIONES DERIVADAS DE LA LSSICE
        </h2>
        <h3>
            1.1 Información sobre el prestador de servicios
        </h3>
        <p>
            La persona, empresa o entidad propietaria de la página web estará obligada a poner a disposición de los usuarios, de forma permanente, fácil, directa y gratuita,
            la siguiente información (este documento es el AVISO LEGAL y se accede a él como enlace en la página de INICIO del sitio web):
        </p>
        <ol class="lower-latin-list">
            <li>
                Identidad del prestador de servicios Tours and Transfers Online SL, con domicilio fiscar en la calle Degollador nº 8, TM Ciutadella de Menorca, Illes Balears.
            </li>
            <li>
                Teléfono de contacto: <a href="tel:+34631387514">631387514</a>
            </li>
            <li>
                E-mail de contacto: <a href="mailto:hola@rubikfly.com">hola@rubikfly.com</a>
            </li>
        </ol>

        <h3>
            1.2 Información y formalismos obligatorios en el proceso de compra online
        </h3>
        <h4>
            1.2.1 Información accesible ANTES de realizar el pedido (contratar)
        </h4>
        <p>
            Además del cumplimiento de los requisitos en materia de información que se establecen en el apartado anterior, la propietaria de la página web deberá, antes de iniciar
            el procedimiento de contratación, poner a disposición del destinatario, de forma permanente, fácil y gratuita, información clara, comprensible e inequívoca sobre los
            siguientes aspectos:
        </p>
        <ol class="lower-latin-list">
            <li>
                Los distintos trámites que deben seguirse para cerrar el contrato.
            </li>
            <li>
                Si el prestador va a archivar el documento electrónico en que se formalizará el contrato y si éste va a ser accesible.
            </li>
            <li>
                Los medios técnicos que la propietaria pone a su disposición para identificar y corregir errores en la introducción de datos.
            </li>
            <li>
                La lengua o lenguas en que podrá formalizarse el contrato. La Ley permite incluir esta información (puntos a,b,c y d) en el AVISO LEGAL general.
            </li>
            <li>
                Las CONDICIONES GENERALES a que, en su caso, deba sujetarse el contrato, de manera que estas puedan ser almacenadas y reproducidas por el destinatario.
            </li>
        </ol>

        <p>
            EXCEPCIONES: El prestador no tendrá la obligación de facilitar la información señalada en el apartado anterior cuando:
        </p>
        <ol>
            <li>
                Ambos contratantes así lo acuerden y ninguno de ellos tenga la consideración de consumidor.
            </li>
            <li>
                El contrato se haya celebrado exclusivamente mediante intercambio de correo electrónico u otro tipo de comunicación electrónica equivalente.
            </li>
        </ol>

        <h4>
            1.2.2 Información POSTERIOR a la realización del pedido
        </h4>

        <p>
            La propietaria está obligada a confirmar la recepción del pedido al emisor, por alguno de los siguientes medios:
        </p>
        <ol class="lower-latin-list">
            <li>
                El envío de un acuse de recibo por correo electrónico u otro medio de comunicación electrónica equivalente, a la dirección que el
                aceptante haya señalado, en el plazo de las veinticuatro horas siguientes a la recepción.
            </li>
            <li>
                Directamente en la misma página web, siempre que se permita al consumidor guardar esta información. Igual que en el apartado anterior,
                no será necesario confirmar la recepción de la aceptación de una oferta cuando:
                <ol>
                    <li>
                        Ambos contratantes así lo acuerden y ninguno de ellos tenga la consideración de consumidor.
                    </li>
                    <li>
                        El contrato se haya celebrado exclusivamente mediante intercambio de correo electrónico u otro tipo de comunicación electrónica equivalente, siempre que estos medios
                        no sean empleados con el exclusivo propósito de eludir el cumplimiento de tal obligación
                    </li>
                </ol>
            </li>
        </ol>

        <p>
            <b>
                A efectos de prueba, se recomienda que el proceso de contratación se realice mediante un sistema que permita acreditar que el usuario acepta las condiciones y confirma la
                compra de los productos una vez ha podido acceder a ellas.
            </b>
        </p>
        <h2>
            2. OBLIGACIONES DERIVADAS DE LA NORMATIVA EN MATERIA DE PROTECCION DE DATOS
        </h2>
        <p>
            Además de las obligaciones establecidas por la LSSICE, deberán tenerse en cuenta las obligaciones generales derivadas de la LOPD-RGPD
            con las que se estará cumpliendo al haber contratado dicho servicio en esta página web:
        </p>
        <ol class="lower-latin-list">
            <li>
                Mantener un registro de actividades de tratamiento.
            </li>
            <li>
                Cumplir con el deber de INFORMACIÓN y CONSENTIMIENTO.
            </li>
            <li>
                Contratos con ENCARGADOS DE TRATAMIENTO.
            </li>
            <li>
                Cumplir con las medidas de seguridad correspondientes.
            </li>
        </ol>

        <h2>
            OTRAS OBLIGACIONES
        </h2>
        <p>
            Debemos tener en cuenta que, al margen de las obligaciones indicadas, que son las más importantes en cualquier proceso de compra por internet y que obligan a incluir
            una serie de formalismos previos a la implantación de la página web, existen otras obligaciones derivadas de otras normas de carácter general que pueden
            afectar a cualquier nuevo establecimiento (ya sea real o virtual), al proceso de compra o incluso a los destinatarios.
        </p>
        <p>
            Esta normativa deberemos tenerla presente en el momento de redactar las condiciones generales de contratación y a la hora de prestar el servicio
            (por ejemplo, el plazo para proceder a la devolución de un pedido en virtud de la Ley de Consumidores).
        </p>
        <p>
            Las normas más importantes son:
        </p>
        <ol class="lower-latin-list">
            <li>
                Real Decreto 1906/1999, de 17 de diciembre, por el que se regula la contratación telefónica o electrónica con condiciones generales.
            </li>
            <li>
                Ley 44/2006 de 29 de diciembre, de mejora de la protección de los consumidores y usuarios.
            </li>
            <li>
                RDL 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios (TRLGDCU).
            </li>
            <li>
                Ley 29/2009, de 30 de diciembre, por la que se modifica el régimen legal de la competencia desleal y de la publicidad para la mejora de la protección de los usuarios y consumidores.
            </li>
            <li>
                Ley 3/2014, de 27 de marzo, por la que se modifica el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios.
            </li>
            <li>
                Ley 7/1998, de 13 de abril, sobre Condiciones Generales de Contratación, y modificaciones posteriores.
            </li>
            <li>
                Ley 7/1996, de 15 de enero de Ordenación del Comercio Minorista.
            </li>
        </ol>
        <h2>
            NORMATIVA DE COOKIES
        </h2>
        <p>
            Otra obligación de la LSSICE es la aceptación del uso de cookies por parte de los usuarios que acceden en la web. El prestador de servicios, en el momento que instale
            cookies de terceros en los terminales de los usuarios les tiene que pedir el
            consentimiento para poderlo hacer. (más info en página <a href="/politica-de-cookies">POLÍTICA COOKIES</a>)
        </p>

        <h2>
            CONCLUSIONES
        </h2>
        <p>
            La venta de productos por internet viene regulada específicamente por la LSSICE y la normativa vigente en materia de protección de datos (LOPD-RGPD), debiéndose tener en cuenta
            otras leyes que pueden afectar directa o indirectamente a la forma de prestar el servicio.
        </p>
        <p>
            La mayoría de las obligaciones que se establecen son de carácter formal. Lo recomendable es disponer en la página web de forma accesible mediante enlaces la siguiente información:
            ‘aviso legal’, ‘política de privacidad’, ‘política de cookies’ y ‘condiciones generales de contratación’ (para los e-commerce).
            Estos textos se los proporciona nuestra plataforma en su área documental.
        </p>
        <p>
            Además, para el caso de los e-commerce el proceso de compra deberá realizarse de manera que quede claro que el consumidor puede acceder a la información necesaria
            antes de contratar, que acepta las condiciones y, una vez se haya realizado la contratación, que pueda acceder (y guardarse) la confirmación. Por todo ello, le recomendamos
            que en caso de una tienda online, realice una auditoria completa en materia de LSSICE para que las condiciones se ajusten del todo a su actividad.
        </p>
        <p>
            Si desea llevar a cabo una auditoria a medida sobre esta materia, contacte con nosotros para hacerle un presupuesto.
        </p>
    </div>
</template>
<script>
export default {
    beforeMount(){
        this.$emit('hasPrice', 0);
    }
}
</script>
