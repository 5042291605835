<template>
    <div class="admin-view">
        <nav class="admin-left-menu">
            <ul>
                <li>
                    <router-link to="/admin">INICIO</router-link>
                </li>
                <li>
                    <p @click="openBookingEngine()" class="margin0 pointer">MOTOR DE RESERVAS</p>
                    <ul id="booking-engine-sublist" class="hidden sublist">
                        <li><router-link to="/admin/reservas" >Reservas</router-link></li>
                        <li>Clientes</li>
                        <li>Facturas</li>
                    </ul>
                </li>
                <li>
                    <p @click="openContentManagement()" class="margin0 pointer">GESTION DE CONTENIDOS</p>
                    <ul id="content-management-sublist" class="hidden sublist">
                        <li><router-link to="/admin/calendarios">Calendarios</router-link></li>
                        <li><router-link to="/admin/aeropuertos">Aeropuertos</router-link></li>
                        <li><router-link to="/admin/precios-extras">Precios Extras</router-link></li>
                    </ul>
                </li>
                <li>IDIOMAS</li>
            </ul>
        </nav>
        <router-view class="admin-router-view"></router-view>
    </div>
</template>
<script>

export default {
    data: function(){
        return {
            booking_engine_opened: false,
            content_management_opened: false,
        }
    },
    methods:{
        openBookingEngine(){
            let booking_engine_sublist = document.getElementById("booking-engine-sublist");

            if(!this.booking_engine_opened){
                booking_engine_sublist.classList.remove("hidden");
                this.booking_engine_opened = true;
            }else{
                booking_engine_sublist.classList.add("hidden");
                this.booking_engine_opened = false;
            }
        },
        openContentManagement(){
            let content_management_sublist = document.getElementById("content-management-sublist");

            if(!this.content_management_opened){
                content_management_sublist.classList.remove("hidden");
                this.content_management_opened = true;
            }else{
                content_management_sublist.classList.add("hidden");
                this.content_management_opened = false;
            }
        }
    }
}
</script>
