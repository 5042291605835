<template>
    <div class="calendar-component">
        <div class="breadcrumb-container">
            <ul class="breadcrumb">
                <li><router-link to="/">Inicio</router-link></li>
                <li><router-link to="/calendar">Fechas</router-link></li>
                <li>Destinos</li>
                <li>Extras</li>
            </ul>
        </div>
        <div class="calendar-titles">
            <h2>Selecciona el día de salida</h2>
            <h4>Selecciona el día de inicio de tu viaje. Se aplicará la tarifa correspondiente a ese día.</h4>
        </div>

        <div class="calendar-container">
            <vc-calendar is-expanded :attributes="attrs" :columns="$screens({ default: 1, sm: 2 })"
                :rows="$screens({ default: 2, sm: 1 })" color="red" @dayclick="onDayClick" :min-date="min_date"
                :max-date="max_date" :step="1"></vc-calendar>
        </div>

        <div class="rates-container">
            <ul class="rates">
                <li>
                    <div class="high-demand"></div> <span>Alta demanda (+{{ high_request_price }}€)</span>
                </li>
                <li>
                    <div class="last-places"></div> <span>Ultimas plazas (+{{ last_seats_price }}€)</span>
                </li>
                <li>
                    <div class="blocked"></div> <span>Plazas agotadas</span>
                </li>
                <li>
                    <div class="standard"></div> <span>Tarifa estándar</span>
                </li>
            </ul>
        </div>

        <div class="calendar-button-container">
            <button v-if="days_selected.length != 0" @click="goToNextStep()">Avanzamos</button>
            <button v-else class="disabled">Avanzamos</button>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'destination',
        'origin',
        'days_num',
        'gift',
        'adults',
        'kids',
        'babies'
    ],
    data: function () {

        return {
            days: [],
            now: new Date(),
            min_date: new Date(),
            max_date: new Date(),
            max_date_to_book: new Date(),
            attrs: [],
            days_selected: [],
            own_destination: this.destination,
            own_origin: this.origin,
            origin_price: 0,
            own_days_num: this.days_num,
            own_gift: this.gift,
            own_adults: this.adults,
            own_kids: this.kids,
            own_babies: this.babies,
            people: 0,
            plus_price: 0,
            high_request_price: 0,
            last_seats_price: 0,
            extra_day_base_price: 0,
            total_amount: 0,
        }
    },
    beforeMount() {
        this.min_date = new Date();
        this.max_date.setDate(this.max_date.getDate() + 210);
        this.max_date_to_book.setDate(this.max_date_to_book.getDate() + 365 - this.days_num + 1);
        this.attrs = [

            {

                key: 'plus50',
                highlight: {
                    style: {
                        background: '#FFBB00'
                    },
                    contentStyle: {
                        color: '#FFF'
                    }
                },
                dates: []
            },
            {
                key: 'plus100',
                highlight: {
                    style: {
                        background: '#FF0000'
                    },
                    contentStyle: {
                        color: '#FFF'
                    }
                },
                dates: []
            },
            {
                key: 'locked',
                highlight: {
                    style: {
                        background: '#FFF'
                    },
                    contentStyle: {
                        color: '#CCC'
                    },

                },
                isDisabled: true,
                dates: []
            },
            {
                key: 'disabled',
                dates: [],
                isDisabled: true,
            },
            {
                key: 'normal',
                highlight: {
                    style: {
                        background: '#279fb0'
                    },
                    contentStyle: {
                        color: '#FFF'
                    }
                },
                dates: []
            }

        ]
        let LSObject = JSON.parse(localStorage.getItem('main-data'));
        // console.log(LSObject);
        const now = new Date();
        if (LSObject) {
            if (now.getTime() > LSObject.expires) {
                localStorage.removeItem('main-data');
                this.$router.push({ name: 'Home' });
            } else {
                this.own_destination = LSObject.destination;
                this.own_origin = LSObject.origin;
                // console.log( LSObject.origin,'origin');
                this.own_days_num = parseInt(LSObject.days_num);
                this.own_gift = LSObject.gift;
                this.own_adults = LSObject.adults;
                this.own_kids = LSObject.kids;
                this.own_babies = LSObject.babies;
                this.origin_price = LSObject.origin_price;
            }
        } else {
            this.$router.push({ name: 'Home' });
        }
        // console.log(this.origin,'beforemount');
        // console.log(this.own_origin,'beforemount');
        this.people = this.own_adults + this.own_kids + this.own_babies;

        this.emitData();
        this.getNormalDays();
        this.getHighRequest();
        this.getLastSeats();

        this.getDaysPrices();
        this.getLocked();
        this.getDisabled();


    },
    mounted() {
    },
    computed: {
        dates() {
            return this.days.map(day => day.date);
        },
        attributes() {
            return this.dates.map(date => ({
                highlight: true,
                dates: date,
            }))
        },
        async total_price() {
            let people = this.adults + this.kids + this.babies;
            this.extra_day_base_price = await this.getExtraDayPrice();
            let total = (people * this.origin_price) + (this.extra_day_base_price * people) + this.plus_price;
            this.$emit('hasPrice', total);
            // console.log(total);
            return (people * this.origin_price) + (this.extra_day_base_price * people * (this.own_days_num - 3)) + this.plus_price;
        }
    },
    methods: {
        async emitData() {
            this.extra_day_base_price = await this.getExtraDayPrice();

            this.total_amount = parseInt((this.origin_price * this.people) + (this.extra_day_base_price * this.people * (this.own_days_num - 3)) + this.plus_price);
            // console.log(this.total_amount);
            // console.log(this.plus_price);
            this.$emit('hasPrice', this.total_amount);
        },
        async getExtraDayPrice() {
            let extra_day_base_price = await axios.get('/extra-price/Dia extra')

            // console.log(extra_day_base_price.data.price);
            this.extra_day_base_price = extra_day_base_price.data.price !== undefined ? extra_day_base_price.data.price : 0;
            //this.$emit('hasPrice', this.total_price);
            return this.extra_day_base_price;
        },
        getDaysPrices() {
            axios.get('/extra-price/Alta Demanda')
                .then(res => {
                    this.high_request_price = res.data.price;
                });

            axios.get('/extra-price/Ultimas Plazas')
                .then(res => {
                    this.last_seats_price = res.data.price;
                });
        },
        getHighRequest() {
            this.attrs[0].dates = [];

            axios.get('/admin/high-request/' + this.own_origin)
                .then(res => {
                    res.data.forEach((element) => {
                        let date = new Date(element.day);
                        if (date.getTime() > this.min_date.getTime() && date.getTime() < this.max_date.getTime()) {
                            this.attrs[0].dates.push(new Date(element.day));
                        }
                    });
                })

        },
        getLastSeats() {
            this.attrs[1].dates = [];
            axios.get('/admin/last-seats/' + this.own_origin)
                .then(res => {
                    res.data.forEach((element) => {
                        let date = new Date(element.day);
                        if (date.getTime() > this.min_date.getTime() && date.getTime() < this.max_date.getTime()) {
                            this.attrs[1].dates.push(new Date(element.day));
                        }

                    });
                })
        },
        getLocked() {
            this.attrs[2].dates = [];
            this.attrs[2].dates.push(new Date());
            axios.get('/admin/locked/' + this.own_origin)
                .then(res => {
                    res.data.forEach((element) => {
                        let date = new Date(element.day);
                        if (date.getTime() > this.min_date.getTime() && date.getTime() < this.max_date.getTime()) {
                            this.attrs[2].dates.push(new Date(element.day));
                        }
                    });
                })
        },


        async getDisabled() {
            this.attrs[3].dates = [];
            const res = await axios.get('/admin/locked/' + this.own_origin);
            res.data.forEach((element) => {
                let date = new Date(element.day);
                if (date.getTime() > this.min_date.getTime() && date.getTime() < this.max_date.getTime()) {
                    this.attrs[3].dates.push(new Date(element.day));
                }
            });
        },
        areDatesEqual(date1, date2) {
            return date1.getFullYear() === date2.getFullYear()
                && date1.getMonth() === date2.getMonth()
                && date1.getDate() === date2.getDate();
        },
        async onDayClick(day) {
            if (this.attrs.length == 6) {
                this.attrs.pop();
            }

            let selected_days = {
                key: 'selected',
                highlight: {
                    style: {
                        background: '#a1f237'
                        //background: '#279fb0'
                    },
                    contentStyle: {
                        color: 'white'
                    }
                },
                dates: []
            }
            this.days_selected = [];
            this.days = [];
            const idx = this.days.findIndex(d => d.id === day.id);
            day.date.setHours(2);
            day.date.setMinutes(0);
            day.date.setSeconds(0);
            day.date.setMilliseconds(0);
            let plus50 = this.attrs[0].dates.find(obj => this.areDatesEqual(obj, day.date));

            let plus100 = this.attrs[1].dates.find(obj => this.areDatesEqual(obj, day.date));

            let blocked = this.attrs[2].dates.find(obj => this.areDatesEqual(obj, day.date));

            // let normal = this.attrs[4].dates.find(obj => this.areDatesEqual(obj, day.date));


            // let bgcolor = ;
            // let txtcolor = plus100 ? "#FF0000" : plus50 ? "#FFBB00" : "#279fb0";
            let txtcolor = "#fff";
            let bgcolor = "#7eb339";
            if (!blocked) {
                if (idx >= 0) {
                    this.days.splice(idx, 1);
                } else {
                    day.date.setHours(this.min_date.getHours());
                    day.date.setMinutes(this.min_date.getMinutes());
                    day.date.setSeconds(this.min_date.getSeconds());
                    day.date.setMilliseconds(this.min_date.getMilliseconds());

                    if (day.date.getTime() >= this.min_date.getTime() && day.date.getTime() <= this.max_date_to_book.getTime()) {
                        for (let i = 0; i < this.own_days_num; i++) {
                            let date = new Date(day.date);
                            this.days_selected.push(new Date(date.setDate(date.getDate() + i)));
                            selected_days.highlight.style.background = bgcolor;
                            selected_days.highlight.contentStyle.color = txtcolor;
                            selected_days.dates.push(
                                date.setDate(date.getDate()),
                            )
                        }
                    }
                    this.attrs.push(selected_days);
                }
            }

            if (plus100) {
                this.plus_price = this.last_seats_price * (this.own_adults + this.own_kids + this.own_babies);
            } else if (plus50) {
                this.plus_price = this.high_request_price * (this.own_adults + this.own_kids + this.own_babies);
            } else {
                this.plus_price = 0;
            }
            await this.emitData();
        },
        async goToNextStep() {
            const now = new Date();

            let calendarInfo = {
                info: this.days_selected,
                plus_price: this.plus_price,
                total_price: await this.total_price,
                expires: now.getTime() + (1000 * 60 * 60) //1 hour
            }

            localStorage.setItem("calendar-data", JSON.stringify(calendarInfo));
            // console.log(localStorage.getItem("calendar-data"));
            this.$router.push({ name: 'destinations' });
        },
        async getNormalDays() {
            let allDays = [];
            const startDate = new Date();
            const endDate = new Date();
            endDate.setMonth(endDate.getMonth() + 7);
            startDate.setDate(startDate.getDate() + 1);

            while (startDate <= endDate) {
                allDays.push(new Date(startDate));
                startDate.setDate(startDate.getDate() + 1);
            }
            allDays = allDays.map(day => day.toISOString().split('T')[0]);

            let highDays = await this.getHighRequestDays();

            let lastSeatsDays = await this.getLastSeatsDays();

            let lockedDays = await this.getLockedDays();


            let specialDays = [...highDays, ...lastSeatsDays, ...lockedDays];


            let normalDays = allDays.filter(day => !specialDays.includes(day));
            console.log(normalDays);
            normalDays = normalDays.map(day => day.split('T')[0]);

            this.attrs[4].dates = normalDays;
        },
        async getHighRequestDays() {
            try {
                const response = await axios.get('/admin/high-request/' + this.own_origin);
                const days = response.data
                    .filter(element => {
                        let date = new Date(element.day);
                        return date.getTime() > this.min_date.getTime() && date.getTime() < this.max_date.getTime();
                    })
                    .map(element => new Date(element.day).toISOString().split('T')[0]);
                return days;
            } catch (error) {
                console.error("Error fetching high request days:", error);
                return []; // Return an empty array in case of error
            }
        },
        async getLastSeatsDays() {
            try {
                const response = await axios.get('/admin/last-seats/' + this.own_origin);
                const days = response.data
                    .filter(element => {
                        let date = new Date(element.day);
                        return date.getTime() > this.min_date.getTime() && date.getTime() < this.max_date.getTime();
                    })
                    .map(element => new Date(element.day).toISOString().split('T')[0]);
                return days;
            } catch (error) {
                console.error("Error fetching last seats days:", error);
                return []; // Return an empty array in case of error
            }
        },

        async getLockedDays() {
            try {
                const response = await axios.get('/admin/locked/' + this.own_origin);
                const days = response.data
                    .filter(element => {
                        let date = new Date(element.day);
                        return date.getTime() > this.min_date.getTime() && date.getTime() < this.max_date.getTime();
                    })
                    .map(element => new Date(element.day).toISOString().split('T')[0]);
                return days;
            } catch (error) {
                console.error("Error fetching locked days:", error);
                return []; // Return an empty array in case of error
            }
        }


    }
}
</script>
