<template>
    <div class="promotions-container">
        <h3>¡No te pierdas nuestras promociones!</h3>
        <p>Infórmate de nuestros descuentos, noticias y nuevos destinos en nuestra newsletter. ¿A qué estás esperando? Únete a nuestra comunidad y sé el primero en disfrutar de promociones únicas y ofertas exclusivas. Cada viaje es una aventura cuidadosamente seleccionada para que no tengas que planificar. Vive una experiencia única en un destino europeo sorpresa.    </p>
        <div>
            <form action="/newsletter">
                <input class="email" type="email" placeholder="E-mail">
                <input class="send" type="submit" value="ENVIAR">
            </form>
        </div>
    </div>
</template>
<script>
export default {
    mounted(){

    }
}
</script>
