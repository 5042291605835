<template>
    <div>
        <hotels-component></hotels-component>
    </div>
</template>
<script>
export default {
    beforeMount(){
        this.$emit('hasPrice', 0);
    }
}
</script>