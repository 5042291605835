var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "opinion-container",
      attrs: { id: "opinion-container" + _vm.id },
    },
    [
      _c("div", { staticClass: "opinion-info-container" }, [
        _c("div", { staticClass: "img-outter-container" }, [
          _c(
            "div",
            {
              staticClass: "img-container",
              attrs: { id: "img-container" + _vm.id + _vm.index },
            },
            [
              _vm.img == "none"
                ? _c("h1", [_vm._v(_vm._s(_vm.name.charAt(0)))])
                : _vm._e(),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "opinion-info" }, [
          _c("h2", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "stars" },
            _vm._l(_vm.stars, function (star, key) {
              return _c("img", {
                key: "star" + key,
                attrs: { src: "/imgs/star-solid.svg", alt: "" },
              })
            }),
            0
          ),
          _vm._v(" "),
          _c("p", { staticClass: "date" }, [_vm._v(_vm._s(_vm.date))]),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "opinion" }, [_vm._v(_vm._s(_vm.opinion))]),
      _vm._v(" "),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "see-more" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.google.com/search?q=rubikfly#lrd=0x12be03cf844e2523:0x617a0d9dc0419aa7,1,,,",
            target: "v_blank",
          },
        },
        [_vm._v("Ver mas reseñas en Google")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }