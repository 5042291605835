<template>
    <div>
        <h1>Booking</h1>
        <div>
            <div>
                Desde
            </div>
            <div>
                Hasta
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: function(){
        return{
            booking: {},
        }
    },
    beforeMount(){
        
    },
}
</script>