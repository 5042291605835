<template>
    <div class="blog-view">
      <div class="hide-on-mobile">
        <header-component v-on="$listeners"></header-component>
        <link rel="icon" href="/imgs/favicon.webp" />
      </div>
      <div class="main">
        <h1>{{ blog.title }}</h1>
        <p v-html="blog.content"></p>
      </div>
    </div>
  </template>

  <script>
  import axios from 'axios';

  export default {
    data() {
      return {
        blog: {}
      }
    },
    props: {
      slug: String
    },
    created() {
      const slug = this.$route.params.slug;
      axios.get(`/api/blog/${slug}`)
        .then(response => {
          this.blog = response.data;
          this.updateMetaTags(this.blog.title, this.blog.meta_description);
          this.updateCanonicalLink(`https://rubikfly.es/blogs/${slug}`);
        })
        .catch(error => {
          console.error(error);
        });
    },
    methods: {
      updateMetaTags(title, description) {
        if (document) {
          document.title = title; // Sets the page title
          const metaDesc = document.querySelector('meta[name="description"]');
          if (metaDesc) {
            metaDesc.setAttribute('content', description);
          } else {
            const meta = document.createElement('meta');
            meta.name = "description";
            meta.content = description;
            document.getElementsByTagName('head')[0].appendChild(meta);
          }
        }
      },
      updateCanonicalLink(url) {
        let link = document.querySelector('link[rel="canonical"]');
        if (!link) {
          link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.setAttribute('href', url);
      }
    }
  }
  </script>

  <style scoped>
  .h2 {
    font-size: 1rem;
  }
  </style>
