import VueRouter from 'vue-router'
import Vue from 'vue'
import Home from './views/Home.vue'
import Contact from './views/Contact.vue'
import Hotels from './views/HotelsView.vue'
import GiveAway from './views/GiveAwayRubikfly.vue'
import HowItWorks from './views/HowItWorks.vue'
import AboutUs from './views/AboutUs.vue'
import Calendar from './views/Days.vue'
import Destinations from './views/DestinationsView.vue'
import Extras from './views/Extras.vue'
import Finalize from './views/Finalize.vue'
import Admin from './admin/views/Home.vue'
import Bookings from './admin/views/Bookings.vue'
import Calendars from './admin/views/Calendars.vue'
import Airports from './admin/views/Airports.vue'
import ExtraPrices from './admin/views/ExtraPrices.vue'
import Booking from './admin/views/Booking.vue'
import Terms from './views/Terms.vue'
import Cookies from './views/Cookies.vue'
import PrivacyPolicy from './views/PrivacyPolicy.vue'
import LegalAdvice from './views/LegalAdvice.vue'
import FAQ from './views/FAQ.vue'
import WorkWithUs from './views/WorkWithUs.vue'
import Blogs from './views/Blogs.vue'
import BlogView from './views/BlogView.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },{
    path: '/hoteles',
    name: 'hotels',
    component: Hotels
  },{
    path: '/como-funciona',
    name: 'como-funciona',
    component: HowItWorks
  },{
    path: '/regala-rubikfly',
    name: 'regala-rubikfly',
    component: GiveAway
  },{
    path: '/about-us',
    name: 'sobre-nosotros',
    component: AboutUs
  },{
    path: '/calendar',
    name: 'calendar',
    component: Calendar
  },{
    path: '/destinos',
    name: 'destinations',
    component: Destinations
  },{
    path: '/extras',
    name: 'extras',
    component: Extras
  },{
    path: '/finaliza',
    name: 'finaliza',
    component: Finalize
  },
  {
    path: '/terminos-y-condiciones',
    name: 'terminos',
    component: Terms
  },
  {
    path: '/trabaja-con-nosotros',
    name: 'work-with-us',
    component: WorkWithUs
  },
  {
    path: '/politica-de-cookies',
    name: 'cookies',
    component: Cookies
  },{
    path: '/aviso-legal',
    name: 'legal-advice',
    component: LegalAdvice
  },{
    path: '/politica-de-privacidad',
    name: 'privacy-policy',
    component: PrivacyPolicy
  },{
    path: '/preguntas-frecuentes',
    name: 'faq',
    component: FAQ
  },{
    path: '/admin',
    name: 'admin',
    component: Admin,
  },
  {
    path: '/admin/reservas',
    name: 'bookings',
    component: Bookings,
  },
  {
    path: '/admin/calendarios',
    name: 'calendars',
    component: Calendars,
  },
  {
    path: '/admin/aeropuertos',
    name: 'airports',
    component: Airports,
  },
  {
    path: '/admin/precios-extras',
    name: 'extra-prices',
    component: ExtraPrices,
  },
  {
    path: '/admin/booking/:id',
    name: 'booking',
    component: Booking,
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: Blogs,
  },
  {
    path: '/blogs/:slug',
    name: 'blog',
    component: BlogView,
  }
]


const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior () {
      return { x: 0, y: 0 }
    }
});


export default router;
