var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hotels-component" }, [
    _c(
      "div",
      { staticClass: "hide-on-mobile" },
      [_c("header-component", _vm._g({}, _vm.$listeners))],
      1
    ),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "hotels-container" },
      _vm._l(_vm.hotels.slice(0, _vm.num), function (hotel, key) {
        return _c(
          "div",
          { key: key },
          [
            _c("hotel-component", {
              attrs: {
                img: hotel.img,
                name: hotel.name,
                stars: hotel.stars,
                location: hotel.location,
                description: hotel.description,
                opinion: hotel.opinion,
                comments_number: hotel.comments_number,
                ubication: hotel.ubication,
                punctuation: hotel.punctuation,
                id: key,
              },
            }),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "see-more-container" }, [
      _vm.num < _vm.hotels.length
        ? _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.seeMore()
                },
              },
            },
            [_vm._v("VER MÁS HOTELES")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hotels-component-titles" }, [
      _c("h1", [_vm._v("Hoteles")]),
      _vm._v(" "),
      _c("h5", { staticClass: "hotels-description" }, [
        _vm._v(
          "\n            Los hoteles son elegidos con sumo cuidado y pensando siempre con el mejor bienestar de nuestros clientes.\n            Por ello, la calidad de los hoteles elegidos siempre es de 3* o superior. Además, intentamos ubicar a nuestros clientes en los\n            hoteles mas céntricos o bien comunicados de la ciudad de destino.\n        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }