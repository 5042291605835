var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "experiences-component-container" }, [
      _c("div", { staticClass: "titles-container" }, [
        _c("h3", [
          _vm._v("¿TE APETECE ESCAPAR DE LA RUTINA Y VIVIR UN VIAJE SORPRESA?"),
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("Las experiencias de un viaje sorpresa barato")]),
        _vm._v(" "),
        _c("h3", { staticStyle: { "margin-top": "10px", color: "#212529" } }, [
          _vm._v(
            "\n            Un RUBIKFLY es un viaje sorpresa. Conocerás tu destino 2 días antes de la fecha de tu viaje.\n            Podrás viajar por Europa o por España, siempre con la sorpresa de conocer tu destino 48 horas antes.\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "experiences-container" }, [
        _c("div", { staticClass: "experience experience1" }, [
          _c("div", { staticClass: "experience-img experience1-img" }),
          _vm._v(" "),
          _c("h4", [_vm._v("VIAJE SORPRESA POR ESPAÑA")]),
          _vm._v(" "),
          _c("div", [
            _c("p", [
              _vm._v(
                "\n                    Disfruta de la libertad más absoluta de viajar a un precio\n                    irresistible a cualquier ligar de España desde 150€.\n                "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "experience experience2" }, [
          _c("div", { staticClass: "experience-img experience2-img" }),
          _vm._v(" "),
          _c("h4", [_vm._v("VIAJE SORPRESA POR EUROPA")]),
          _vm._v(" "),
          _c("div", [
            _c("p", [
              _vm._v(
                "\n                    Desconecta a un destino sorpresa y conoce lugares increíbles\n                    y ciudades mágicas de Europa durante 3 días.\n                "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "experience experience3" }, [
          _c("div", { staticClass: "experience-img experience3-img" }),
          _vm._v(" "),
          _c("h4", [_vm._v("TARJETA DE REGALO SORPRESA")]),
          _vm._v(" "),
          _c("div", [
            _c("p", [
              _vm._v(
                "\n                    Regala una rarjeta de regalo a tu pareja, familiar o amigo.\n                    ¡Descubrirá la emoción de un destino sorpresa!\n                "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }