<template>
    <div class="cookies-policy">
        <h1>Política de cookies</h1>
        <p>
            Este sitio web utiliza cookies, tanto propias como de terceros, para recopilar información estadística sobre su navegación y mostrarle 
            publicidad relacionada con sus preferencias, generada a partir de sus pautas de navegación. Si continúa navegando, consideramos que acepta su uso. 
        </p>

        <h2>ANTECEDENTES</h2>
        <p>
            De conformidad con la normativa española que regula el uso de cookies en relación a la prestación de servicios de comunicaciones electrónicas, 
            recogida en el Real Decreto Ley 13/2012 del 30 de marzo, le informamos sobre las cookies utilizadas en el sitio web de RUBIKFLY S.L. (también el prestador) 
            y el motivo de su uso. Asimismo, le informa de que al navegar en el Sitio Web usted está prestando su consentimiento para poder utilizarlas.
        </p>

        <h2>¿QUÉ SON LAS COOKIES?</h2>
        <p>
            Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, 
            almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, 
            pueden utilizarse para reconocer al usuario. Las cookies se asocian únicamente a un usuario anónimo y su ordenador o dispositivo y no proporcionan referencias que permitan 
            conocer sus datos personales.
        </p>

        <h2>COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXENTAS</h2>
        <p>
            Según la directiva de la UE, las cookies que requieren el consentimiento informado por parte del usuario son las cookies de analítica y las de publicidad y afiliación, 
            quedando exceptuadas las de carácter técnico y las necesarias para el funcionamiento del sitio web o la prestación de servicios expresamente demandados por el usuario.
        </p>

        <h2>TIPOS DE COOKIES</h2>
        <ol class="lower-latin-list">
            <li>
                Según la entidad que las gestione, hay Cookies propias (aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio 
                editor y desde el que se presta el servicio Solicitado por el usuario) y de terceros (las que se envían al equipo terminal del usuario desde un equipo 
                o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies).
            </li>
            <li>
                Según el tiempo que permanecen activas, existen las de sesión (diseñadas para recabar y almacenar datos mientras el usuario accede a una página web) y las persistentes 
                (en las que los datos se almacenan en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie -puede ir de unos minutos a varios años-).
            </li>
            <li>
                Según la finalidad para la que traten la información que recopilan, pueden ser:
                <ul>
                    <li>
                        Cookies técnicas (necesarias para el uso de la web y la prestación del servicio contratado),
                    </li>
                    <li>
                        Cookies de personalización (que permiten al usuario acceder al servicio con características predefinidas, como por ejemplo el idioma, tipo de navegador, configuración regional, etc.)
                    </li>
                    <li>
                        Cookies de análisis (recogen información el uso que se realiza de la web),
                    </li>
                    <li>
                        Cookies publicitarias (recogen información sobre las preferencias y elecciones personales de los usuarios),
                    </li>
                    <li>
                        Cookies de afiliados (permiten realizar un seguimiento de las visitas procedentes de otras webs, con las que el sitio web establece un contrato de afiliación).
                    </li>
                </ul>
            </li>
        </ol>

        <h3>
            TIPOS DE COOKIES UTILIZADAS POR ESTE SITIO WEB
        </h3>
        <p>
            Las cookies utilizadas en nuestro sitio web son de sesión y de terceros, y nos permiten almacenar y acceder a información relativa al idioma, el tipo de navegador utilizado, 
            y otras características generales predefinidas por el usuario, así como seguir y analizar la actividad que lleva a cabo para mejorar y prestar nuestros servicios de una manera 
            más eficiente y personalizada. Las cookies usadas tienen, en todo caso, carácter temporal, con la única finalidad de hacer más eficaz la navegación. En ningún caso estas 
            cookies proporcionan por sí mismas datos de carácter personal y no se usan para la recogida de los mismos.
        </p>
        <p>
            La utilización de las cookies ofrece ventajas, como por ejemplo:
        </p>
        <ul>
            <li>
                Facilita al usuario la navegación y el acceso a los diferentes servicios que ofrece este sitio web.
            </li>
            <li>
                Evita al usuario tener que configurar las características generales predefinidas cada vez que accede al sitio web.
            </li>
            <li>
                Favorece la mejora del funcionamiento y de los servicios prestados a través de este sitio web, tras el correspondiente análisis de la información 
                obtenida a través de las cookies instaladas.
            </li>
        </ul>

        <p>
            Al acceder a nuestra web se le informa que si sigue navegando se le instalarán diversas cookies de terceros consintiendo así la instalación de determinadas cookies que 
            tendrán como única finalidad la de registrar el acceso a nuestro sitio web para la realización de estadísticas anónimas sobre las visitas, recopilando información siempre 
            de forma anónima. No es necesario que acepte la instalación de estas cookies, podrá navegar igualmente por toda nuestra web.
        </p>

        <p>
            En diversas secciones de nuestra web se podrán instalar cookies de redes sociales, en concreto las siguientes:
        </p>
        <ul>
            <li>
                Cookie de Twitter, según lo dispuesto en su política de privacidad y uso de cookies.
            </li>
            <li>
                Cookie de Facebook, según lo dispuesto en su política de cookies.
            </li>
            <li>
                Cookie de Linkedin, según lo dispuesto en su página sobre el uso de las cookies.
            </li>
            <li>
                Cookie de Google+ y Google Maps, según lo dispuesto en su página sobre qué tipo de cookies utilizan.
            </li>
        </ul>

        <h2>REVOCACIÓN</h2>

        <p>
            En todo momento podrá acceder a la configuración de su navegador aceptando o rechazando todas las cookies, o bien seleccionar aquéllas cuya instalación 
            admite y cuáles no, siguiendo uno de los siguientes procedimientos, que depende del navegador que utilice:
        </p>

        <p><b>Google Chrome</b> (en el Menú Herramientas):</p>
        <p>
            Configuración > Mostrar opciones avanzadas > Privacidad (Configuración de contenido) > Cookies: 
            <a target="v_blank" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktopandhl=es">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktopandhl=es</a> 
        </p>

        <p><b>Microsoft Internet Explorer</b> (en el Menú Herramientas):</p>
        <p>
            Opciones de Internet > Privacidad > Avanzada: 
            <a target="v_blank" href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a>
        </p>

        <p><b>Firefox:</b></p>
        <p>
            Opciones > Privacidad > Cookies: 
            <a target="v_blank" href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a>
        </p>

        <p><b>Safari, iPad y iPhone:</b></p>
        <p>
            Preferencias > Privacidad: 
            <a target="v_blank" href="https://support.apple.com/kb/ph21411?locale=es_ES">https://support.apple.com/kb/ph21411?locale=es_ES</a> 
        </p>

        <p><b>Opera:</b></p>
        <p>
            Configuración > Opciones > Avanzado > Cookies: 
            <a target="v_blank" href="http://help.opera.com/Windows/12.00/es-ES/cookies.html">http://help.opera.com/Windows/12.00/es-ES/cookies.html</a>
        </p>

        <p>
            Estos navegadores están sometidos a actualizaciones o modificaciones, por lo que no podemos garantizar que se ajusten completamente a la versión de su navegador. 
            También puede ser que utilice otro navegador no contemplado en estos enlaces como Konqueror, Arora, Flock, etc. Para evitar estos desajustes, puede acceder directamente 
            desde las opciones de su navegador, generalmente en el menú de "Opciones" en la sección de "Privacidad". (Por favor, consulte la ayuda de su navegador para más información).
        </p>

        <h2>DESACTIVACIÓN/ACTIVACIÓN Y ELIMINACIÓN DE COOKIES</h2>

        <p>
            Para restringir o bloquear las cookies, se hace a través de la configuración del navegador. Si no desea que los sitios web pongan ninguna cookie en su equipo, 
            puede adaptar la configuración del navegador de modo que se le notifique antes de que se coloque ninguna cookie. De igual modo, puede adaptar la configuración 
            de forma que el navegador rechace todas las cookies, o únicamente las cookies de terceros. También puede eliminar cualquiera de las cookies que ya se encuentren en el equipo. 
            Tenga en cuenta que tendrá que adaptar por separado la configuración de cada navegador y equipo que utilice.
        </p>

        <p>
            Tenga en cuenta que si no desea recibir cookies, ya no podremos garantizar que nuestro sitio web funcione debidamente. Puede que algunas funciones del sitio se pierdan 
            y es posible que ya no pueda ver ciertos sitios web. Además, rechazar las cookies no significa que ya no vaya a ver anuncios publicitarios. Simplemente los anuncios no 
            se ajustarán a sus intereses y se repetirán con más frecuencia.
        </p>

        <p>
            Cada navegador posee un método distinto para adaptar la configuración. Si fuera necesario, consulte la función de ayuda del navegador para establecer la configuración correcta.
        </p>

        <p>
            Para desactivar las cookies en el teléfono móvil, consulte el manual del dispositivo para obtener más información. 
            Puede obtener más información sobre las cookies en Internet, <a target="v_blank" href="http://www.aboutcookies.org/">http://www.aboutcookies.org/</a>. 
        </p>

        <p>
            Teniendo en cuenta la forma en la que funciona Internet y los sitios web, no siempre contamos con información de las cookies que colocan terceras partes 
            a través de nuestro sitio web. Esto se aplica especialmente a casos en los que nuestra página web contiene lo que se denominan elementos integrados: 
            textos, documentos, imágenes o breves películas que se almacenan en otra parte, pero se muestran en nuestro sitio web o a través del mismo.
        </p>

        <p>
            Por consiguiente, en caso de que se encuentre con este tipo de cookies en este sitio web y no estén enumeradas en la lista anterior, le rogamos que nos lo comunique. 
            O bien póngase en contacto directamente con el tercero para pedirle información sobre las cookies que coloca, la finalidad y la duración de la cookie, y cómo ha garantizado su privacidad.
        </p>

    </div>
</template>
<script>
export default {
    beforeMount(){
        this.$emit('hasPrice', 0);
    }
}
</script>