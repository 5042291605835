var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "workwithus-view" }, [
    _c(
      "div",
      { staticClass: "hide-on-mobile" },
      [_c("header-component", _vm._g({}, _vm.$listeners))],
      1
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "work-with-us" }, [
      _c("h5", [_vm._v("Sector turismo")]),
      _vm._v(" "),
      _c("h1", [
        _vm._v("La agencia de viajes sorpresa, reserente en el sector"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n            Rubikfly propone una experiencia única de viaje. A través de paquetes de viaje que incluyen hotel + vuelo de ida y vuelta + destino\n            sorpresa a precios muy competitivos.\n        "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n            Si deseas unirte a nuestro equipo de trabajo puedes enviarnos tu currículum a "
        ),
        _c("a", { attrs: { href: "mailto:hola@rubikfly.com" } }, [
          _vm._v("hola@rubikfly.com"),
        ]),
        _vm._v(".\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }