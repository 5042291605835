var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "extras-component" }, [
    _c("div", { staticClass: "breadcrumb-container" }, [
      _c("ul", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Inicio")])],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/calendar" } }, [
              _vm._v("Fechas"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/destinos" } }, [
              _vm._v("Destinos"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/extras" } }, [_vm._v("Extras")])],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "finalize-modal",
        attrs: { id: "finalize-modal" },
        on: {
          click: function ($event) {
            return _vm.hideModal($event)
          },
        },
      },
      [
        _c("div", { staticClass: "finalize-modal-content" }, [
          _c("div", { staticClass: "relative finalize-modal-inner" }, [
            _c("h4", { staticClass: "finalize-modal-content__title" }, [
              _vm._v("Al realizar la reserva ten en cuenta: "),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "finalize-modal-content__button",
                on: {
                  click: function ($event) {
                    return _vm.goToNextStep()
                  },
                },
              },
              [_vm._v("Aceptar")]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "finalize-modal",
        attrs: { id: "trip-insurance-modal" },
        on: {
          click: function ($event) {
            return _vm.hideTripInsuranceModal($event)
          },
        },
      },
      [
        _c("div", { staticClass: "finalize-modal-content" }, [
          _c("div", { staticClass: "relative finalize-modal-inner" }, [
            _c("h4", { staticClass: "finalize-modal-content__title" }, [
              _vm._v("¿Que incluye el seguro de viaje?"),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("Resumen de Garantias Seguro de Viaje")]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _vm._m(5),
            _vm._v(" "),
            _vm._m(6),
            _vm._v(" "),
            _vm._m(7),
            _vm._v(" "),
            _vm._m(8),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "finalize-modal-content__button",
                on: {
                  click: function ($event) {
                    return _vm.hideTripInsuranceModal()
                  },
                },
              },
              [_vm._v("Aceptar")]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "finalize-modal",
        attrs: { id: "cancellation-insurance-modal" },
        on: {
          click: function ($event) {
            return _vm.hideCancellationInsuranceModal($event)
          },
        },
      },
      [
        _c("div", { staticClass: "finalize-modal-content" }, [
          _c("div", { staticClass: "relative finalize-modal-inner" }, [
            _c("h4", { staticClass: "finalize-modal-content__title" }, [
              _vm._v("¿Que incluye el seguro de cancelación?"),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "finalize-modal-content__button",
                on: {
                  click: function ($event) {
                    return _vm.hideCancellationInsuranceModal()
                  },
                },
              },
              [_vm._v("Aceptar")]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "hotels-type-container" }, [
      _c("div", [
        _c("h3", [_vm._v("¿Qué tipo de hotel prefieres?")]),
        _vm._v(" "),
        _c("h6", [_vm._v("Elige en que tipo de hotel te gustaría alojarte")]),
        _vm._v(" "),
        _c("div", { staticClass: "hotel-container" }, [
          _c(
            "div",
            {
              staticClass: "extra-container extra-selected",
              attrs: { id: "h3or4-stars" },
            },
            [
              _c("div", { staticClass: "h3or4-stars-img extra-img" }),
              _vm._v(" "),
              _vm._m(9),
              _vm._v(" "),
              _c("div", { staticClass: "button-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "selected",
                    attrs: { id: "basic-hotel" },
                    on: {
                      click: function ($event) {
                        return _vm.changeHotel("basic")
                      },
                    },
                  },
                  [_vm._v("SELECCIONADO")]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "extra-container", attrs: { id: "higher-hotel" } },
            [
              _c("div", { staticClass: "higher-hotel-img extra-img" }),
              _vm._v(" "),
              _c("div", { staticClass: "texts" }, [
                _c("h4", [_vm._v("Hotel superior + desayuno")]),
                _vm._v(" "),
                _c("p"),
                _vm._v(" "),
                _c("p", [
                  _c("span", { staticClass: "cost" }, [
                    _vm._v(_vm._s(_vm.high_hotel_base_price) + "€"),
                  ]),
                  _vm._v(" / persona"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "button-container" }, [
                _c(
                  "button",
                  {
                    attrs: { id: "extra-hotel" },
                    on: {
                      click: function ($event) {
                        return _vm.changeHotel("extra")
                      },
                    },
                  },
                  [_vm._v("SELECCIONAR")]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "insurances-container", staticStyle: { display: "none" } },
      [
        _c("div", [
          _c("h3", [_vm._v("¿Quieres añadir seguro para tu viaje?")]),
          _vm._v(" "),
          _c("h6", [_vm._v("Elige los seguros que quieres para tu viaje")]),
          _vm._v(" "),
          _c("div", { staticClass: "insurance-container" }, [
            _c(
              "div",
              {
                staticClass: "extra-container relative",
                attrs: { id: "cancellation" },
              },
              [
                _c("div", { staticClass: "cancellation-insurance-img" }),
                _vm._v(" "),
                _c("div", { staticClass: "texts" }, [
                  _c("h4", [_vm._v("Seguro de Cancelación")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("Recupera tu dinero ante posibles cancelaciones"),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", { staticClass: "cost" }, [
                      _vm._v(
                        _vm._s(_vm.cancellation_insurance_base_price) + "%"
                      ),
                    ]),
                    _vm._v(" del total de la reserva"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "button-container" }, [
                  _c(
                    "button",
                    {
                      attrs: { id: "cancellation-insurance" },
                      on: {
                        click: function ($event) {
                          return _vm.changeCancellationInsurance()
                        },
                      },
                    },
                    [_vm._v("SELECCIONAR")]
                  ),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "info-img",
                  attrs: { src: "/imgs/circle-info-solid.svg", alt: "" },
                  on: {
                    click: function ($event) {
                      return _vm.showCancellationInsuranceModal()
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "extra-container relative",
                attrs: { id: "trip" },
              },
              [
                _c("div", { staticClass: "trip-insurance-img extra-img" }),
                _vm._v(" "),
                _c("div", { staticClass: "texts" }, [
                  _c("h4", [_vm._v("Seguro de viaje")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Cúbrete ante cualquier imprevisto durante el viaje"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", { staticClass: "cost" }, [
                      _vm._v(_vm._s(_vm.trip_insurance_base_price) + "€"),
                    ]),
                    _vm._v(" / persona"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "button-container" }, [
                  _c(
                    "button",
                    {
                      attrs: { id: "trip-insurance" },
                      on: {
                        click: function ($event) {
                          return _vm.changeTripInsurance()
                        },
                      },
                    },
                    [_vm._v("SELECCIONAR")]
                  ),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "info-img",
                  attrs: { src: "/imgs/circle-info-solid.svg", alt: "" },
                  on: {
                    click: function ($event) {
                      return _vm.showTripInsuranceModal()
                    },
                  },
                }),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "departure-return-container" }, [
      _c("div", [
        _c("h3", [_vm._v("¿Necesitas limitar horarios de vuelo?")]),
        _vm._v(" "),
        _c("h6", [
          _vm._v(
            "Por cada ajuste horario se suman " +
              _vm._s(_vm.departure_base_price) +
              "€ por persona"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "deperture-return-inner-container" }, [
          _c("div", { staticClass: "extra-container departure-flight" }, [
            _c("div", { staticClass: "departure-flight-img" }),
            _vm._v(" "),
            _c("h5", { staticClass: "center-text" }, [_vm._v("VUELO DE IDA")]),
            _vm._v(" "),
            _c("p", { staticClass: "center-text" }, [
              _vm._v("Haz que los vuelos salgan en tu franja horaria"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "center-text price" }, [
              _vm._v(_vm._s(_vm.departure_hour_price) + "€"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "middle" }, [
              _c("div", { staticClass: "multi-range-slider" }, [
                _c("input", {
                  attrs: {
                    type: "range",
                    id: "input-left",
                    min: "0",
                    max: "100",
                    value: "0",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.setLeftValue()
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "range",
                    id: "input-right",
                    min: "0",
                    max: "100",
                    value: "100",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.setRightValue()
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(10),
              ]),
              _vm._v(" "),
              _vm._m(11),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "extra-container return-flight" }, [
            _c("div", { staticClass: "return-flight-img" }),
            _vm._v(" "),
            _c("h5", { staticClass: "center-text" }, [
              _vm._v("VUELO DE VUELTA"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "center-text" }, [
              _vm._v("Haz que los vuelos salgan en tu franja horaria"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "center-text price" }, [
              _vm._v(_vm._s(_vm.return_hour_price) + "€"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "middle" }, [
              _c("div", { staticClass: "multi-range-slider" }, [
                _c("input", {
                  attrs: {
                    type: "range",
                    id: "return-input-left",
                    min: "0",
                    max: "100",
                    value: "0",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.setReturnLeftValue()
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "range",
                    id: "return-input-right",
                    min: "0",
                    max: "100",
                    value: "100",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.setReturnRightValue()
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(12),
              ]),
              _vm._v(" "),
              _vm._m(13),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "baggage-container" }, [
      _c("div", [
        _c("h3", [_vm._v("¿Cuanto equipaje vas a llevar?")]),
        _vm._v(" "),
        _c("h6", [
          _vm._v("Elige el numero de maletas que vas a llevar en tu viaje"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "baggage-inner-container" }, [
          _c(
            "div",
            {
              staticClass: "extra-container extra-selected",
              attrs: { id: "no-baggage" },
            },
            [
              _c("div", { staticClass: "case-img extra-img" }),
              _vm._v(" "),
              _vm._m(14),
              _vm._v(" "),
              _c("div", { staticClass: "button-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "selected",
                    attrs: { id: "no-baggage-button" },
                    on: {
                      click: function ($event) {
                        return _vm.selectNoBaggage()
                      },
                    },
                  },
                  [_vm._v("SELECCIONADO")]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "extra-container", attrs: { id: "baggage" } },
            [
              _c("div", { staticClass: "baggage-img extra-img" }),
              _vm._v(" "),
              _c("div", { staticClass: "texts" }, [
                _c("h4", [_vm._v("Facturar maletas")]),
                _vm._v(" "),
                _vm._m(15),
                _vm._v(" "),
                _c("div", { staticClass: "baggage-amount-container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "change-value",
                      on: {
                        click: function ($event) {
                          return _vm.removeBaggage()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: "/imgs/minus-solid.svg", alt: "" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("p", { staticClass: "baggage-num" }, [
                      _vm._v(_vm._s(_vm.baggage_num)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "change-value",
                      on: {
                        click: function ($event) {
                          return _vm.addBaggage()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: "/imgs/plus-solid.svg", alt: "" },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("span", { staticClass: "cost" }, [
                    _vm._v(_vm._s(_vm.baggage_base_price) + "€"),
                  ]),
                  _vm._v(" / maleta por vuelo"),
                ]),
                _vm._v(" "),
                _c("h5", [
                  _vm._v(_vm._s(_vm.baggage_num) + " maletas facturadas"),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "seats-container" }, [
      _c("div", [
        _c("h3", [_vm._v("¿Quieres sentarte con tu acompañante?")]),
        _vm._v(" "),
        _c("h6", [_vm._v("Asegúrate de volar junto a tu compañero de viaje")]),
        _vm._v(" "),
        _c("div", { staticClass: "seat-container" }, [
          _c(
            "div",
            {
              staticClass: "extra-container extra-selected",
              attrs: { id: "dont-choose-seats" },
            },
            [
              _c("div", { staticClass: "dont-choose-seats-img extra-img" }),
              _vm._v(" "),
              _vm._m(16),
              _vm._v(" "),
              _c("div", { staticClass: "button-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "selected",
                    attrs: { id: "seat-alone" },
                    on: {
                      click: function ($event) {
                        return _vm.changeSeats("alone")
                      },
                    },
                  },
                  [_vm._v("SELECCIONADO")]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "extra-container", attrs: { id: "choose-seats" } },
            [
              _c("div", { staticClass: "choose-seats-img extra-img" }),
              _vm._v(" "),
              _c("div", { staticClass: "texts" }, [
                _c("h4", [_vm._v("Asientos juntos")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Te garantizamos un asiento con tu acompañante. Nuestro sistema los asignara juntos de forma automática"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("span", { staticClass: "cost" }, [
                    _vm._v(_vm._s(_vm.seats_base_price) + "€"),
                  ]),
                  _vm._v(" / persona y vuelo"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "button-container" }, [
                _c(
                  "button",
                  {
                    attrs: { id: "seat-together" },
                    on: {
                      click: function ($event) {
                        return _vm.changeSeats("together")
                      },
                    },
                  },
                  [_vm._v("SELECCIONAR")]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "extras-button-container" }, [
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.showModal()
            },
          },
        },
        [_vm._v("Avanzamos")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("p", { staticClass: "finalize-modal-content__text" }, [
          _vm._v(
            "\n                            Cuando acabes la reserva y una vez pagada, autorizas a rubikfly.com a comprar los billetes de avión y la reserva del hotel a tu nombre.\n                        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("p", { staticClass: "finalize-modal-content__text" }, [
          _vm._v(
            "\n                            Como los billetes se compran pocas horas después de hacerse el pago, generalmente no se podrán hacer\n                            cambios ni cancelar las reservas, haciendo que sea no reembolsable.\n                        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("p", { staticClass: "finalize-modal-content__text" }, [
          _vm._v(
            "\n                            Los billetes van a nombre de los viajeros que nos indicas en el formulario. Si después decides cambiar alguno\n                            de los pasajeros, no será posible. Asegúrate de introducir todos los datos correctamente.\n                        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("p", { staticClass: "finalize-modal-content__text" }, [
          _vm._v(
            "\n                            En todos los pagos realizados en criptomonedas no se podrán realizar devoluciones, solo podremos realizar vales canjeables por otro viaje.\n                        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _vm._v(
          "\n                        Riesgos Cubiertos\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c(
          "a",
          { attrs: { href: "./imgs/seguro-viaje.pdf", target: "v_blank" } },
          [_vm._v("VER CONDICIONADO PARA GARANTIAS DETALLADAS DE LA PÓLIZA")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _c("b", [
          _vm._v(
            "\n                            Gastos de anulación\n                        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "flex flex__space-between flex__vertical-align underline",
        },
        [
          _c("span", [
            _vm._v(
              "\n                            Gastos de anulación de viaje (por asegurado)\n                        "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "text__right" }, [
            _vm._v(
              "\n                            1.500€\n                        "
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _c("b", [
          _vm._v(
            "\n                            Asistencia médica y personal en viaje\n                        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "flex flex__space-between flex__vertical-align underline",
        },
        [
          _c("span", [
            _vm._v(
              "\n                            Gastos médicos enfermedad o accidente del Asegurado (hasta)\n                        "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "text__right" }, [
            _vm._v(
              "\n                            15.000€\n                        "
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _c("b", [
          _vm._v(
            "\n                            Equipajes y documentos\n                        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "flex flex__space-between flex__vertical-align underline",
        },
        [
          _c("span", [
            _vm._v(
              "\n                            Perdida o daños de equipaje (Obligatorio PIR)\n                        "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "text__right" }, [
            _vm._v(
              "\n                            1.000€\n                        "
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _c("b", [
          _vm._v(
            "\n                            Reembolso de vacaciones no disfrutadas\n                        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "flex flex__space-between flex__vertical-align underline",
        },
        [
          _c("span", [
            _vm._v(
              "\n                            Reembolso de vacaciones no disfrutadas\n                        "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "text__right" }, [
            _vm._v(
              "\n                            SEGÚN GASTOS DE CAPITAL CONTRATADOS\n                        "
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _c("b", [
          _vm._v(
            "\n                            Envio de de medicamentos en el extranjero\n                        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "flex flex__space-between flex__vertical-align underline",
        },
        [
          _c("span", [
            _vm._v(
              "\n                            Envio de de medicamentos en el extranjero\n                        "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "text__right" }, [
            _vm._v(
              "\n                            Incluido\n                        "
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _c("b", [
          _vm._v(
            "\n                            Responsabilidad Civil\n                        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "flex flex__space-between flex__vertical-align underline",
        },
        [
          _c("span", [
            _vm._v(
              "\n                            Responsabilidad Civil (Franquicia 150€)\n                        "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "text__right" }, [
            _vm._v(
              "\n                            60.000€\n                        "
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "texts" }, [
      _c("h4", [_vm._v("Hotel 2* o 3*")]),
      _vm._v(" "),
      _c("p"),
      _vm._v(" "),
      _c("p", { staticClass: "gratis" }, [_vm._v("GRATIS")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slider" }, [
      _c("div", { staticClass: "track" }),
      _vm._v(" "),
      _c("div", { staticClass: "range", attrs: { id: "range" } }),
      _vm._v(" "),
      _c("div", { staticClass: "thumb left", attrs: { id: "thumb-left" } }),
      _vm._v(" "),
      _c("div", { staticClass: "thumb right", attrs: { id: "thumb-right" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "multi-range-hours" }, [
      _c("span", { staticClass: "hour hour6" }, [_vm._v("06.00h")]),
      _vm._v(" "),
      _c("span", { staticClass: "hour hour12" }, [_vm._v("12.00h")]),
      _vm._v(" "),
      _c("span", { staticClass: "hour hour18" }, [_vm._v("18.00h")]),
      _vm._v(" "),
      _c("span", { staticClass: "hour hour24" }, [_vm._v("24.00h")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slider" }, [
      _c("div", { staticClass: "track" }),
      _vm._v(" "),
      _c("div", { staticClass: "range", attrs: { id: "return-range" } }),
      _vm._v(" "),
      _c("div", {
        staticClass: "thumb left",
        attrs: { id: "return-thumb-left" },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "thumb right",
        attrs: { id: "return-thumb-right" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "multi-range-hours" }, [
      _c("span", { staticClass: "hour hour6" }, [_vm._v("06.00h")]),
      _vm._v(" "),
      _c("span", { staticClass: "hour hour12" }, [_vm._v("12.00h")]),
      _vm._v(" "),
      _c("span", { staticClass: "hour hour18" }, [_vm._v("18.00h")]),
      _vm._v(" "),
      _c("span", { staticClass: "hour hour24" }, [_vm._v("24.00h")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "texts" }, [
      _c("h4", [_vm._v("Equipaje de mano")]),
      _vm._v(" "),
      _c("p", [_vm._v("Bolso o mochila (40x20x25cm) por persona")]),
      _vm._v(" "),
      _c("p", { staticClass: "gratis" }, [_vm._v("GRATIS")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Una pieza de equipaje en cabina "),
      _c("br"),
      _vm._v(" (máximo 10kg 55x40x20cm)"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "texts" }, [
      _c("h4", [_vm._v("Asientos sin asignar")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Procuramos que siempre viajes acompañado, pero a veces las aerolineas los separan"
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "gratis" }, [_vm._v("GRATIS")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }