<template>
    <div class="extra-prices-component">
        <h1>Precios extras</h1>
        <table>
            <tr>
                <th>
                    Nombre
                </th>
                <th>
                    Precio
                </th>
                <th>
                    Editar
                </th>
            </tr>
            <tr v-for="(extra, key) in extras" :key="key">
                <td>
                    <input :id="'name'+key" type="text" :value="extra.name" disabled class="disabled-input">
                </td>
                <td>
                    <input :id="'price'+key" type="number" :value="extra.price" disabled class="disabled-input">
                </td>
                <td><span @click="!editing ? enableEdit(key) : saveEdit(key)" :id="'edit'+key" class="edit bold brand-color">Editar</span></td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    data: function(){
        return{
            extras: [],
            editing:false,
            adding: false,
        }
    },
    beforeMount(){
        axios.get('/admin/extras')
        .then(res => {
            res.data.forEach((element, index) => {
                this.extras.push({
                    name: element.name,
                    price: element.price,
                    id: element.id,
                    key: index,
                })
            });
        })
    },
    methods:{
        enableEdit(key){
            if(!this.editing && !this.adding){
                let name = document.getElementById("name"+key);
                let price = document.getElementById("price"+key);
                let edit = document.getElementById("edit"+key);
                name.disabled=false;
                price.disabled=false;
                edit.innerHTML="Aplicar";
                name.classList.remove("disabled-input")
                price.classList.remove("disabled-input")
                this.editing=true;
            }
        },
        saveEdit(key){
            if(this.editing && !this.adding){
                let name = document.getElementById("name"+key);
                let price = document.getElementById("price"+key);
                let edit = document.getElementById("edit"+key);
                this.extras[key].name = name.value;
                this.extras[key].price = price.value;
                let id = this.extras.filter(obj => {
                    // console.log(obj.key, key)
                    return obj.key === key
                })[0].id;
                // console.log(id);
                name.disabled=true;
                price.disabled=true;
                edit.innerHTML="Editar";
                name.classList.add("disabled-input")
                price.classList.add("disabled-input")
                this.editing=false;

                axios.put(`/admin/extra/${id}`, {
                    name: name.value,
                    price: price.value,
                    id: id
                })
            }
        },
    }
}
</script>
