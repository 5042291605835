var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calendars-view" }, [
    _c("h1", [_vm._v("Calendarios")]),
    _vm._v(" "),
    _c(
      "select",
      {
        attrs: { name: "airport", id: "airport-calendar" },
        on: {
          change: function ($event) {
            return _vm.changeAirport($event)
          },
        },
      },
      _vm._l(_vm.airports, function (airport, key) {
        return _c(
          "option",
          { key: "airport" + key, domProps: { value: airport.name } },
          [_vm._v(_vm._s(airport.name + " (" + airport.price + "€)"))]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("h2", [_vm._v("Alta demanda(+50)")]),
        _vm._v(" "),
        _c("vc-calendar", {
          attrs: {
            "is-expanded": "",
            attributes: _vm.attrsHighRequest,
            columns: _vm.$screens({ default: 1, sm: 2 }),
            rows: _vm.$screens({ default: 2, sm: 1 }),
            color: "red",
            "min-date": _vm.min_date,
            "max-date": _vm.max_date,
            step: 1,
          },
          on: { dayclick: _vm.togglePriceHR },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("h2", [_vm._v("Ultimas plazas(+100)")]),
        _vm._v(" "),
        _c("vc-calendar", {
          attrs: {
            "is-expanded": "",
            attributes: _vm.attrsLastSeats,
            columns: _vm.$screens({ default: 1, sm: 2 }),
            rows: _vm.$screens({ default: 2, sm: 1 }),
            color: "red",
            "min-date": _vm.min_date,
            "max-date": _vm.max_date,
            step: 1,
          },
          on: { dayclick: _vm.togglePriceLS },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("h2", [_vm._v("Plazas agotadas")]),
        _vm._v(" "),
        _c("vc-calendar", {
          attrs: {
            "is-expanded": "",
            attributes: _vm.attrsLocked,
            columns: _vm.$screens({ default: 1, sm: 2 }),
            rows: _vm.$screens({ default: 2, sm: 1 }),
            color: "red",
            "min-date": _vm.min_date,
            "max-date": _vm.max_date,
            step: 1,
          },
          on: { dayclick: _vm.toggleLocked },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }