<template>
    <div class="opinions-component-container">
        <div class="titles-container">
            <h3>TODAS LAS OPINIONES SON VERIFICADAS POR NUESTRO EQUIPO, DEJA TU OPINION DE TU VIAJE SORPRESA...</h3>
            <h2>¿Que opinan nuestros Rubikers?</h2>
        </div>
        <div class="">
            <div class="slider-container">
                <div class="slider" id="slider1">
                    <opinion-component class="slider__element" v-for="(op, index) in opinions" :key="index"
                        :name="op.name" :img="op.img" :opinion="op.comment" :date="op.date" :stars="op.stars"
                        :id="index" :index="op.index"></opinion-component>
                </div>
                <br>
                <div class="slider" id="slider2">
                    <opinion-component class="slider__element" v-for="(op, index) in opinions2" :key="index"
                        :name="op.name" :img="op.img" :opinion="op.comment" :date="op.date" :stars="op.stars"
                        :id="index" :index="op.index"></opinion-component>
                </div>
            </div>
            <div class="slider-buttons">
                <button id="button-left" class="button">&lt;</button>
                <button id="button-right" class="button">&gt;</button>
            </div>
        </div>
    </div>
</template>


<script>
import Opinion from './Opinion.vue'
export default {
    components: { Opinion },
    data: function () {
        return {
            opinions: [
                {
                    name: "Francisco Aperador",
                    img: "../imgs/avatar1.webp",
                    comment: "Estas navidades nos han regalado el pack sorpresa regalo, ya estuvimos con un pack España hace un par de meses y ahora repetimos.",
                    date: "Enero 2024",
                    stars: 5,
                    index: 0
                }, {
                    name: "Isabel Camps",
                    img: "../imgs/avatar2.webp",
                    comment: "Una experiencia única! Muy buena organización y eficacia, nos tocó Londres como destino. No cambio para nada esta vivencia y la agencia muy profesional!! Sin duda voy a repetir.",
                    date: "Abril 2023",
                    stars: 5,
                    index: 0
                }, {
                    name: "Eva Maria Carmona",
                    img: "../imgs/avatar3.webp",
                    comment: "Una experiencia increible con RubikFly, volveremos a repetir!",
                    date: "Mayo 2023",
                    stars: 5,
                    index: 0
                }, {
                    name: "Soraya Rei",
                    img: "../imgs/avatar4.webp",
                    comment: "Bien organizado, todo sencillo y sin problemas. Nos tocó Luxemburgo.",
                    date: "Abril 2023",
                    stars: 5,
                    index: 0
                }, {
                    name: "Roberto Carpio",
                    img: "../imgs/avatar5.webp",
                    comment: "Empresa superbién organizada, con un equipo muy atento y amable. Hicimos con ellos un viaje ESPAÑA sorpresa y nos tocó Tenerife, el viaje, el apartamento y todo fue genial.",
                    date: "Marzo 2024",
                    stars: 5,
                    index: 0
                }, {
                    name: "Francisca Gutierrez",
                    img: "../imgs/avatar6.webp",
                    comment: "Elegimos hacer un viaje sorpresa en Junio y nos tocó Lyon. A los dos nos pareció una genial idea.",
                    date: "Julio 2023",
                    stars: 5,
                    index: 0
                },
            ],
            opinions2: [
                {
                    name: "Ana Morado",
                    img: "../imgs/avatar7.png",
                    comment: "El viaje sorpresa con alojamiento fue emocionante y el alojamiento superó mis expectativas.",
                    date: "Marzo 2024",
                    stars: 5,
                    index: 1
                }, {
                    name: "Jaime Mendez",
                    img: "../imgs/avatar8.png",
                    comment: "Por solo 150 euros, reservé un viaje sorpresa con alojamiento que superó todas mis expectativas. Cada momento, desde la reserva hasta el regreso a casa, fue una aventura emocionante.s",
                    date: "Marzo 2024",
                    stars: 5,
                    index: 1
                }, {
                    name: "Sito kalyani",
                    img: "../imgs/avatar9.png",
                    comment: "Una aventura increíble. Reservé un viaje sorpresa y fue muy divertido. Definitivamente vale la pena probarlo.",
                    date: "Marzo 2024",
                    stars: 5,
                    index: 1
                }, {
                    name: "Sandra",
                    img: "../imgs/avatar10.png",
                    comment: "Reservé un viaje sorpresa por 150 euros y fue una experiencia inolvidable. Lo recomiendo a todos los que buscan algo diferente.",
                    date: "Abril 2023",
                    stars: 5,
                    index: 1
                }, {
                    name: "Marc Aranda",
                    img: "../imgs/avatar11.png",
                    comment: "Quería sorprender a mi pareja por San Valentín y la experiencia fue increíble. Nos fuimos los dos a Roma con el hotel incluido, hasta el último momento no sabíamos el sitio al que íbamos. Recomendado 100% para quien quiera vivir experiencias nuevas.",
                    date: "Febrero 2024",
                    stars: 5,
                    index: 1
                }, {
                    name: "José Veral",
                    img: "../imgs/avatar12.png",
                    comment: "Fui a París en noviembre del año pasado gracias a ellos y fue maravilloso, gracias por hacer que este viaje sea tan mágico. Volveré a repetir la experiencia.",
                    date: "Noviembre 2023",
                    stars: 5,
                    index: 1
                },
            ],
            iterations: 4,
            num: 2,
            width: 1200,
            formerWidth: 0,
        }
    },
    beforeMount() {
        this.iterations = Math.ceil(this.opinions.length / this.num);
    },
    mounted() {
        this.width = document.body.clientWidth;

        //this.onResize();
        //window.addEventListener("resize", this.onResize);
        this.slide();
    },
    methods: {
        onResize() {
            this.width = document.body.clientWidth;
            if (this.width > 1250) {
                if (this.formerWidth <= 1250) {
                    this.changeActive()
                }
                this.num = 4;
                this.iterations = Math.ceil(this.opinions.length / this.num);
            } else if (this.width > 800) {
                if (this.formerWidth <= 800) {
                    this.changeActive();
                }
                this.num = 2;
                this.iterations = Math.ceil(this.opinions.length / this.num);
            } else {
                this.num = 1;
                this.iterations = Math.ceil(this.opinions.length / this.num);
            }
            this.formerWidth = this.width;
        },
        changeActive() {
            let elements = document.getElementsByClassName('carousel-item');

            for (let i = 0; i < elements.length; i++) {
                if (i == 0) {
                    elements[i].classList.add('active');
                } else {
                    elements[i].classList.remove('active');
                }
            }
        },
        slide() {
            const slider1 = document.getElementById('slider1');
            const slider2 = document.getElementById('slider2');
            const buttonLeft = document.getElementById('button-left');
            const buttonRight = document.getElementById('button-right');
            const sliderElements1 = document.querySelectorAll('#slider1 .slider__element');
            const sliderElements2 = document.querySelectorAll('#slider2 .slider__element');

            let slideCounter1 = 0;
            let slideCounter2 = 0;
            let isInTransition1 = false;
            let isInTransition2 = false;

            const DIRECTION = {
                RIGHT: 'RIGHT',
                LEFT: 'LEFT'
            };

            const getTransformValue = (slider) =>
                Number(window.getComputedStyle(slider).getPropertyValue('transform').split(',')[4].trim());

            const reorderSlide = (slider, sliderElements, slideCounter, isInTransition) => {
                try {
                    const transformValue = getTransformValue(slider);
                    slider.style.transition = 'none';
                    if (slideCounter === sliderElements.length - 4) {
                        slider.appendChild(slider.firstElementChild);
                        slider.style.transform = `translateX(${transformValue + sliderElements[slideCounter].scrollWidth + 20}px)`;
                        slideCounter--;
                    } else if (slideCounter === 0) {
                        slider.prepend(slider.lastElementChild);
                        slider.style.transform = `translateX(${transformValue - sliderElements[slideCounter].scrollWidth - 20}px)`;
                        slideCounter++;
                    }

                    isInTransition = false;
                    return { slideCounter, isInTransition }; // return the updated slideCounter
                } catch (error) {
                    // console.error(error);
                }
            };

            const moveSlide = (direction, slider, sliderElements, slideCounter, isInTransition) => {
                try {
                    if (isInTransition) return;
                    const transformValue = getTransformValue(slider);
                    slider.style.transition = 'transform 1s';
                    isInTransition = true;
                    if (direction === DIRECTION.LEFT) {
                        slider.style.transform = `translateX(${transformValue + sliderElements[slideCounter].scrollWidth + 20}px)`;
                        slideCounter = slideCounter === 0 ? sliderElements.length - 1 : slideCounter - 1;
                    } else if (direction === DIRECTION.RIGHT) {
                        slider.style.transform = `translateX(${transformValue - sliderElements[slideCounter].scrollWidth - 20}px)`;
                        slideCounter = slideCounter === sliderElements.length - 1 ? 0 : slideCounter + 1;
                    }
                    return { slideCounter, isInTransition }; // return the updated slideCounter
                } catch (error) {
                    // console.error(error);
                }
            };
            let firstClickDone = false;

            buttonRight.addEventListener('click', () => {
                let result1 = moveSlide(DIRECTION.RIGHT, slider1, sliderElements1, slideCounter1, isInTransition1);
                slideCounter1 = result1.slideCounter;
                isInTransition1 = result1.isInTransition;

                let result2 = moveSlide(DIRECTION.RIGHT, slider2, sliderElements2, slideCounter2, isInTransition2);
                slideCounter2 = result2.slideCounter;
                isInTransition2 = result2.isInTransition;
                firstClickDone = true; // Set firstClickDone to true after the first right click

            });
            buttonLeft.addEventListener('click', () => {
                if(!firstClickDone) return; // If firstClickDone is false, return (do nothing
                let result1 = moveSlide(DIRECTION.LEFT, slider1, sliderElements1, slideCounter1, isInTransition1);
                slideCounter1 = result1.slideCounter;
                isInTransition1 = result1.isInTransition;

                let result2 = moveSlide(DIRECTION.LEFT, slider2, sliderElements2, slideCounter2, isInTransition2);
                slideCounter2 = result2.slideCounter;
                isInTransition2 = result2.isInTransition;
            });

            slider1.addEventListener('transitionend', () => {
                let result1 = reorderSlide(slider1, sliderElements1, slideCounter1, isInTransition1);
                slideCounter1 = result1.slideCounter;
                isInTransition1 = result1.isInTransition;
            });
            slider2.addEventListener('transitionend', () => {
                let result2 = reorderSlide(slider2, sliderElements2, slideCounter2, isInTransition2);
                slideCounter2 = result2.slideCounter;
                isInTransition2 = result2.isInTransition;
            });
        }
    },
    created() {

    }
}
</script>
