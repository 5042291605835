var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "blog-container" },
    _vm._l(_vm.processedBlogs, function (blog) {
      return _c(
        "div",
        { key: blog.id, staticClass: "blog-card" },
        [
          _c(
            "router-link",
            { staticClass: "blog-link", attrs: { to: "/blogs/" + blog.slug } },
            [
              _c("img", {
                staticClass: "blog-image",
                attrs: { src: blog.image_url, alt: "Blog Image" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "blog-content" }, [
                _c("h2", [_vm._v(_vm._s(blog.title))]),
                _vm._v(" "),
                _c("p", { domProps: { innerHTML: _vm._s(blog.shortContent) } }),
              ]),
            ]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }