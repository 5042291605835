<template>
    <div class="destinations-view-component">
        <div class="breadcrumb-container">
            <ul class="breadcrumb">
                <li><router-link to="/">Inicio</router-link></li>
                <li><router-link to="/calendar">Fechas</router-link></li>
                <li><router-link to="/destinos">Destinos</router-link></li>
                <li>Extras</li>
            </ul>
        </div>
        <div class="destinations-modal" id="destinations-modal" @click="hideModal($event)">
            <div class="destinations-modal-content">
                <div class="relative destinations-modal-inner">
                    <p class="destinations-modal-content__close" id="destinations-modal-content__close" @click="hideModal($event)">&times;</p>
                    <p class="destinations-modal-content__text">Solo puedes descartar un máximo de 6 destinos</p>
                </div>
            </div>
        </div>
        <div class="titles">
            <h2>¿Quieres descartar destinos?</h2>
            <h4>Elige los destinos que descartar, el primero es gratis. Si deseas descartar mas se añadirán {{price}}€ por destino y persona.</h4>
        </div>
        <div class="destinations-outter-container">
            <div class="destinations-container">
                <div v-for="(destination, key) in destinations" :key="key">
                    <destination-view-component :img="destination.img" :name="destination.name" :id="key" :discarded="discarted" @clicked="onClickChild"></destination-view-component>
                </div>
            </div>
        </div>
        <div class="destinations-button-container">
            <button @click="goToNextStep()">Avanzamos</button>
        </div>
    </div>
</template>
<script>
export default {
    data: function(){
        return {
            days: [],
            destinations:[],
            price: 0,
            destinations_free: 1,
            discarted:[],
            total_discarted_price:0,
            people: 0,
            max_discarded: 6,
        }
    },
    beforeMount(){
        let calendarInfo = JSON.parse(localStorage.getItem("calendar-data"));
        let LSObject = JSON.parse(localStorage.getItem('main-data'));
        const now = new Date();
        if(!LSObject || !calendarInfo){
            this.$router.push({name:'Home'});
        }else{
            if(LSObject.expires < now.getTime() || calendarInfo.expires < now.getTime()){
                localStorage.removeItem('main-data');
                localStorage.removeItem('calendar-data');
                this.$router.push({name:'Home'});
            }else{
                this.days = calendarInfo.info;
                this.people = LSObject.adults + LSObject.kids + LSObject.babies;
            }
        }


        if(LSObject && calendarInfo){
            if(LSObject.destination == 'España'){
                this.destinations = [
                    {
                        name: 'ALICANTE',
                        img: '../imgs/spain/ALICANTE.webp'
                    },
                    {
                        name: 'ALMERIA',
                        img: '../imgs/spain/ALMERIA.webp'
                    },
                    {
                        name: 'BILBAO',
                        img: '../imgs/spain/BILBAO.webp'
                    },
                    {
                        name: 'GRAN CANARIA',
                        img: '../imgs/spain/GRAN-CANARIA.webp'
                    },
                    {
                        name: 'IBIZA',
                        img: '../imgs/spain/IBIZA.webp'
                    },
                    {
                        name: 'LA CORUÑA',
                        img: '../imgs/spain/LA-CORUNA.webp'
                    },
                    {
                        name: 'MADRID',
                        img: '../imgs/spain/MADRID.webp'
                    },
                    {
                        name: 'MALAGA',
                        img: '../imgs/spain/MALAGA.webp'
                    },
                    {
                        name: 'MALLORCA',
                        img: '../imgs/spain/MALLORCA.webp'
                    },
                    {
                        name: 'MENORCA',
                        img: '../imgs/spain/MENORCA.webp'
                    },
                    {
                        name: 'OVIEDO',
                        img: '../imgs/spain/OVIEDO.webp'
                    },
                    {
                        name: 'PAMPLONA',
                        img: '../imgs/spain/PAMPLONA.webp'
                    },
                    {
                        name: 'SAN SEBASTIAN',
                        img: '../imgs/spain/SAN-SEBASTIAN.webp'
                    },
                    {
                        name: 'SANTANDER',
                        img: '../imgs/spain/SANTANDER.webp'
                    },
                    {
                        name: 'SANTIAGO',
                        img: '../imgs/spain/SANTIAGO.webp'
                    },
                    {
                        name: 'SEVILLA',
                        img: '../imgs/spain/SEVILLA.webp'
                    },
                    {
                        name: 'TENERIFE',
                        img: '../imgs/spain/TENERIFE.webp'
                    },
                    {
                        name: 'VALENCIA',
                        img: '../imgs/spain/VALENCIA-1.webp'
                    }
                ]
            }else{
                this.destinations = [
                    {
                        name: 'ALGARVE',
                        img: '../imgs/europe/ALGARVE.webp'
                    },
                    {
                        name: 'AMSTERDAM',
                        img: '../imgs/europe/AMSTERDAM.webp'
                    },
                    {
                        name: 'ATENAS',
                        img: '../imgs/europe/ATENAS.webp'
                    },
                    {
                        name: 'BASILEA',
                        img: '../imgs/europe/BASILEA.webp'
                    },
                    {
                        name: 'BERLIN',
                        img: '../imgs/europe/BERLIN.webp'
                    },
                    {
                        name: 'BIRMINGHAM',
                        img: '../imgs/europe/BIRMINGHAM.webp'
                    },
                    {
                        name: 'BOLONIA',
                        img: '../imgs/europe/BOLONIA.webp'
                    },
                    {
                        name: 'BRUSELAS',
                        img: '../imgs/europe/BRUSELAS.webp'
                    },
                    {
                        name: 'BUDAPEST',
                        img: '../imgs/europe/BUDAPEST.webp'
                    },
                    {
                        name: 'BURDEOS',
                        img: '../imgs/europe/BURDEOS-1.webp'
                    },
                    {
                        name: 'COLONIA',
                        img: '../imgs/europe/COLONIA.webp'
                    },
                    {
                        name: 'CRACOVIA',
                        img: '../imgs/europe/CRACOVIA.webp'
                    },
                    {
                        name: 'DUBROVNIK',
                        img: '../imgs/europe/DUBROVNIK.webp'
                    },
                    {
                        name: 'EDIMBURGO',
                        img: '../imgs/europe/EDIMBURGO.webp'
                    },
                    {
                        name: 'FLORENCIA',
                        img: '../imgs/europe/FLORENCIA.webp'
                    },
                    {
                        name: 'FRANKFURT',
                        img: '../imgs/europe/FRANCKFURT.webp'
                    },
                    {
                        name: 'GINEBRA',
                        img: '../imgs/europe/GINEBRA.webp'
                    },
                    {
                        name: 'GLASGOW',
                        img: '../imgs/europe/GLASGOW.webp'
                    },
                    {
                        name: 'HAMBURGO',
                        img: '../imgs/europe/HAMBURGO.webp'
                    },
                    {
                        name: 'LISBOA',
                        img: '../imgs/europe/LISBOA.webp'
                    },
                    {
                        name: 'LONDRES',
                        img: '../imgs/europe/LONDRES.webp'
                    },
                    {
                        name: 'LYON',
                        img: '../imgs/europe/LYON.webp'
                    },
                    {
                        name: 'MARSELLA',
                        img: '../imgs/europe/MARSELLA.webp'
                    },
                    {
                        name: 'MILAN',
                        img: '../imgs/europe/MILAN.webp'
                    },
                    {
                        name: 'NANTES',
                        img: '../imgs/europe/NANTES.webp'
                    },
                    {
                        name: 'NAPOLES',
                        img: '../imgs/europe/NAPOLES.webp'
                    },
                    {
                        name: 'NIZA',
                        img: '../imgs/europe/NIZA.webp'
                    },
                    {
                        name: 'OPORTO',
                        img: '../imgs/europe/OPORTO.webp'
                    },
                    {
                        name: 'PARÍS',
                        img: '../imgs/europe/PARIS.webp'
                    },
                    {
                        name: 'PRAGA',
                        img: '../imgs/europe/PRAGA.webp'
                    },
                    {
                        name: 'ROMA',
                        img: '../imgs/europe/ROMA.webp'
                    },
                    {
                        name: 'SOFIA',
                        img: '../imgs/europe/SOFIA.webp'
                    },
                    {
                        name: 'TURIN',
                        img: '../imgs/europe/TURIN.webp'
                    },
                    {
                        name: 'VENECIA',
                        img: '../imgs/europe/VENECIA.webp'
                    },
                    {
                        name: 'VIENA',
                        img: '../imgs/europe/VIENA.webp'
                    },
                    {
                        name: 'ZURICH',
                        img: '../imgs/europe/ZURICH.webp'
                    }
                ]
            }
        }
        this.emitData();
    },
    computed:{
        total_price(){
            let calendarInfo = JSON.parse(localStorage.getItem('calendar-data'));
            let acomulated_price = calendarInfo.total_price;
            return acomulated_price + this.total_discarted_price;
        }
    },
    methods:{
        async emitData(){
            await this.getExtraPrices();
            this.$emit('hasPrice', this.total_price);
        },
        async getExtraPrices(){
            let res = await axios.get('/extra-price/Destino Descartado')
            this.price = res.data.price;
        },
        onClickChild(destination){

            if(destination.isDiscarted){
                if(destination.canDiscard){
                    this.discarted.push(destination.name);
                }
            }else{
                if(this.discarted.indexOf(destination.name) >= 0){
                    this.discarted.splice(this.discarted.indexOf(destination.name), 1);
                }
            }
            if(this.discarted.length >= this.destinations_free){
                this.total_discarted_price = (this.discarted.length - this.destinations_free) * this.price * this.people;
            }

            if(!destination.canDiscard){
                this.showModal();
            }
            this.$emit('hasPrice', this.total_price);
        },
        showModal(){
            let modal = document.getElementById("destinations-modal");
            modal.style.display = "block";
        },
        hideModal(event){
            let modal = document.getElementById("destinations-modal");
            let close = document.getElementById("destinations-modal-content__close");

            if(event.target == modal || event.target == close){
                modal.style.display = "none";
            }

        },
        goToNextStep(){
            const now = new Date();
            let destinationsInfo = {
                discarted: this.discarted,
                extra_price: this.total_discarted_price,
                total_price: this.total_price,
                expires: now.getTime() + (1000 * 60 * 60) //1 hour
            }

            localStorage.setItem("destinations-data", JSON.stringify(destinationsInfo));

            this.$router.push({name: 'extras'});
        }
    }
}
</script>
