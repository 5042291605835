<template>
    <div class="calendars-view">
        <h1>Calendarios</h1>

        <select name="airport" id="airport-calendar" @change="changeAirport($event)">
            <option v-for="(airport, key) in airports" :key="'airport'+key" :value="airport.name">{{airport.name + " (" + airport.price + "€)"}}</option>
        </select>
        <div>
            <h2>Alta demanda(+50)</h2>
            <vc-calendar is-expanded :attributes="attrsHighRequest" :columns="$screens({ default: 1, sm: 2 })" :rows="$screens({ default: 2, sm: 1 })" color="red" @dayclick="togglePriceHR" :min-date="min_date" :max-date="max_date" :step="1"></vc-calendar>
        </div>
        <div>
            <h2>Ultimas plazas(+100)</h2>
            <vc-calendar is-expanded :attributes="attrsLastSeats" :columns="$screens({ default: 1, sm: 2 })" :rows="$screens({ default: 2, sm: 1 })" color="red" @dayclick="togglePriceLS" :min-date="min_date" :max-date="max_date" :step="1"></vc-calendar>
        </div>
        <div>
            <h2>Plazas agotadas</h2>
            <vc-calendar is-expanded :attributes="attrsLocked" :columns="$screens({ default: 1, sm: 2 })" :rows="$screens({ default: 2, sm: 1 })" color="red" @dayclick="toggleLocked" :min-date="min_date" :max-date="max_date" :step="1"></vc-calendar>
        </div>
    </div>
</template>
<script>
export default {
    data: function(){
        return{
            airports:[],
            currentAirport: localStorage.getItem('currentAirport') || "Barcelona",
            attrsHighRequest:[],
            attrsLastSeats:[],
            attrsLocked:[],
            min_date: new Date(),
            max_date: new Date(),
        }
    },
    computed:{
        highRequest(){
            return this.attrsHighRequest;
        },
    },
    beforeMount(){
        this.min_date.setDate(this.min_date.getDate() + 7);
        this.max_date.setDate(this.max_date.getDate() + 365);
        axios.get('/admin/airports')
        .then(res => {
            res.data.forEach((element) => {
                this.airports.push({
                    name: element.name,
                    price: element.price,
                    id: element.id,
                })
            });
        })

        this.attrsHighRequest=[{
            key: 'plusHR',
            highlight: {
                style: {
                    background: '#FFBB00'
                },
                contentStyle: {
                    color: 'black'
                }
            },
            dates: []
        }]

        this.attrsLastSeats=[{
            key: 'plusLS',
            highlight: {
                style: {
                    background: '#FF0000'
                },
                contentStyle: {
                    color: 'black'
                }
            },
            dates: []
        }]

        this.attrsLocked=[{
            key: 'locked',
            highlight: {
                style: {
                    background: '#999'
                },
                contentStyle: {
                    color: 'black'
                }
            },
            dates: []
        }]



    },
    mounted(){
        this.getHighRequest();
        this.getLastSeats();
        this.getLocked();
    },
    methods: {
        changeAirport(event){
            this.currentAirport = event.target.value;
            this.getHighRequest();
            this.getLastSeats();
            this.getLocked();
        },
        getHighRequest(){
            this.attrsHighRequest[0].dates=[];
            axios.get('/admin/high-request/'+this.currentAirport)
            .then(res => {
                res.data.forEach((element) => {
                    this.attrsHighRequest[0].dates.push(new Date(element.day));
                });
            })

        },
        getLastSeats(){
            this.attrsLastSeats[0].dates=[];
            axios.get('/admin/last-seats/'+this.currentAirport)
            .then(res => {
                res.data.forEach((element) => {
                    this.attrsLastSeats[0].dates.push(new Date(element.day));
                });
            })
        },
        getLocked(){
            this.attrsLocked[0].dates=[];
            axios.get('/admin/locked/'+this.currentAirport)
            .then(res => {
                res.data.forEach((element) => {
                    this.attrsLocked[0].dates.push(new Date(element.day));
                });
            })
        },
        togglePriceHR(day){
            let isSelected = this.attrsHighRequest[0].dates.filter(obj => {
                obj = new Date(obj);
                let o = obj.getFullYear()+"-"+obj.getMonth()+"-"+obj.getDate()
                let d = day.date.getFullYear()+"-"+day.date.getMonth()+"-"+day.date.getDate();
                return o === d;
            })[0];

            this.currentAirport = document.getElementById("airport-calendar").value;

            if(!isSelected){
                this.attrsHighRequest[0].dates.push(day.date);
                axios.post("/admin/high-request", {
                    day: day.date.getFullYear()+"-"+(day.date.getMonth()+1)+"-"+day.date.getDate(),
                    airport: this.currentAirport,
                })
            }else{
                day.date.setHours(2);
                this.attrsHighRequest[0].dates.splice(this.attrsHighRequest[0].dates.map(Number).indexOf(+day.date), 1);

                axios.post("/admin/high-request/delete", {
                    airport: this.currentAirport,
                    day: day.date.getFullYear()+"-"+(day.date.getMonth()+1)+"-"+day.date.getDate(),
                });
            }
        },
        togglePriceLS(day){
            let isSelected = this.attrsLastSeats[0].dates.filter(obj => {
                obj = new Date(obj);
                let o = obj.getFullYear()+"-"+obj.getMonth()+"-"+obj.getDate()
                let d = day.date.getFullYear()+"-"+day.date.getMonth()+"-"+day.date.getDate();
                return o === d;
            })[0];

            this.currentAirport = document.getElementById("airport-calendar").value;

            if(!isSelected){
                this.attrsLastSeats[0].dates.push(day.date);
                axios.post("/admin/last-seats", {
                    day: day.date.getFullYear()+"-"+(day.date.getMonth()+1)+"-"+day.date.getDate(),
                    airport: this.currentAirport,
                })
            }else{
                day.date.setHours(2);
                this.attrsLastSeats[0].dates.splice(this.attrsLastSeats[0].dates.map(Number).indexOf(+day.date), 1);
                //console.log(day.date);
                //console.log(this.attrsLocked[0].dates);
                axios.post("/admin/last-seats/delete", {
                    airport: this.currentAirport,
                    day: day.date.getFullYear()+"-"+(day.date.getMonth()+1)+"-"+day.date.getDate(),
                });
            }
        },
        toggleLocked(day){
            let isSelected = this.attrsLocked[0].dates.filter(obj => {
                obj = new Date(obj);
                let o = obj.getFullYear()+"-"+obj.getMonth()+"-"+obj.getDate()
                let d = day.date.getFullYear()+"-"+day.date.getMonth()+"-"+day.date.getDate();
                return o === d;
            })[0];

            this.currentAirport = document.getElementById("airport-calendar").value;

            if(!isSelected){
                this.attrsLocked[0].dates.push(day.date);
                axios.post("/admin/locked", {
                    day: day.date.getFullYear()+"-"+(day.date.getMonth()+1)+"-"+day.date.getDate(),
                    airport: this.currentAirport,
                })
            }else{
                day.date.setHours(2);
                this.attrsLocked[0].dates.splice(this.attrsLocked[0].dates.map(Number).indexOf(+day.date), 1);
                //console.log(day.date);
                //console.log(this.attrsLocked[0].dates);
                axios.post("/admin/locked/delete", {
                    airport: this.currentAirport,
                    day: day.date.getFullYear()+"-"+(day.date.getMonth()+1)+"-"+day.date.getDate(),
                });
            }
        }
    }
}
</script>
