<template>
    <div class="destination-view-component">
        <div class="destination-container flex" :id="'destination'+id" @click="discard()">
            <h2>{{name}}</h2>
        </div>
        <span :id="'discarded'+id" class="hidden discarded-destination-text"></span>
    </div>
</template>
<script>
export default {
    props:[
        'img',
        'name',
        'id',
        'discarded'
    ],
    data: function(){
        return {
            isDiscarted: false,
            canDiscard: true,
            max_discarded: 6,
        }
    },
    mounted(){
        let element = document.getElementById(`destination${this.id}`);
        element.style.backgroundImage = `url(${this.img})`
    },
    methods:{
        discard(){
            let element = document.getElementById(`destination${this.id}`);
            let discarded_text = document.getElementById(`discarded${this.id}`);

            if(this.discarded.length < this.max_discarded){
                this.canDiscard = true;
            }else{
                if(this.isDiscarted){
                    this.canDiscard = true;
                }else{
                    this.canDiscard = false;
                }

            }

            if(!this.isDiscarted && this.canDiscard){
                element.style.opacity = 0.1;
                this.isDiscarted = true;
                discarded_text.classList.remove("hidden");
            }else{
                element.style.opacity = 1;
                this.isDiscarted = false;
                discarded_text.classList.add("hidden");
            }

            this.$emit('clicked', {
                isDiscarted: this.isDiscarted,
                name: this.name,
                id: this.id,
                canDiscard: this.canDiscard
            })
        }
    }
}
</script>
