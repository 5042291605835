var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-view" },
    [
      _c("nav", { staticClass: "admin-left-menu" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/admin" } }, [
                _vm._v("INICIO"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c(
              "p",
              {
                staticClass: "margin0 pointer",
                on: {
                  click: function ($event) {
                    return _vm.openBookingEngine()
                  },
                },
              },
              [_vm._v("MOTOR DE RESERVAS")]
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "hidden sublist",
                attrs: { id: "booking-engine-sublist" },
              },
              [
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/admin/reservas" } }, [
                      _vm._v("Reservas"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", [_vm._v("Clientes")]),
                _vm._v(" "),
                _c("li", [_vm._v("Facturas")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "p",
              {
                staticClass: "margin0 pointer",
                on: {
                  click: function ($event) {
                    return _vm.openContentManagement()
                  },
                },
              },
              [_vm._v("GESTION DE CONTENIDOS")]
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "hidden sublist",
                attrs: { id: "content-management-sublist" },
              },
              [
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/admin/calendarios" } }, [
                      _vm._v("Calendarios"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/admin/aeropuertos" } }, [
                      _vm._v("Aeropuertos"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/admin/precios-extras" } },
                      [_vm._v("Precios Extras")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("li", [_vm._v("IDIOMAS")]),
        ]),
      ]),
      _vm._v(" "),
      _c("router-view", { staticClass: "admin-router-view" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }