var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "about-us-view" }, [
    _c(
      "div",
      { staticClass: "hide-on-mobile" },
      [_c("header-component", _vm._g({}, _vm.$listeners))],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "main" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "fly-img section" }, [
        _c("img", { attrs: { src: _vm.img, alt: "Viaje sorpresa España" } }),
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section section-top" }, [
      _c("p", { staticClass: "margin0" }, [
        _c("b", [_vm._v("Surprise travel")]),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("\n                emociónate, suit up\n            ")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                ¿Que haces cuando quieres viajar y no sabes donde? Te pones a trabajar.\n            "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                Somos una agencia de viajes sorpresa fundada por tres jóvenes emprendedores apasionados por explorar\n                el mundo y compartir experiencias únicas con otros viajeros. Nosotros, nos lanzamos a esta aventura\n                con la firme convicción de que viajar no solo debería ser una actividad, sino una experiencia que\n                despierte emociones, cree recuerdos inolvidables y fomente la exploración de lo desconocido.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section" }, [
      _c("h2", [_vm._v("encantados de conocerte")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                Nos encanta desafiar lo convencional y pensar fuera de la caja. Nos dimos cuenta de que muchos\n                viajes se convierten en rutinas predecibles, donde cada detalle está planeado de antemano,\n                eliminando la emoción y la sorpresa. Así nació RubikFly, con la idea de reinventar la forma en que\n                las personas experimentan el mundo. Nos esforzamos por ofrecer una alternativa emocionante y\n                misteriosa para aquellos que buscan escapar de lo ordinario y sumergirse en lo extraordinario.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section" }, [
      _c("h2", [_vm._v("¿Que nos diferencia?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                En RubikFly, creemos en la magia de lo inesperado. Nuestro enfoque único consiste en diseñar viajes\n                personalizados, pero con un giro emocionante: ¡el destino final es una sorpresa! Nos encargamos de\n                todo el proceso, desde la planificación hasta la ejecución, permitiéndote relajarte y disfrutar del\n                viaje sin preocupaciones. Cada aventura con RubikFly es como resolver un rompecabezas: emocionante,\n                desafiante y lleno de gratas sorpresas.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section last-section" }, [
      _c("h2", [
        _vm._v("\n                we're changing the way\n            "),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                Nuestra misión es inspirar a las personas a descubrir el mundo de una manera completamente nueva.\n                Queremos fomentar la curiosidad, avivar la pasión por la aventura y crear recuerdos que duren toda\n                la vida. En RubikFly, creemos en la importancia de salir de tu zona de confort, explorar lo\n                desconocido y abrir tu mente a nuevas experiencias. Estamos aquí para convertir tus sueños de viaje\n                en realidades inolvidables. ¡Únete a nosotros en esta emocionante aventura y deja que RubikFly te\n                lleve a lugares que nunca imaginaste!\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }