<template>
    <div class="about-us-view">
      <div class="hide-on-mobile">
        <header-component v-on="$listeners"></header-component>
      </div>
      <div class="main">
        <list-of-blogs-component :blogs="blogs"></list-of-blogs-component>
      </div>
    </div>
  </template>

  <script>
  import axios from 'axios';
  import ListOfBlogsComponent from '../components/ListBlogs.vue';

  export default {
    components: {
      ListOfBlogsComponent
    },
    data() {
      return {
        blogs: []
      }
    },
    created() {
      axios.get('/api/blogs')
        .then(response => {
          this.blogs = response.data;

        })
        .catch(error => {
          console.error(error);
        });
    }
  }
  </script>
