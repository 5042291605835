<template>
    <div class="extras-component">
        <div class="breadcrumb-container">
            <ul class="breadcrumb">
                <li><router-link to="/">Inicio</router-link></li>
                <li><router-link to="/calendar">Fechas</router-link></li>
                <li><router-link to="/destinos">Destinos</router-link></li>
                <li><router-link to="/extras">Extras</router-link></li>
            </ul>
        </div>
        <div class="finalize-modal" id="finalize-modal" @click="hideModal($event)">
            <div class="finalize-modal-content">
                <div class="relative finalize-modal-inner">
                    <h4 class="finalize-modal-content__title">Al realizar la reserva ten en cuenta: </h4>
                    <ul>
                        <li>
                            <p class="finalize-modal-content__text">
                                Cuando acabes la reserva y una vez pagada, autorizas a rubikfly.com a comprar los billetes de avión y la reserva del hotel a tu nombre.
                            </p>
                        </li>
                        <li>
                            <p class="finalize-modal-content__text">
                                Como los billetes se compran pocas horas después de hacerse el pago, generalmente no se podrán hacer
                                cambios ni cancelar las reservas, haciendo que sea no reembolsable.
                            </p>
                        </li>
                        <li>
                            <p class="finalize-modal-content__text">
                                Los billetes van a nombre de los viajeros que nos indicas en el formulario. Si después decides cambiar alguno
                                de los pasajeros, no será posible. Asegúrate de introducir todos los datos correctamente.
                            </p>
                        </li>
                        <li>
                            <p class="finalize-modal-content__text">
                                En todos los pagos realizados en criptomonedas no se podrán realizar devoluciones, solo podremos realizar vales canjeables por otro viaje.
                            </p>
                        </li>
                    </ul>

                    <button class="finalize-modal-content__button" @click="goToNextStep()">Aceptar</button>
                </div>
            </div>
        </div>

        <div class="finalize-modal" id="trip-insurance-modal" @click="hideTripInsuranceModal($event)">
            <div class="finalize-modal-content">
                <div class="relative finalize-modal-inner">
                    <h4 class="finalize-modal-content__title">¿Que incluye el seguro de viaje?</h4>

                    <p>Resumen de Garantias Seguro de Viaje</p>

                    <p>
                        <b>
                            Riesgos Cubiertos
                        </b>
                    </p>
                    <p>
                        <b>
                            <a href="./imgs/seguro-viaje.pdf" target="v_blank">VER CONDICIONADO PARA GARANTIAS DETALLADAS DE LA PÓLIZA</a>
                        </b>
                    </p>

                    <div>
                        <p>
                            <b>
                                Gastos de anulación
                            </b>
                        </p>
                        <p class="flex flex__space-between flex__vertical-align underline">
                            <span>
                                Gastos de anulación de viaje (por asegurado)
                            </span>
                            <span class="text__right">
                                1.500€
                            </span>
                        </p>
                    </div>

                    <div>
                        <p>
                            <b>
                                Asistencia médica y personal en viaje
                            </b>
                        </p>
                        <p class="flex flex__space-between flex__vertical-align underline">
                            <span>
                                Gastos médicos enfermedad o accidente del Asegurado (hasta)
                            </span>
                            <span class="text__right">
                                15.000€
                            </span>
                        </p>
                    </div>

                    <div>
                        <p>
                            <b>
                                Equipajes y documentos
                            </b>
                        </p>
                        <p class="flex flex__space-between flex__vertical-align underline">
                            <span>
                                Perdida o daños de equipaje (Obligatorio PIR)
                            </span>
                            <span class="text__right">
                                1.000€
                            </span>
                        </p>
                    </div>

                    <div>
                        <p>
                            <b>
                                Reembolso de vacaciones no disfrutadas
                            </b>
                        </p>
                        <p class="flex flex__space-between flex__vertical-align underline">
                            <span>
                                Reembolso de vacaciones no disfrutadas
                            </span>
                            <span class="text__right">
                                SEGÚN GASTOS DE CAPITAL CONTRATADOS
                            </span>
                        </p>
                    </div>

                    <div>
                        <p>
                            <b>
                                Envio de de medicamentos en el extranjero
                            </b>
                        </p>
                        <p class="flex flex__space-between flex__vertical-align underline">
                            <span>
                                Envio de de medicamentos en el extranjero
                            </span>
                            <span class="text__right">
                                Incluido
                            </span>
                        </p>
                    </div>

                    <div>
                        <p>
                            <b>
                                Responsabilidad Civil
                            </b>
                        </p>
                        <p class="flex flex__space-between flex__vertical-align underline">
                            <span>
                                Responsabilidad Civil (Franquicia 150€)
                            </span>
                            <span class="text__right">
                                60.000€
                            </span>
                        </p>
                    </div>

                    <button class="finalize-modal-content__button" @click="hideTripInsuranceModal()">Aceptar</button>
                </div>
            </div>
        </div>

        <div class="finalize-modal" id="cancellation-insurance-modal" @click="hideCancellationInsuranceModal($event)">
            <div class="finalize-modal-content">
                <div class="relative finalize-modal-inner">
                    <h4 class="finalize-modal-content__title">¿Que incluye el seguro de cancelación?</h4>


                    <button class="finalize-modal-content__button" @click="hideCancellationInsuranceModal()">Aceptar</button>
                </div>
            </div>
        </div>
        <div class="hotels-type-container">
            <div>
                <h3>¿Qué tipo de hotel prefieres?</h3>
                <h6>Elige en que tipo de hotel te gustaría alojarte</h6>
                <div class="hotel-container">
                    <div class="extra-container extra-selected" id="h3or4-stars">
                        <div class="h3or4-stars-img extra-img"></div>
                        <div class="texts">
                            <h4>Hotel 2* o 3*</h4>
                            <p></p>
                            <p class="gratis">GRATIS</p>
                        </div>
                        <div class="button-container">
                            <button class="selected" id="basic-hotel" @click="changeHotel('basic')">SELECCIONADO</button>
                        </div>
                    </div>
                    <div class="extra-container" id="higher-hotel">
                        <div class="higher-hotel-img extra-img"></div>
                        <div class="texts">
                            <h4>Hotel superior + desayuno</h4>
                            <p></p>
                            <p><span class="cost">{{high_hotel_base_price}}€</span> / persona</p>
                        </div>
                        <div class="button-container">
                            <button id="extra-hotel" @click="changeHotel('extra')">SELECCIONAR</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="insurances-container"  style="display: none;">
            <div>
                <h3 >¿Quieres añadir seguro para tu viaje?</h3>
                <h6>Elige los seguros que quieres para tu viaje</h6>
                <div class="insurance-container">
                    <div class="extra-container relative" id="cancellation">
                        <div class="cancellation-insurance-img"></div>
                        <div class="texts">
                            <h4>Seguro de Cancelación</h4>
                            <p>Recupera tu dinero ante posibles cancelaciones</p>
                            <p><span class="cost">{{cancellation_insurance_base_price}}%</span> del total de la reserva</p>
                        </div>
                        <div class="button-container">
                            <button id="cancellation-insurance" @click="changeCancellationInsurance()">SELECCIONAR</button>
                        </div>
                        <img class="info-img" src="/imgs/circle-info-solid.svg" alt="" @click="showCancellationInsuranceModal()">
                    </div>
                    <div class="extra-container relative" id="trip">
                        <div class="trip-insurance-img extra-img"></div>
                        <div class="texts">
                            <h4>Seguro de viaje</h4>
                            <p>Cúbrete ante cualquier imprevisto durante el viaje</p>
                            <p><span class="cost">{{trip_insurance_base_price}}€</span> / persona</p>
                        </div>
                        <div class="button-container">
                            <button id="trip-insurance" @click="changeTripInsurance()">SELECCIONAR</button>
                        </div>
                        <img class="info-img" src="/imgs/circle-info-solid.svg" alt=""  @click="showTripInsuranceModal()">
                    </div>
                </div>
            </div>
        </div>

        <div class="departure-return-container">
            <div>
                <h3>¿Necesitas limitar horarios de vuelo?</h3>
                <h6>Por cada ajuste horario se suman {{departure_base_price}}€ por persona</h6>
                <div class="deperture-return-inner-container">
                    <div class="extra-container departure-flight">
                        <div class="departure-flight-img"></div>
                        <h5 class="center-text">VUELO DE IDA</h5>
                        <p class="center-text">Haz que los vuelos salgan en tu franja horaria</p>
                        <p class="center-text price">{{departure_hour_price}}€</p>
                        <div class="middle">

                            <div class="multi-range-slider">
                                <input @input="setLeftValue()" type="range" id="input-left" min="0" max="100" value="0">
                                <input @input="setRightValue()" type="range" id="input-right" min="0" max="100" value="100">
                                <div class="slider">
                                    <div class="track"></div>
                                    <div id="range" class="range"></div>
                                    <div id="thumb-left" class="thumb left"></div>
                                    <div id="thumb-right" class="thumb right"></div>
                                </div>
                            </div>
                            <div class="multi-range-hours">
                                <span class="hour hour6">06.00h</span>
                                <span class="hour hour12">12.00h</span>
                                <span class="hour hour18">18.00h</span>
                                <span class="hour hour24">24.00h</span>
                            </div>
                        </div>
                    </div>
                    <div class="extra-container return-flight">
                        <div class="return-flight-img"></div>
                        <h5 class="center-text">VUELO DE VUELTA</h5>
                        <p class="center-text">Haz que los vuelos salgan en tu franja horaria</p>
                        <p class="center-text price">{{return_hour_price}}€</p>
                        <div class="middle">

                            <div class="multi-range-slider">
                                <input @input="setReturnLeftValue()" type="range" id="return-input-left" min="0" max="100" value="0">
                                <input @input="setReturnRightValue()" type="range" id="return-input-right" min="0" max="100" value="100">
                                <div class="slider">
                                    <div class="track"></div>
                                    <div id="return-range" class="range"></div>
                                    <div id="return-thumb-left" class="thumb left"></div>
                                    <div id="return-thumb-right" class="thumb right"></div>
                                </div>
                            </div>
                            <div class="multi-range-hours">
                                <span class="hour hour6">06.00h</span>
                                <span class="hour hour12">12.00h</span>
                                <span class="hour hour18">18.00h</span>
                                <span class="hour hour24">24.00h</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="baggage-container">
            <div>
                <h3>¿Cuanto equipaje vas a llevar?</h3>
                <h6>Elige el numero de maletas que vas a llevar en tu viaje</h6>
                <div class="baggage-inner-container">
                    <div class="extra-container extra-selected" id="no-baggage">
                        <div class="case-img extra-img">

                        </div>
                        <div class="texts">
                            <h4>Equipaje de mano</h4>
                            <p>Bolso o mochila (40x20x25cm) por persona</p>
                            <p class="gratis">GRATIS</p>
                        </div>
                        <div class="button-container">
                            <button id="no-baggage-button" class="selected" @click="selectNoBaggage()">SELECCIONADO</button>
                        </div>
                    </div>
                    <div class="extra-container" id="baggage">
                        <div class="baggage-img extra-img">

                        </div>
                        <div class="texts">
                            <h4>Facturar maletas</h4>
                            <p>Una pieza de equipaje en cabina <br> (máximo 10kg 55x40x20cm)</p>
                            <div class="baggage-amount-container">
                                <div class="change-value" @click="removeBaggage()">
                                    <img src="/imgs/minus-solid.svg" alt="">
                                </div>
                                <div>
                                    <p class="baggage-num">{{baggage_num}}</p>
                                </div>
                                <div class="change-value" @click="addBaggage()">
                                    <img src="/imgs/plus-solid.svg" alt="">
                                </div>
                            </div>
                            <p><span class="cost">{{baggage_base_price}}€</span> / maleta por vuelo</p>
                            <h5>{{baggage_num}} maletas facturadas</h5>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="seats-container">
            <div>
                <h3>¿Quieres sentarte con tu acompañante?</h3>
                <h6>Asegúrate de volar junto a tu compañero de viaje</h6>
                <div class="seat-container">
                    <div class="extra-container extra-selected" id="dont-choose-seats">
                        <div class="dont-choose-seats-img extra-img"></div>
                        <div class="texts">
                            <h4>Asientos sin asignar</h4>
                            <p>Procuramos que siempre viajes acompañado, pero a veces las aerolineas los separan</p>
                            <p class="gratis">GRATIS</p>
                        </div>
                        <div class="button-container">
                            <button class="selected" id="seat-alone" @click="changeSeats('alone')">SELECCIONADO</button>
                        </div>
                    </div>
                    <div class="extra-container" id="choose-seats">
                        <div class="choose-seats-img extra-img"></div>
                        <div class="texts">
                            <h4>Asientos juntos</h4>
                            <p>Te garantizamos un asiento con tu acompañante. Nuestro sistema los asignara juntos de forma automática</p>
                            <p><span class="cost">{{seats_base_price}}€</span> / persona y vuelo</p>
                        </div>
                        <div class="button-container">
                            <button id="seat-together" @click="changeSeats('together')">SELECCIONAR</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="extras-button-container">
            <button @click="showModal()">Avanzamos</button>
        </div>
    </div>
</template>
<script>
export default {
    data: function(){
        return{
            hotel: "3 o 4 estrellas",
            cancellation_insurance: false,
            trip_insurance: false,
            departure_hour_range:{
                start: "06:00h",
                end: "24:00h"
            },
            return_hour_range: {
                start: "06:00h",
                end: "24:00h"
            },
            departure_price_left: 0,
            departure_price_right: 0,
            departure_hour_price: 0,
            return_price_left: 0,
            return_price_right: 0,
            return_hour_price: 0,
            suitcases: 0,
            baggage_num: 0,
            people: 0,
            acumulated_price: 0,
            departure_base_price: 0,
            return_base_price: 0,
            baggage_base_price: 0,
            high_hotel_base_price: 0,
            cancellation_insurance_base_price: 0,
            trip_insurance_base_price: 0,
            seats_base_price: 0,
            seat_together: false,
        }
    },
    computed:{
        total_price(){
            let destinationsInfo = JSON.parse(localStorage.getItem('destinations-data'));
            this.acumulated_price = destinationsInfo.total_price;
            let hotel_price = this.hotel == "3 o 4 estrellas" ? 0 : this.high_hotel_base_price * this.people;
            let trip_insurance_price = this.trip_insurance ? this.trip_insurance_base_price * this.people : 0;
            let seats_price = this.seat_together ? this.seats_base_price * this.people * 2 : 0;
            let subtotal = this.acumulated_price + this.departure_hour_price + this.return_hour_price + (this.baggage_num * this.baggage_base_price * this.people) + hotel_price + trip_insurance_price + seats_price;
            let cancellation_insurance_price = this.cancellation_insurance ? subtotal * this.cancellation_insurance_base_price / 100 : 0;
            return subtotal + cancellation_insurance_price;
        }
    },
    beforeMount(){
        let calendarInfo = JSON.parse(localStorage.getItem("calendar-data"));
        let LSObject = JSON.parse(localStorage.getItem('main-data'));
        let destinationsInfo = JSON.parse(localStorage.getItem('destinations-data'));
        const now = new Date();
        if(!LSObject || !calendarInfo || !destinationsInfo){
            this.$router.push({name:'Home'});
        }else{
            if(LSObject.expires < now.getTime() || calendarInfo.expires < now.getTime() || destinationsInfo.expires < now.getTime()){
                localStorage.removeItem('main-data');
                localStorage.removeItem('calendar-data');
                localStorage.removeItem('destinations-data');
                this.$router.push({name:'Home'});
            }
        }


        this.getExtraPrices();
        this.people = LSObject.adults + LSObject.kids + LSObject.babies;
        //this.acumulated_price = destinationsInfo.total_price;

        this.$emit('hasPrice', this.total_price);
        window.scrollTo(0,0);
    },
    mounted(){

    },
    methods:{
        showModal(){
            let modal = document.getElementById("finalize-modal");
            modal.style.display = "block";
        },
        hideModal(event = null){
            let modal = document.getElementById("finalize-modal");
            if(event !=null){
                if(event.target == modal){
                    modal.style.display = "none";
                }
            }else{
                modal.style.display = "none";
            }
        },
        showTripInsuranceModal(){
            let modal = document.getElementById("trip-insurance-modal");
            modal.style.display = "block";
        },
        hideTripInsuranceModal(event = null){
            let modal = document.getElementById("trip-insurance-modal");
            if(event !=null){
                if(event.target == modal){
                    modal.style.display = "none";
                }
            }else{
                modal.style.display = "none";
            }
        },
        showCancellationInsuranceModal(){
            let modal = document.getElementById("cancellation-insurance-modal");
            modal.style.display = "block";
        },
        hideCancellationInsuranceModal(event = null){
            let modal = document.getElementById("cancellation-insurance-modal");
            if(event !=null){
                if(event.target == modal){
                    modal.style.display = "none";
                }
            }else{
                modal.style.display = "none";
            }
        },
        getExtraPrices(){
            axios.get('/extra-price/Descarte ida')
            .then(res => {
                this.departure_base_price = res.data.price;
            });

            axios.get('/extra-price/Descarte vuelta')
            .then(res => {
                this.return_base_price = res.data.price;
            });

            axios.get('/extra-price/Seguro de Viaje')
            .then(res => {
                this.trip_insurance_base_price = res.data.price;
            });

            axios.get('/extra-price/Seguro de Cancelacion')
            .then(res => {
                this.cancellation_insurance_base_price = res.data.price;
            });

            axios.get('/extra-price/Equipaje')
            .then(res => {
                this.baggage_base_price = res.data.price;
            });

            axios.get('/extra-price/Hotel Superior')
            .then(res => {
                this.high_hotel_base_price = res.data.price;
            });

            axios.get('/extra-price/Asientos Juntos')
            .then(res => {
                this.seats_base_price = res.data.price;
            });
        },
        changeHotel(type){
            let basic = document.getElementById("basic-hotel");
            let extra = document.getElementById("extra-hotel");
            let basic_container = document.getElementById("h3or4-stars");
            let extra_container = document.getElementById("higher-hotel");
            if(type=='basic'){
                basic.innerHTML="SELECCIONADO";
                extra.innerHTML="SELECCIONAR";
                basic.classList.add("selected");
                extra.classList.remove("selected");
                basic_container.classList.add("extra-selected");
                extra_container.classList.remove("extra-selected");
                this.hotel = "3 o 4 estrellas"
            }else{
                extra.innerHTML="SELECCIONADO";
                basic.innerHTML="SELECCIONAR";
                extra.classList.add("selected");
                basic.classList.remove("selected");
                basic_container.classList.remove("extra-selected");
                extra_container.classList.add("extra-selected");
                this.hotel = "Superior + desayuno"
            }

            this.$emit('hasPrice', this.total_price);
        },
        changeSeats(type){
            let basic = document.getElementById("seat-alone");
            let extra = document.getElementById("seat-together");
            let basic_container = document.getElementById("dont-choose-seats");
            let extra_container = document.getElementById("choose-seats");
            if(type=='alone'){
                basic.innerHTML="SELECCIONADO";
                extra.innerHTML="SELECCIONAR";
                basic.classList.add("selected");
                extra.classList.remove("selected");
                basic_container.classList.add("extra-selected");
                extra_container.classList.remove("extra-selected");
                this.seat_together = false;
            }else{
                extra.innerHTML="SELECCIONADO";
                basic.innerHTML="SELECCIONAR";
                extra.classList.add("selected");
                basic.classList.remove("selected");
                basic_container.classList.remove("extra-selected");
                extra_container.classList.add("extra-selected");
                this.seat_together = true;
            }

            this.$emit('hasPrice', this.total_price);
        },
        changeCancellationInsurance(){
            let cancellation_insurance_button = document.getElementById("cancellation-insurance");
            let cancellation_insurance = document.getElementById("cancellation");
            if(!this.cancellation_insurance){
                cancellation_insurance_button.innerHTML="SELECCIONADO"
                cancellation_insurance_button.classList.add("selected");
                cancellation_insurance.classList.add("extra-selected");
                this.cancellation_insurance = true;
            }else{
                cancellation_insurance_button.innerHTML="SELECCIONAR"
                cancellation_insurance_button.classList.remove("selected");
                cancellation_insurance.classList.remove("extra-selected");
                this.cancellation_insurance = false;
            }

            this.$emit('hasPrice', this.total_price);
        },
        changeTripInsurance(){
            let trip_insurance_button = document.getElementById("trip-insurance");
            let trip_insurance = document.getElementById("trip");
            if(!this.trip_insurance){
                trip_insurance_button.innerHTML="SELECCIONADO"
                trip_insurance_button.classList.add("selected");
                trip_insurance.classList.add("extra-selected");
                this.trip_insurance = true;
            }else{
                trip_insurance_button.innerHTML="SELECCIONAR"
                trip_insurance_button.classList.remove("selected");
                trip_insurance.classList.remove("extra-selected");
                this.trip_insurance = false;
            }

            this.$emit('hasPrice', this.total_price);
        },
        setLeftValue(){
            let input_left = document.getElementById("input-left");
            let input_right = document.getElementById("input-right");
            let thumb_left = document.getElementById("thumb-left");
            let thumb_right = document.getElementById("thumb-right");
            let range = document.getElementById("range");
            let _this = input_left;
            let min = parseInt(_this.min);
            let max = parseInt(_this.max);
            if(parseInt(_this.value) < 17){
                _this.value=0;
                this.departure_price_left = 0;
                this.departure_hour_range.start = "06:00h"
            }else if(parseInt(_this.value) < 50){
                _this.value=33;
                if(this.departure_price_right <= this.departure_base_price * this.people){
                    this.departure_price_left = this.departure_base_price * this.people;
                }
                this.departure_hour_range.start = "12:00h"

            }else{
                _this.value=66;
                if(this.departure_price_right == 0){
                    this.departure_price_left = this.departure_base_price * 2 * this.people;
                }
                this.departure_hour_range.start = "18:00h"
            }
            _this.value = Math.min(parseInt(_this.value), parseInt(input_right.value)-33)
            let percent = ((_this.value - min)/(max-min)) * 100;
            thumb_left.style.left = percent +"%";
            range.style.left = percent +"%";
            this.departure_hour_price = this.departure_price_left + this.departure_price_right;

            this.$emit('hasPrice', this.total_price);
        },
        setRightValue(){
            let input_left = document.getElementById("input-left");
            let input_right = document.getElementById("input-right");
            let thumb_left = document.getElementById("thumb-left");
            let thumb_right = document.getElementById("thumb-right");
            let range = document.getElementById("range");
            let _this = input_right;
            let min = parseInt(_this.min);
            let max = parseInt(_this.max);
            if(parseInt(_this.value) > 83){
                _this.value=100;
                this.departure_price_right = 0;
                this.departure_hour_range.end = "24:00h";
            }else if(parseInt(_this.value) > 50){
                _this.value=66;
                if(this.departure_price_left <= this.departure_base_price * this.people){
                    this.departure_price_right = this.departure_base_price * this.people;
                }
                this.departure_hour_range.end = "18:00h";
            }else{
                _this.value=33;
                if(this.departure_price_left == 0){
                    this.departure_price_right = this.departure_base_price * 2 * this.people;
                }
                this.departure_hour_range.end = "12:00h";
            }
            _this.value = Math.max(parseInt(_this.value), parseInt(input_left.value)+33)
            let percent = ((_this.value - min)/(max-min)) * 100;
            thumb_right.style.right = (100 - percent) +"%";
            range.style.right = (100 - percent) +"%";
            this.departure_hour_price = this.departure_price_left + this.departure_price_right;

            this.$emit('hasPrice', this.total_price);
        },
        setReturnLeftValue(){
            let input_left = document.getElementById("return-input-left");
            let input_right = document.getElementById("return-input-right");
            let thumb_left = document.getElementById("return-thumb-left");
            let thumb_right = document.getElementById("return-thumb-right");
            let range = document.getElementById("return-range");
            let _this = input_left;
            let min = parseInt(_this.min);
            let max = parseInt(_this.max);
            if(parseInt(_this.value) < 17){
                _this.value=0;
                this.return_price_left = 0;
                this.return_hour_range.start = "06:00h"
            }else if(parseInt(_this.value) < 50){
                _this.value=33;
                if(this.return_price_right <= this.return_base_price * this.people){
                    this.return_price_left = this.return_base_price * this.people;
                }
                this.return_hour_range.start = "12:00h"
            }else{
                _this.value=66;
                if(this.return_price_right == 0){
                    this.return_price_left = this.return_base_price * 2 * this.people;
                }
                this.return_hour_range.start = "18:00h"
            }
            _this.value = Math.min(parseInt(_this.value), parseInt(input_right.value)-33)
            let percent = ((_this.value - min)/(max-min)) * 100;
            thumb_left.style.left = percent +"%";
            range.style.left = percent +"%";
            this.return_hour_price = this.return_price_left + this.return_price_right;

            this.$emit('hasPrice', this.total_price);
        },
        setReturnRightValue(){
            let input_left = document.getElementById("return-input-left");
            let input_right = document.getElementById("return-input-right");
            let thumb_left = document.getElementById("return-thumb-left");
            let thumb_right = document.getElementById("return-thumb-right");
            let range = document.getElementById("return-range");
            let _this = input_right;
            let min = parseInt(_this.min);
            let max = parseInt(_this.max);
            if(parseInt(_this.value) > 83){
                _this.value=100;
                this.return_price_right = 0;
                this.return_hour_range.end = "24:00h";
            }else if(parseInt(_this.value) > 50){
                _this.value=66;
                if(this.return_price_left <= this.return_base_price * this.people){
                    this.return_price_right = this.return_base_price * this.people;
                }
                this.return_hour_range.end = "18:00h";
            }else{
                _this.value=33;
                if(this.return_price_left == 0){
                    this.return_price_right = this.return_base_price * 2 * this.people;
                }
                this.return_hour_range.end = "12:00h";
            }
            _this.value = Math.max(parseInt(_this.value), parseInt(input_left.value)+33)
            let percent = ((_this.value - min)/(max-min)) * 100;
            thumb_right.style.right = (100 - percent) +"%";
            range.style.right = (100 - percent) +"%";
            this.return_hour_price = this.return_price_left + this.return_price_right;

            this.$emit('hasPrice', this.total_price);
        },
        addBaggage(){
            let headerObject = JSON.parse(localStorage.getItem("main-data"));
            let adults = parseInt(headerObject.adults);
            let kids = parseInt(headerObject.kids);
            let no_baggage_button = document.getElementById("no-baggage-button");
            let no_baggage_container = document.getElementById("no-baggage");
            let baggage_container = document.getElementById("baggage");
            no_baggage_button.classList.remove("selected");
            no_baggage_container.classList.remove("extra-selected");
            baggage_container.classList.add("extra-selected");
            no_baggage_button.innerHTML = "SELECCIONAR";
            if(this.baggage_num < (adults*2)+(kids*2)){
                this.baggage_num++;
            }

            this.$emit('hasPrice', this.total_price);
        },
        removeBaggage(){
            let no_baggage_button = document.getElementById("no-baggage-button");
            let no_baggage_container = document.getElementById("no-baggage");
            let baggage_container = document.getElementById("baggage");
            if(this.baggage_num > 0){
                this.baggage_num--;
            }

            if(this.baggage_num == 0){
                no_baggage_button.classList.add("selected");
                no_baggage_button.innerHTML = "SELECCIONADO"
                no_baggage_container.classList.add("extra-selected");
                baggage_container.classList.remove("extra-selected");
            }

            this.$emit('hasPrice', this.total_price);
        },
        selectNoBaggage(){
            let no_baggage_button = document.getElementById("no-baggage-button");
            let no_baggage_container = document.getElementById("no-baggage");
            let baggage_container = document.getElementById("baggage");
            no_baggage_button.classList.add("selected");
            no_baggage_button.innerHTML = "SELECCIONADO"
            no_baggage_container.classList.add("extra-selected");
            baggage_container.classList.remove("extra-selected");
            this.baggage_num = 0;

            this.$emit('hasPrice', this.total_price);
        },
        goToNextStep(){
            const now = new Date();
            let mainInfo = JSON.parse(localStorage.getItem("main-data"));
            let calendarInfo = JSON.parse(localStorage.getItem("calendar-data"));
            let destinationsInfo = JSON.parse(localStorage.getItem("destinations-data"));
            let extrasInfo = {
                hotel: this.hotel,
                cancellation_insurance: this.cancellation_insurance,
                trip_insurance: this.trip_insurance,
                departure_hour_range: this.departure_hour_range,
                return_hour_range: this.return_hour_range,
                baggage_num: this.baggage_num,
                seat_together: this.seat_together,
                expires: now.getTime() + (1000 * 60 * 60) //1 hour
            }

            if(mainInfo.expires < now.getTime() || calendarInfo.expires < now.getTime() || destinationsInfo.expires < now.getTime()){
                this.$router.push({name:'Home'});
            }

            localStorage.setItem("extras-data", JSON.stringify(extrasInfo));

            this.$router.push({name: 'finaliza'});
        }
    }
}
</script>
