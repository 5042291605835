var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "hotel-component",
      attrs: { id: "hotel-component" + _vm.id },
    },
    [
      _c("div", {
        staticClass: "img-container",
        attrs: { id: "hotel-img" + _vm.id },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "hotel-info" }, [
        _c("div", { staticClass: "main-info" }, [
          _c("h6", [
            _vm._v(
              "\n                " +
                _vm._s(_vm.location) +
                " - " +
                _vm._s(_vm.name) +
                "\n                \n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [_c("p", [_vm._v(_vm._s(_vm.description))])]),
        _vm._v(" "),
        _c("div", { staticClass: "valorations-container" }, [
          _c("div", { staticClass: "valorations" }, [
            _c("div", [
              _c("p", { staticClass: "opinion" }, [
                _vm._v(_vm._s(_vm.opinion)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "comments-number" }, [
                _vm._v(_vm._s(_vm.comments_number) + " comentarios"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "ubication" }, [
                _vm._v("Ubicación " + _vm._s(_vm.ubication)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "punctuation" }, [
              _c("div", { staticClass: "punctuation-container" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.punctuation) +
                    "\n                    "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "booking-punctuation" }, [
              _c(
                "div",
                { staticClass: "stars" },
                _vm._l(_vm.stars, function (star, key) {
                  return _c("img", {
                    key: "star" + key,
                    attrs: { src: "/imgs/star-solid.svg", alt: "" },
                  })
                }),
                0
              ),
              _vm._v(" "),
              _c("p", [_vm._v("Puntuación en Booking")]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }