import en from "./lang/en.json";
import es from "./lang/es.json";

import VueI18n from 'vue-i18n';
import Vue from "vue";

Vue.use(VueI18n);

let locale = document.documentElement.lang.substr(0, 2);

if(localStorage.getItem("lang")){
    locale = localStorage.getItem("lang");
}

if(locale != "es" && locale != "en"){
    locale = "en";
}

const i18n = new VueI18n({
    locale: locale,
    messages: {
        en: en,
        es: es
    }
})

export default i18n;