var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "airports-component" }, [
    _c("h1", [_vm._v("Aeropuertos")]),
    _vm._v(" "),
    _c(
      "table",
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.airports, function (airport, key) {
          return _c("tr", { key: key }, [
            _c("td", [
              _c("input", {
                staticClass: "disabled-input",
                attrs: { id: "name" + key, type: "text", disabled: "" },
                domProps: { value: airport.name },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                staticClass: "disabled-input",
                attrs: { id: "price" + key, type: "number", disabled: "" },
                domProps: { value: airport.price },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                {
                  staticClass: "edit bold brand-color",
                  attrs: { id: "edit" + key },
                  on: {
                    click: function ($event) {
                      !_vm.editing
                        ? _vm.enableEdit(key)
                        : !_vm.adding
                        ? _vm.saveEdit(key)
                        : _vm.saveNew(key)
                    },
                  },
                },
                [_vm._v("Editar")]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                {
                  staticClass: "red-text bold",
                  on: {
                    click: function ($event) {
                      return _vm.deleteAirport(key)
                    },
                  },
                },
                [_vm._v("Eliminar")]
              ),
            ]),
          ])
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "add-airport-button-container" }, [
      _c(
        "button",
        {
          staticClass: "add-airport-button",
          on: {
            click: function ($event) {
              return _vm.addAirport()
            },
          },
        },
        [_vm._v("Añadir aeropuerto")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("\n                Nombre\n            ")]),
      _vm._v(" "),
      _c("th", [_vm._v("\n                Precio\n            ")]),
      _vm._v(" "),
      _c("th", [_vm._v("\n                Editar\n            ")]),
      _vm._v(" "),
      _c("th", [_vm._v("\n                Eliminar\n            ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }