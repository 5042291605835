var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "promotions-container" }, [
      _c("h3", [_vm._v("¡No te pierdas nuestras promociones!")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Infórmate de nuestros descuentos, noticias y nuevos destinos en nuestra newsletter. ¿A qué estás esperando? Únete a nuestra comunidad y sé el primero en disfrutar de promociones únicas y ofertas exclusivas. Cada viaje es una aventura cuidadosamente seleccionada para que no tengas que planificar. Vive una experiencia única en un destino europeo sorpresa.    "
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("form", { attrs: { action: "/newsletter" } }, [
          _c("input", {
            staticClass: "email",
            attrs: { type: "email", placeholder: "E-mail" },
          }),
          _vm._v(" "),
          _c("input", {
            staticClass: "send",
            attrs: { type: "submit", value: "ENVIAR" },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }