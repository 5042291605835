var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "destination-view-component" }, [
    _c(
      "div",
      {
        staticClass: "destination-container flex",
        attrs: { id: "destination" + _vm.id },
        on: {
          click: function ($event) {
            return _vm.discard()
          },
        },
      },
      [_c("h2", [_vm._v(_vm._s(_vm.name))])]
    ),
    _vm._v(" "),
    _c("span", {
      staticClass: "hidden discarded-destination-text",
      attrs: { id: "discarded" + _vm.id },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }