var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "destinations-view-component" }, [
    _c("div", { staticClass: "breadcrumb-container" }, [
      _c("ul", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Inicio")])],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/calendar" } }, [
              _vm._v("Fechas"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/destinos" } }, [
              _vm._v("Destinos"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", [_vm._v("Extras")]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "destinations-modal",
        attrs: { id: "destinations-modal" },
        on: {
          click: function ($event) {
            return _vm.hideModal($event)
          },
        },
      },
      [
        _c("div", { staticClass: "destinations-modal-content" }, [
          _c("div", { staticClass: "relative destinations-modal-inner" }, [
            _c(
              "p",
              {
                staticClass: "destinations-modal-content__close",
                attrs: { id: "destinations-modal-content__close" },
                on: {
                  click: function ($event) {
                    return _vm.hideModal($event)
                  },
                },
              },
              [_vm._v("×")]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "destinations-modal-content__text" }, [
              _vm._v("Solo puedes descartar un máximo de 6 destinos"),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "titles" }, [
      _c("h2", [_vm._v("¿Quieres descartar destinos?")]),
      _vm._v(" "),
      _c("h4", [
        _vm._v(
          "Elige los destinos que descartar, el primero es gratis. Si deseas descartar mas se añadirán " +
            _vm._s(_vm.price) +
            "€ por destino y persona."
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "destinations-outter-container" }, [
      _c(
        "div",
        { staticClass: "destinations-container" },
        _vm._l(_vm.destinations, function (destination, key) {
          return _c(
            "div",
            { key: key },
            [
              _c("destination-view-component", {
                attrs: {
                  img: destination.img,
                  name: destination.name,
                  id: key,
                  discarded: _vm.discarted,
                },
                on: { clicked: _vm.onClickChild },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "destinations-button-container" }, [
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.goToNextStep()
            },
          },
        },
        [_vm._v("Avanzamos")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }