<template>
    <div class="blog-container">
        <div class="blog-card" v-for="blog in processedBlogs" :key="blog.id">
            <router-link :to="'/blogs/' + blog.slug" class="blog-link">
                <img :src="blog.image_url" alt="Blog Image" class="blog-image" />
                <div class="blog-content">
                    <h2>{{ blog.title }}</h2>
                    <p v-html="blog.shortContent"></p> <!-- Use v-html directive here -->
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        blogs: Array
    },
    computed: {
        processedBlogs() {
            return this.blogs.map(blog => ({
                ...blog,
                shortContent: blog.content.length > 50 ? blog.content.substring(0, 50) + '...' : blog.content
            }));
        }
    }
}
</script>

<style scoped>
.blog-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.blog-card {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.blog-content {
    padding: 15px;
}

.blog-content h2 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.blog-content p {
    font-size: 14px;
    color: #666;
}

.blog-link {
    text-decoration: none;
    /* Removes underline */
    color: inherit;
    /* Ensures text colors are not affected by link styling */
}
</style>
