var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "contact-header" }),
    _vm._v(" "),
    _c("div", { staticClass: "contact-body" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "contact-form" }, [
        _c(
          "form",
          { attrs: { action: "" } },
          [
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _vm._m(5),
            _vm._v(" "),
            _c("vue-recaptcha", { attrs: { sitekey: _vm.recaptcha_key } }),
            _vm._v(" "),
            _vm._m(6),
            _vm._v(" "),
            _vm._m(7),
            _vm._v(" "),
            _c("button", { attrs: { type: "submit" } }, [_vm._v("Enviar")]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact-info" }, [
      _c("h1", [_vm._v("Contacta con nosotros")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestias laborum ratione iste dolorem voluptates consequuntur suscipit, non libero ad quam reprehenderit sed deserunt similique, magnam, quod et quidem aut inventore!"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { attrs: { for: "name" } }, [_vm._v("Nombre")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("input", { attrs: { type: "text", name: "name" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { attrs: { for: "email" } }, [_vm._v("Correo electónico")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("input", { attrs: { type: "email", name: "email" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { attrs: { for: "phone" } }, [_vm._v("Teléfono")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("input", { attrs: { type: "text", name: "phone" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { attrs: { for: "subject" } }, [_vm._v("Asunto")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("input", { attrs: { type: "text", name: "subject" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { attrs: { for: "comment" } }, [_vm._v("Comentario")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("textarea", { attrs: { rows: "10" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "checkbox" }, [
      _c("input", { attrs: { type: "checkbox", name: "checkbox" } }),
      _vm._v(" "),
      _c("label", { attrs: { for: "checkbox" } }, [
        _vm._v(
          "Autorizo el tratamiento de mis datos para futuras ofertas comerciales por parte de Rubikfly."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "checkbox" }, [
      _c("input", { attrs: { type: "checkbox", name: "checkbox2" } }),
      _vm._v(" "),
      _c("label", { attrs: { for: "checkbox2" } }, [
        _vm._v(
          "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor tempore, distinctio error velit iure minus! Facilis voluptates nihil et nostrum voluptatem obcaecati consequuntur aspernatur deserunt. Iste iusto quo totam harum!"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }