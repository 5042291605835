import { render, staticRenderFns } from "./Booking.vue?vue&type=template&id=b770246c&"
import script from "./Booking.vue?vue&type=script&lang=js&"
export * from "./Booking.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\rubikfly\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b770246c')) {
      api.createRecord('b770246c', component.options)
    } else {
      api.reload('b770246c', component.options)
    }
    module.hot.accept("./Booking.vue?vue&type=template&id=b770246c&", function () {
      api.rerender('b770246c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/admin/views/Booking.vue"
export default component.exports