<template>
    <div :id="'opinion-container'+id" class="opinion-container">
        <div class="opinion-info-container">
            <div class="img-outter-container">
                <div class="img-container" :id="'img-container'+id+index">
                    <h1 v-if="img=='none'">{{name.charAt(0)}}</h1>
                </div>
            </div>
            <div class="opinion-info">
                <h2 class="name">{{name}}</h2>
                <div class="stars">
                    <img src="/imgs/star-solid.svg" alt="" v-for="(star, key) in stars" :key="'star'+key">
                </div>
                <p class="date">{{date}}</p>
            </div>
        </div>

        <p class="opinion">{{opinion}}</p>
        <div class="see-more">
            <a href="https://www.google.com/search?q=rubikfly#lrd=0x12be03cf844e2523:0x617a0d9dc0419aa7,1,,," target="v_blank">Ver mas reseñas en Google</a>
        </div>

    </div>
</template>
<script>
export default {
    props:[
        'name',
        'img',
        'id',
        'opinion',
        'stars',
        'date',
        'index'
    ],
    mounted(){
        if(this.img!='none'){
            document.getElementById("img-container"+this.id+this.index).style.backgroundImage=`url('${this.img}')`;
        }
    }
}
</script>
