var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bookings-view" }, [
    _c("h1", [_vm._v("Reservas")]),
    _vm._v(" "),
    _c(
      "table",
      { staticClass: "table" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.bookings, function (booking, key) {
          return _c("tr", { key: key }, [
            _c("td", [
              _vm._v(
                "\n                " + _vm._s(booking.id) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n               " +
                  _vm._s(booking.departure_date) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n               " +
                  _vm._s(booking.return_date) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n               " + _vm._s(booking.origin) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n               " +
                  _vm._s(booking.destination) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("a", { attrs: { href: "/admin/booking/" + booking.id } }, [
                _vm._v("Ver"),
              ]),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("\n                Id\n            ")]),
      _vm._v(" "),
      _c("th", [_vm._v("\n                Ida\n            ")]),
      _vm._v(" "),
      _c("th", [_vm._v("\n                Vuelta\n            ")]),
      _vm._v(" "),
      _c("th", [_vm._v("\n                Origen\n            ")]),
      _vm._v(" "),
      _c("th", [_vm._v("\n                Destino\n            ")]),
      _vm._v(" "),
      _c("th", [_vm._v("\n                Ver\n            ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }