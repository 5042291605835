<template>
    <div :id="'destination-img-container'+id" class="destination-img-container flex">
        <h4>{{name}}</h4>
    </div>
</template>
<script>
export default {
    props:[
        'name',
        'img',
        'id'
    ],
    mounted(){
        let el = document.getElementById("destination-img-container"+this.id);
        el.style.backgroundImage = this.img;
    }
}
</script>