<template>
    <div class="hotels-component">
        <div class="hide-on-mobile">
            <header-component v-on="$listeners"></header-component>
        </div>

        <div class="hotels-component-titles">
            <h1>Hoteles</h1>
            <h5 class="hotels-description">
                Los hoteles son elegidos con sumo cuidado y pensando siempre con el mejor bienestar de nuestros clientes.
                Por ello, la calidad de los hoteles elegidos siempre es de 3* o superior. Además, intentamos ubicar a nuestros clientes en los
                hoteles mas céntricos o bien comunicados de la ciudad de destino.
            </h5>
        </div>

        <div class="hotels-container">
            <div v-for="(hotel, key) in hotels.slice(0, num)" :key="key">
                <hotel-component
                    :img="hotel.img"
                    :name="hotel.name"
                    :stars="hotel.stars"
                    :location="hotel.location"
                    :description="hotel.description"
                    :opinion="hotel.opinion"
                    :comments_number="hotel.comments_number"
                    :ubication="hotel.ubication"
                    :punctuation="hotel.punctuation"
                    :id="key"></hotel-component>
            </div>
        </div>
        <div class="see-more-container">
            <button v-if="num < hotels.length" @click="seeMore()">VER MÁS HOTELES</button>
        </div>

    </div>
</template>

<script>
export default {
    data: function(){
        return {
            hotels:[
                {
                    img:"../imgs/hotel-sevilla.webp",
                    name: "Hotel Casa Imperial",
                    stars: 4,
                    location: "SEVILLA",
                    description: "El Hotel Casa Imperial se encuentra en el centro histórico de Sevilla, a 10 minutos a pie de la catedral, y ocupa una mansión fantástica de estilo andaluz.",
                    opinion: "Fabuloso",
                    comments_number: 2095,
                    ubication: "9.5",
                    punctuation: "8.8"
                },
                {
                    img:"../imgs/hotel-roma.webp",
                    name: "Hotel Varese",
                    stars: 4,
                    location: "ROMA",
                    description: "El Hotel Varese está a solo 300 metros de la estación de tren Termini y ofrece servicio de recepción las 24 horas y WiFi gratuita en el vestíbulo. Las habitaciones disponen de aire acondicionado, TV vía satélite y minibar.",
                    opinion: "Fabuloso",
                    comments_number: 379,
                    ubication: "9.7",
                    punctuation: "9.0"
                },
                {
                    img:"../imgs/hotel-paris.webp",
                    name: "Hotel Malte Astotel",
                    stars: 4,
                    location: "PARIS",
                    description: "El Hotel de 4 estrellas está ubicado en el centro histórico de París, a solo 650 metros del Museo del Louvre. Cuenta con un patio interior y recepción las 24 horas.",
                    opinion: "Fabuloso",
                    comments_number: 565,
                    ubication: "9.6",
                    punctuation: "9.0"
                },
                {
                    img:"../imgs/hotel4-menorca.webp",
                    name: "Hotel Ses 5 Claus",
                    stars: 4,
                    location: "MENORCA",
                    description: "El Hotel Ses 5 Claus está situado en Ciutadella, a 500 metros de la Playa. Ses 5 claus ofrece habitaciones con aire acondicionado y un jardín. Muy céntrico y con habitaciones cuidadas con todo tipo de detalles.",
                    opinion: "Fabuloso",
                    comments_number: 103,
                    ubication: "9.8",
                    punctuation: "9.3"
                },
                {
                    img:"../imgs/hotel-mallorca.webp",
                    name: "Brondo ArchitectHotel",
                    stars: 4,
                    location: "MALLORCA",
                    description: "Hotel de lujo elegante. Está situado en el centro histórico encantador de Palma de Mallorca, a solo 4 minutos a pie de la catedral. El Architect Hotel combina una arquitectura mallorquina tradicional.",
                    opinion: "Fabuloso",
                    comments_number: 1412,
                    ubication: "9.6",
                    punctuation: "8.2"
                },
                {
                    img:"../imgs/hotel-londres.webp",
                    name: "Hotel Indigo Tower Hill",
                    stars: 4,
                    location: "LONDRES",
                    description: "El nuevo Indigo London Tower Hill Hotel está situadoa unos pasos de la Torre de Londres y ofrece habitaciones boutique de diseño individual con conexión Wi-Fi gratuita, cafetera Nespresso y TV.",
                    opinion: "Fantástico",
                    comments_number: 414,
                    ubication: "9.3",
                    punctuation: "9.0"
                },
                {
                    img:"../imgs/hotel-laspalmas.webp",
                    name: "Silken Saaj",
                    stars: 4,
                    location: "LAS PALMAS",
                    description: "EL Silken Saaj Las Palmas ofrece WiFi gratuita y se encuenta en Las Palmas de Gran Canaria, a 1 km del parque de Santa Catalina. Es establecimiento se encuentra a menos de 1 km del parque Romano.",
                    opinion: "Fabuloso",
                    comments_number: 974,
                    ubication: "9.2",
                    punctuation: "8.5"
                },
                {
                    img:"../imgs/hotel-barcelona.webp",
                    name: "Pol & Grace Hotel",
                    stars: 4,
                    location: "BARCELONA",
                    description: "El Pol & Grace Hotel se encuentra a solo 100 metros de las estaciones de tren de Plaça Molina y Sant Gervasi de Barcelona. Este hotel moderno cuenta con biblioteca, zona de trabajo de uso gratuito y zona de exposición de obras de artistas locales.",
                    opinion: "Fantástico",
                    comments_number: 2137,
                    ubication: "9.4",
                    punctuation: "8.8"
                }
            ],
            num: 6
        }
    },
    mounted(){

    },
    methods:{
        seeMore(){
            this.num+=6;
        }
    }
}
</script>
