<template>
    <div class="faq-view">
        <div class="hide-on-mobile">
            <header-component v-on="$listeners"></header-component>
        </div>

        <div class="faq">
            <h1>Preguntas frecuentes</h1>
            <h5>Información para los viajeros. Resuelve todas tus dudas antes de viajar. Aquí te dejamos las respuestas a las preguntas más frecuentes.</h5>
            <div v-for="(question, key) in questions" :key="key">
                <div class="question">
                    <h3 class="question__title" @click="openClose(key)"><span class="question__title-text">{{question.title}}</span> <span class="relative"><img :id="'img'+key" class="up-down-img absolute" :src="down_img" alt=""></span></h3>
                </div>
                <p :id="'answer'+key" class="hidden" v-html="question.answer"></p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: function(){
        return {
            questions: [
                {
                    title: "¿Qué es RUBIKFLY?",
                    answer: "Rubikfly es una nueva compañia con una nueva forma de viajar por España y Europa. Te escaparás durante tres días a una ciudad sorpresa donde conocerás tu destino entre 48 horas antes de la salida del vuelo."
                },{
                    title: "¿Qué incluye un pack?",
                    answer: "Vuelos de ida y vuelta, las noches de alojamiento contratadas y la emoción de un destino sorpresa. Lo recibirás todo por email 48 antes de la salida del vuelo."
                },{
                    title: "¿Qué no incluye el pack?",
                    answer: "Los transportes aeropuerto y hotel, tasas turística de algunas ciudades."
                },{
                    title: "¿Qué diferencia hay entre Alojamientos?",
                    answer: 'Disponemos de 2 tipos de Alojamientos, en ambos los alojamientos son lo más céntricos posibles o están bien comunicados con transporte público. <br/> Alojamientos de 3 o 4 estrellas sin desayuno. <br/> Alojamientos de 4 y 5 estrellas con desayuno.'
                },{
                    title: "¿Cuándo sabré la sorpresa de dónde viajo?",
                    answer: "Conocerás tu destino entre 48 horas antes de salida del vuelo via e-mail."
                },{
                    title: "¿Si ya he viajado con Rubikfly ¿me puede tocar otra vez el mismo destino?",
                    answer: "No, pero recuerda avisarnos al hacer la nueva reserva."
                },{
                    title: "¿Puedo viajar aunque no tenga la nacionalidad española?",
                    answer: "Sí, al disponer de DNI o pasaporte en regla y seas de un estado miembro del espacio Schengen."
                },{
                    title: "¿Qué equipaje esta permitido llevar?",
                    answer: "Los packs sorpresa incluyen el equipaje de mano gratuito. Dependiendo de la compañía aérea que te toque, las medidas y condiciones pueden variar."
                },{
                    title: "¿Existe algún descuento para niños?",
                    answer: "A partir de los 2 años (incluidos), no se aplica un descuento."
                },{
                    title: "¿Puedo viajar si tengo movilidad reducida o necesito alguna atención especial?",
                    answer: "Si. Perfectamente pero recuerda comentarlo en observaciones."
                },{
                    title: "¿Con qué compañía voy a volar?",
                    answer: "Trabajamos con todas las compañías aéreas, tradicionales y low cost, asi podemos tener mas opciones y combinaciones posibles."
                },{
                    title: "¿Qué tipo de alojamiento me puede tocar?",
                    answer: "Todos los hoteles, apartamentos, estudios o derivados estan seleccionados con sumo cuidado y céntricos o bien comunicados para llegar a él y siempre privados."
                },{
                    title: "¿Puedo obtener descuentos o promociones?",
                    answer: "Suscríbete para conocer todas las ofertas y promociones en nuestra newsletters."
                },{
                    title: "¿Me puede tocar un vuelo con escala?",
                    answer: "Si, dependiendo del aeropuerto de origen, el vuelo puede tener una escala para conseguir mejores horarios y combinaciones. Normalmente todos nuestros vuelos son directos."
                },{
                    title: "¿Tarjeta regalo RUBIKFLY ¿cómo la canjeo?",
                    answer: "En el apartado “CANJEA TU CHEQUE REGALO” podrás seguir los pasos que te indicamos."
                }
            ],
            down_img: "../imgs/angle-down-solid.svg",
            up_img: "../imgs/chevron-up-solid.svg",
        }
    },
    beforeMount(){
        window.scrollTo(0,0);
        
        this.$emit('hasPrice', 0);
    },
    methods:{
        openClose(key){
            let answer = document.getElementById("answer"+key);
            if(answer.classList.contains("hidden")){
                answer.classList.remove("hidden");
                this.toggleUpDown(key, this.up_img);
            }else{
                answer.classList.add("hidden");
                this.toggleUpDown(key, this.down_img);
            }
        },
        toggleUpDown(key, url){
            let img = document.getElementById("img"+key);
            img.src = url;
            if(url == this.up_img){
                img.style.paddingTop = "4px";
            }else{
                img.style.paddingTop = "0";
            }
        },
    }
}
</script>