<template>
    <div class="privacy-policy">
        <h1>Politica de privacidad</h1>
        <p>
            En cumplimiento del Capítulo II de la ley 34/2002, LSSICE, los informamos que la presente página web es propiedad de Tours and Transfers Online S.L,
            de ahora en adelante también el Prestador, domiciliada a calle Degollador nº 8, Ciutadella de Menorca (ILLES BALEARS), con CIF B-01648401, teléfono de
            contacto +34631387514 y email: hola@rubikfly.com.
        </p>

        <p>
            <b>El prestador</b>, como responsable del presente Sitio web y en conformidad con lo que dispone la normativa vigente en materia de protección de datos personales,
            el Reglamento (UE) 2016/679 de 27 de abril de 2016 (RGPD) relativo a la protección de las personas físicas en cuanto al tratamiento de datos personales y a la libre
            circulación de estos datos, la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD) y por la Ley 34/2002, de 11 de julio,
            de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSICE), tiene implementadas todas las medidas de seguridad, de índole técnica y organizativas,
            establecidas en el Real decreto 1720/2007 de 21 de Diciembre, (que desarrolla la LOPD) para garantizar y proteger la confidencialidad, integridad y disponibilidad de
            los datos introducidos.
        </p>

        <p>
            A efectos de lo que prevé la LOPD, <b>el prestador</b> le informa que los datos que voluntariamente nos está facilitando serán incorporadas a nuestros sistemas de
            información con el fin de realizar las gestiones comerciales y administrativas necesarias con los usuarios de la web; Las operaciones previstas para realizar los
            tratamientos son los siguientes: responder a las consultas y/o proporcionar informaciones requeridas por el Usuario; realizar las prestaciones de servicios y/o productos
            contratados o subscritos por el Usuario; realizar todas aquellas actividades propias de <b>el prestador</b> por el presente aviso legal reseñadas y remitir el boletín
            de noticias de la página web.
        </p>

        <p>
            El firmante garantiza la veracidad de los datos aportados y se compromete a comunicar cualquier cambio que se produzca en los mismos.
        </p>

        <p>
            El Prestador, mediante asterisco (*) en las casillas correspondientes del formulario de contacto, le informa de esta obligatoriedad al Usuario,
            indicando qué datos son necesarios. Mediante la indicación e introducción de los datos, el Usuario otorga el consentimiento inequívoco a <b>el prestador </b>
            para que proceda al tratamiento de los datos facilitados en pro de las finalidades mencionadas.
        </p>

        <p>
            El no facilitar los datos personales solicitados o el no aceptar la presente política de protección de datos supone la imposibilidad de subscribirse,
            registrarse o recibir información de los productos y servicios del Prestador.
        </p>

        <p>
            En conformidad con lo que disponen las normativas vigentes en protección de datos personales, el Prestador está cumpliendo con todas las disposiciones de las normativas
            RGPD y LOPD para el tratamiento de los datos personales de su responsabilidad, y manifiestamente con los principios descritos al art. 5 del RGPD y al art. 4 de la LOPD,
            por los cuales son tratados de manera lícita, leal y transparente en relación con el interesado y adecuadas, pertinentes y limitados al que es necesario en relación con los
            fines para los cuales son tratados.
        </p>

        <p>
            El RESPONSABLE garantiza que ha implementado políticas técnicas y organizativas apropiadas para aplicar las medidas de seguridad que establecen el RGPD y la LOPD para proteger
            los derechos y libertades de los Usuarios.
        </p>
        <p>
            De acuerdo con estas normativas, pues, le informamos que tiene derecho a solicitar el acceso, rectificación, portabilidad y supresión de sus datos y la limitación
            y oposición a su tratamiento dirigiéndose a CALLE DEGOLLADOR 8, 07760 CIUTADELLA (ISLAS BALEARES), enviando un correo electrónico a
            <a href="mailto:hola@rubikfly.com">hola@rubikfly.com</a>,
            indicando como Asunto: “LOPD, Derechos ARCO”, y adjuntando fotocopia de su DNI o cualquier medio análogo en derecho, tal y como indica la ley.
            Tiene derecho a retirar el consentimiento prestado en cualquier momento. La retirada del consentimiento no afectará a la licitud del tratamiento efectuado
            antes de la retirada del consentimiento. También tiene derecho a presentar una reclamación ante la autoridad de control si considera que pueden haberse visto vulnerados
            sus derechos en relación a la protección de sus datos (agpd.es).
        </p>

        <h2>
            CONFIDENCIALIDAD Y CESIÓN DE DATOS A TERCEROS
        </h2>

        <p>
            Los datos que nos facilite se tratarán de forma confidencial. El Prestador ha adoptado todas las medidas técnicas y organizativas y todos los niveles de protección
            necesarios para garantizar la seguridad en el tratamiento de los datos y evitar su alteración, pérdida, robo, tratamiento o acceso no autorizado, de acuerdo el estado
            de la tecnología y naturaleza de los datos almacenados. Así mismo, se garantiza también que el tratamiento y registro en ficheros, programas, sistemas o equipos, locales
            y centros cumplen con los requisitos y condiciones de integridad y seguridad establecidas en la normativa vigente.
        </p>

        <p>
            <b>El prestador</b> no cederá los datos personales a terceros, excepto por obligación legal. Sin embargo, en el caso de ser cedidos a algún tercero se produciría
            una información previa solicitando el consentimiento expreso para tal cesión. La entidad responsable de la base de datos, así como los que intervengan en cualquier
            fase del tratamiento y/o las entidades a quienes se los haya comunicado -en todo caso siempre con la correspondiente autorización otorgada por el usuario-, están obligadas
            a observar el secreto profesional y a la adopción de los niveles de protección y las medidas técnicas y organizativas necesarias a su alcance para garantizar la seguridad
            de los datos de carácter personal, evitando, dentro de lo posible, accesos no autorizados,modificaciones ilícitas, substracciones y/o la pérdida de los datos, con objeto de
            procurar el correspondiente nivel de seguridad de los ficheros del Prestador, según la naturaleza y sensibilidad de los datos facilitados por los usuarios del presente Sitio
            <a href="https://navegaenbarco.com/">web</a>.
        </p>

        <h2>
            ACEPTACIÓN Y CONSENTIMIENTO
        </h2>

        <p>
            El Usuario declara haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptando y consintiendo el tratamiento automatizado
            de los mismos por parte de <b>el prestador</b> en la forma y para las finalidades indicadas en la presente Política de Protección de Datos Personales.
        </p>
        <p>
            A través de esta Política de Privacidad le informamos que las fotografías que estén colgadas en la web son propiedad de <b>el prestador</b>, incluyendo las de los menores, en las que,
            para la obtención de estas, se ha obtenido el consentimiento previo de los padres, tutores o representantes legales mediante la firma de los formularios realizados al efecto por
            los centros en los cuales los menores forman parte. Sin embargo, los padres, tutores o representantes de los menores, como titulares del ejercicio de los derechos de estos, y
            siempre previo requerimiento formal por escrito, pueden indicar la negativa al uso de la imagen del menor; en este caso, la imagen se mostrará pixelada.
        </p>

        <h2>
            EXACTITUD Y VERACIDAD DE LOS DATOS
        </h2>
        <p>
            El usuario es el único responsable de la veracidad y corrección de los datos que remita a <b>el prestador</b>, exonerando al Prestador de cualquier responsabilidad al
            respeto. Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y autenticidad de los datos personales facilitados, y se comprometen a mantenerlos
            debidamente actualizados. El usuario acepta proporcionar información completa y correcta en el formulario de registro o suscripción.
        </p>

        <h2>
            CONTENIDO DE LA WEB I ENLACES (LINKS)
        </h2>
        <p>
            <b>El prestador</b> se reserva el derecho a actualizar, modificar o eliminar la información contenida en la web, pudiendo, incluso, limitar o no permitir el acceso a la información.
        </p>
        <p>
            <b>El prestador</b> no asume ningún tipo de responsabilidad por la información contenida en las Webs de terceros a las que se pueda acceder por los "links" o enlaces desde
            cualquier página Web propiedad del Prestador. La presencia de "links" o enlaces sólo tienen finalidad informativa y en ningún caso supone ninguna sugerencia, invitación
            o reconocimiento sobre los mismos.
        </p>

        <h2>
            SUBSCRIPCIÓN AL BLOG DE TOURS AND TRANSFERS ONLINE S.L.
        </h2>
        <p>
            En el supuesto de que el usuario se subscriba al blog, le informamos que los datos aportados serán tratados para gestionar su suscripción al blog informativo con aviso de
            actualización y que se conservarán mientras haya un interés mutuo para mantener el fin del tratamiento. Cuando ya no sea necesario para tal fin, se suprimirán con medidas
            de seguridad adecuadas para garantizar la seudonimización de los datos o destrucción total de los mismos. No se comunicarán los datos a terceros, excepto obligación legal.
        </p>

        <h2>
            PARTICIPACIÓN EN FOROS
        </h2>
        <p>
            En el supuesto de que el usuario quiera participar en los foros de la web, le informamos que los datos aportados serán tratados para regular la participación en el mismo.
            Cualquier registro o transmisión de datos personales que el usuario de este foro facilite supone el conocimiento y aceptación inequívoca del
            <a href="/aviso-legal">Aviso Legal</a>
            y la Política de Privacidad
            expuestos en la página web. Los datos se conservarán mientras haya un interés mutuo para mantener el fin del tratamiento y cuando ya no sea necesario para tal fin, se suprimirán
            con medidas de seguridad adecuadas para garantizar la seudonimización de los datos o destrucción total de los mismos. Las publicaciones se mostrarán públicamente a los usuarios
            del foro online.
        </p>

        <h2>
            PUBLICACIÓN DE SU TESTIMONIO
        </h2>
        <p>
            En el supuesto de que el usuario quiera publicar su opinión en la web, le informamos que los datos aportados serán tratados serán para atender las sugerencias propuestas,
            experiencias u opiniones respecto de los productos y/o servicios para ser publicadas en la página web y así poder ayudar a otros usuarios. Los datos se conservarán mientras
            haya un interés mutuo para mantener el fin del tratamiento y cuando ya no sea necesario para tal fin, se suprimirán con medidas de seguridad adecuadas para garantizar la
            seudonimización de los datos o destrucción total de los mismos. Los testigos se publicarán en nuestra página web. El único dato personal que se publicará del testigo será su nombre.
        </p>

        <h2>
            CAMBIOS EN LA PRESENTE POLÍTICA DE PRIVACIDAD
        </h2>
        <p>
            <b>El prestador</b> se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales.
        </p>

        <h2>
            CORREOS COMERCIALES
        </h2>
        <p>
            De acuerdo con la LSSICE, <b>el prestador</b> no realiza prácticas de SPAM, por lo tanto, no envía correos comerciales por e-mail que no hayan sido previamente solicitados o
            autorizados por el Usuario. En consecuencia, en cada uno de los formularios de la Página Web, el Usuario tiene la posibilidad de dar su consentimiento expreso para recibir
            nuestro Boletín, con independencia de la información comercial puntualmente solicitada.
        </p>

        <h2>
            LEGISLACIÓN
        </h2>
        <p>
            A todos los efectos las relaciones entre <b>el prestador</b> con los Usuarios de sus servicios telemáticos, presentes en esta Web, están sometidos a la legislación y
            jurisdicción española a la que se someten expresamente las partes, siendo competentes por la resolución de todos los conflictos derivados o relacionados con su uso
            los Juzgados y Tribunales de la comunidad autónoma en cuestión.
        </p>
    </div>
</template>
<script>
export default {
    beforeMount(){
        this.$emit('hasPrice', 0);
    }
}
</script>
