<template>
    <div class="finalize-component">
        <div class="breadcrumb-container">
            <ul class="breadcrumb">
                <li><router-link to="/">Inicio</router-link></li>
                <li><router-link to="/calendar">Fechas</router-link></li>
                <li><router-link to="/destinos">Destinos</router-link></li>
                <li><router-link to="/extras">Extras</router-link></li>
            </ul>
        </div>
        <div class="finalize-container">
            <div class="contact-data-container">
                <div class="contact-data">
                    <h3>Datos de contacto</h3>

                    <div class="name-surname-grid">
                        <div>
                            <div>
                                <label for="client-name">Nombre</label>
                            </div>
                            <div class="client-name">
                                <input type="text" id="client-name" name="client-name" required>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label for="client-surname">Apellidos</label>
                            </div>
                            <div class="client-surname">
                                <input type="text" id="client-surname" name="client-surname" required>
                            </div>
                        </div>
                    </div>
                    <div class="contact-data-grid">
                        <div>
                            <div>
                                <label for="email">Email</label>
                            </div>
                            <div>
                                <input type="email" id="email" name="email" required>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label for="phone">Teléfono</label>
                            </div>
                            <div>
                                <input type="text" id="phone" name="phone" required>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-for="(adult, key) in adults" :key="'adult' + key" class="adults">
                    <div class="traveler-header">
                        <h3>Adulto - Pasajero {{ key + 1 }}</h3>
                        <div v-if="!is_gift" @click="openForm(key)">
                            <img src="/imgs/angle-down-solid.svg" alt="" :id="'open-traveler' + key">
                        </div>
                    </div>
                    <div :class="key + 1 > 2 ? 'hidden traveler-form' : 'traveler-form'" :id="'form' + key">
                        <div>
                            <input type="radio" :name="'gender' + key" value="Hombre" checked> <label
                                for="Hombre">Hombre</label>
                            <input type="radio" :name="'gender' + key" value="Mujer"> <label for="Mujer">Mujer</label>
                        </div>
                        <div>
                            <div class="name-container">
                                <div>
                                    <label for="name">Nombre:</label>
                                </div>
                                <div>
                                    <input type="text" class="name" name="name" required>
                                </div>
                            </div>
                            <div class="surname-container">
                                <div>
                                    <label for="surname">Apellidos:</label>
                                </div>
                                <div>
                                    <input type="text" name="surname" required>
                                </div>

                            </div>
                        </div>
                        <div>
                            <label for="birthday">Fecha de nacimiento:</label>
                            <div class="birthday-container">
                                <div>
                                    <input type="number" name="birthday-day" min="1" max="31" placeholder="dd" required>
                                </div>
                                <div>
                                    <input type="number" name="birthday-month" min="1" max="12" placeholder="mm"
                                        required>
                                </div>
                                <div>
                                    <input type="number" name="birthday-year" min="1800" :max="current_year"
                                        placeholder="aaaa" required>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="nationality-container">
                                <div>
                                    <label for="nacionality">Nacionalidad:</label>
                                </div>
                                <div>
                                    <input type="text" name="nationality" required>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label for="document">Documento de identidad:</label>
                            <div class="document-container">
                                <select name="document-type" class="document-type" id="">
                                    <option value="DNI">DNI</option>
                                    <option value="Pasaporte">Pasaporte</option>
                                </select>
                                <input class="document-number" type="text" name="document" required>
                            </div>
                        </div>
                        <div>
                            <label for="expire-date">Fecha de expiración:</label>
                            <div class="expire-date-container">
                                <div>
                                    <input type="number" name="expire-date-day" min="1" max="31" placeholder="dd"
                                        required>
                                </div>
                                <div>
                                    <input type="number" name="expire-date-month" min="1" max="12" placeholder="mm"
                                        required>
                                </div>
                                <div>
                                    <input class="expire-date-year" type="number" name="expire-date-year"
                                        :min="current_year" placeholder="aaaa" required>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-for="(kid, key) in kids" :key="'kid' + key" class="kids">
                    <div class="traveler-header">
                        <h3>Niño - Pasajero {{ key + 1 + adults }}</h3>
                        <div v-if="!is_gift" @click="openKidForm(key)">
                            <img src="/imgs/angle-down-solid.svg" alt="" :id="'open-kid-traveler' + key">
                        </div>
                    </div>
                    <div :class="key + 1 + adults > 2 ? 'hidden traveler-form' : 'traveler-form'"
                        :id="'kid-form' + key">
                        <div>
                            <input type="radio" :name="'genderkid' + key" value="Hombre" checked> <label
                                for="Hombre">Hombre</label>
                            <input type="radio" :name="'genderkid' + key" value="Mujer"> <label
                                for="Mujer">Mujer</label>
                        </div>
                        <div>
                            <div class="name-container">
                                <div>
                                    <label for="name">Nombre:</label>
                                </div>
                                <div>
                                    <input type="text" name="name" required>
                                </div>
                            </div>
                            <div class="surname-container">
                                <div>
                                    <label for="surname">Apellidos:</label>
                                </div>
                                <div>
                                    <input type="text" name="surname" required>
                                </div>

                            </div>
                        </div>
                        <div>
                            <label for="birthday">Fecha de nacimiento:</label>
                            <div class="birthday-container">
                                <div>
                                    <input type="number" name="birthday-day" min="1" max="31" placeholder="dd" required>
                                </div>
                                <div>
                                    <input type="number" name="birthday-month" min="1" max="12" placeholder="mm"
                                        required>
                                </div>
                                <div>
                                    <input type="number" name="birthday-year" min="1800" :max="current_year"
                                        placeholder="aaaa" required>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="nationality-container">
                                <div>
                                    <label for="nacionality">Nacionalidad:</label>
                                </div>
                                <div>
                                    <input type="text" name="nationality" required>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label for="document">Documento de identidad:</label>
                            <div class="document-container">
                                <select name="document-type" class="document-type" id="">
                                    <option value="DNI">DNI</option>
                                    <option value="Pasaporte">Pasaporte</option>
                                </select>
                                <input class="document-number" type="text" name="document">
                            </div>
                        </div>
                        <div>
                            <label for="expire-date">Fecha de expiración:</label>
                            <div class="expire-date-container">
                                <div>
                                    <input type="number" name="expire-date-day" min="1" max="31" placeholder="dd">
                                </div>
                                <div>
                                    <input type="number" name="expire-date-month" min="1" max="12" placeholder="mm">
                                </div>
                                <div>
                                    <input class="expire-date-year" type="number" name="expire-date-year"
                                        :min="current_year" placeholder="aaaa">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-for="(baby, key) in babies" :key="'baby' + key" class="babies">
                    <div class="traveler-header">
                        <h3>Bebé - Pasajero {{ key + 1 + adults + kids }}</h3>
                        <div v-if="!is_gift" @click="openBabyForm(key)">
                            <img src="/imgs/angle-down-solid.svg" alt="" :id="'open-baby-traveler' + key">
                        </div>
                    </div>
                    <div :class="key + 1 + adults + kids > 2 ? 'hidden traveler-form' : 'traveler-form'"
                        :id="'baby-form' + key">
                        <div>
                            <input type="radio" :name="'genderbaby' + key" value="Hombre" checked> <label
                                for="Hombre">Hombre</label>
                            <input type="radio" :name="'genderbaby' + key" value="Mujer"> <label
                                for="Mujer">Mujer</label>
                        </div>
                        <div>
                            <div class="name-container">
                                <div>
                                    <label for="name">Nombre:</label>
                                </div>
                                <div>
                                    <input type="text" name="name" required>
                                </div>
                            </div>
                            <div class="surname-container">
                                <div>
                                    <label for="surname">Apellidos:</label>
                                </div>
                                <div>
                                    <input type="text" name="surname" required>
                                </div>

                            </div>
                        </div>
                        <div>
                            <label for="birthday">Fecha de nacimiento:</label>
                            <div class="birthday-container">
                                <div>
                                    <input type="number" name="birthday-day" min="1" max="31" placeholder="dd" required>
                                </div>
                                <div>
                                    <input type="number" name="birthday-month" min="1" max="12" placeholder="mm"
                                        required>
                                </div>
                                <div>
                                    <input type="number" name="birthday-year" min="1800" :max="current_year"
                                        placeholder="aaaa" required>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="nationality-container">
                                <div>
                                    <label for="nacionality">Nacionalidad:</label>
                                </div>
                                <div>
                                    <input type="text" name="nationality" required>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label for="document">Documento de identidad:</label>
                            <div class="document-container">
                                <select name="document-type" class="document-type" id="">
                                    <option value="DNI">DNI</option>
                                    <option value="Pasaporte">Pasaporte</option>
                                </select>
                                <input class="document-number" type="text" name="document">
                            </div>
                        </div>
                        <div>
                            <label for="expire-date">Fecha de expiración:</label>
                            <div class="expire-date-container">
                                <div>
                                    <input type="number" name="expire-date-day" min="1" max="31" placeholder="dd">
                                </div>
                                <div>
                                    <input type="number" name="expire-date-month" min="1" max="12" placeholder="mm">
                                </div>
                                <div>
                                    <input class="expire-date-year" type="number" name="expire-date-year"
                                        :min="current_year" placeholder="aaaa">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="observations">
                    <label for="observations">Observaciones</label><br>
                    <textarea name="observations" id="observations" class="observations__textarea"
                        placeholder="¡Dinos alguna cosa que te resulte importante, todo lo que nos digas nos ayudará para poder ofrecerte el mejor viaje sorpresa!"
                        rows="10"></textarea>
                </div>
            </div>
            <aside class="booking-data">
                <h4>TU RESERVA</h4>
                <div class="booking-container">
                    <h5>INFORMACIÓN DEL VIAJE</h5>
                    <p>{{ adults }} viajeros adultos</p>
                    <p v-if="kids > 0">{{ kids }} viajeros niños</p>
                    <p v-if="babies > 0">{{ babies }} viajeros bebés</p>
                </div>
                <div class="booking-container">
                    <h5>VUELO DE IDA: {{ departure_date }}</h5>
                    <p>Entre las {{ departure_hour.start }} y las {{ departure_hour.end }}</p>
                    <div class="flex-gap-20">
                        <span class="bold">{{ origin }}</span> <span><img src="/imgs/plane-solid.svg" alt=""></span>
                        <span class="bold">SORPRESA</span>
                    </div>
                </div>
                <div class="booking-container">
                    <h5>VUELO DE VUELTA: {{ return_date }}</h5>
                    <p>Entre las {{ return_hour.start }} y las {{ return_hour.end }}</p>
                    <div class="flex-gap-20">
                        <span class="bold">SORPRESA</span> <span><img src="/imgs/plane-solid.svg" alt=""></span> <span
                            class="bold">{{ origin }}</span>
                    </div>
                </div>
                <div v-if="discarded_destinations.length > 0" class="booking-container">
                    <h5>DESTINOS DESCARTADOS</h5>
                    <ul>
                        <li v-for="(discarded_destination, key) in discarded_destinations" :key="'destination' + key">
                            {{ discarded_destination }}</li>
                    </ul>
                    <p><b>Precio:</b> <span class="price-text">{{ discarded_price }}€</span></p>
                </div>
                <div class="booking-container">
                    <h5>EXTRAS</h5>
                    <p>
                        <b>Hotel:</b> {{ hotel }}
                        <span v-if="hotel_price > 0"> - <span class="price-text">{{ hotel_price }}€</span></span>
                    </p>
                    <p>
                        <b>Seguro de cancelación:</b> {{ cancelation_insurance ? "Si" : "No" }}
                        <span v-if="cancelation_insurance"> - <span class="price-text">{{
                    cancelation_insurance_price.toFixed(2) }}€</span></span>
                    </p>
                    <p style="display: none;">
                        <b>Seguro de viaje:</b> {{ trip_insurance ? "Si" : "No" }}
                        <span v-if="trip_insurance"> - <span class="price-text">{{ trip_insurance_price
                                }}€</span></span>
                    </p>
                    <p>
                        <b>Viajar juntos:</b> {{ seat_together ? "Si" : "No" }}
                        <span v-if="seat_together"> - <span class="price-text">{{ seat_price }}€</span></span>
                    </p>
                    <p>
                        <b>Maletas:</b> {{ baggage }}
                        <span v-if="baggage > 0"> - <span class="price-text">{{ baggage_price }}€</span></span>
                    </p>
                    <div v-if="departure_hour_price > 0 || return_hour_price > 0">
                        <p><b>Modificación del horario de vuelo</b></p>
                        <p v-if="departure_hour_price > 0"> Ida: <span class="price-text">{{ departure_hour_price
                                }}€</span></p>
                        <p v-if="return_hour_price > 0"> Vuelta: <span class="price-text">{{ return_hour_price
                                }}€</span>
                        </p>
                    </div>
                </div>
                <div class="booking-container">
                    <p><b>Precio total:</b>
                    <span v-if="!discount_period" class="price-text">{{ total_price.toFixed(2) }}€</span>
                    <span v-if="discount_period" class="price-text" style="text-decoration: line-through;">{{ (total_price + 30).toFixed(2) }} € </span>
                    <span v-if="discount_period" class="price-text">{{ total_price.toFixed(2) }}€</span>;
                    </p>

                </div>
            </aside>
        </div>
        <div v-if="is_gift">
            <p class="gift-message">
                Te mandaremos la tarjeta de regalo personalizada a tu email, que incluirá un código para poder canjear
                el viaje sorpresa.
            </p>
        </div>
        <div class="accept-terms-container">
            <input v-if="terms" @change="toggleAcceptTerms()" type="checkbox" name="accept-terms" id="accept-terms"
                checked>
            <input v-else @change="toggleAcceptTerms()" type="checkbox" name="dont-accept-terms" id="accept-terms">
            Acepto los <a target="v_blank" href="imgs/TerminosYCondiciones.pdf">terminos y condiciones.</a>
        </div>
        <!-- <div class="finalize-button-container">
            <button @click="finalize('euros')" class="disabled-button" id="finalize-button">Pagar con visa/masasdfasdftercard</button>
        </div> -->
        <!-- <div class="finalize-button-container">
            <button @click="finalize('euros')" class="disabled-button" id="finalize-button">Pagar con visa/mastercard</button>
        </div> -->
        <div class="finalize-button-container">
            <button @click="finalize('')" class="disabled-button" id="finalize-button">Enviar petición</button>
        </div>
        <!-- <div class="finalize-button-container">
            <select name="criptos" id="criptos" @change="chooseCripto()">
                <option v-for="(cripto, key) in criptos" :key="'cripto'+key" :value="cripto.symbol">{{cripto.name}}</option>
            </select>
            <button @click="finalize('criptos')" class="disabled-button" id="cripto-button">Pagar con criptos</button>
        </div> -->
        <div>
            <p :class="error_message ? 'finalize-message error-message' : 'finalize-message'">{{ message }}</p>
        </div>
        <form style="display: none" action="/payment" method="POST" id="form">
            <input type="hidden" name="_token" :value="csrf">
            <input type="hidden" id="bookingid" name="bookingid" value="" />
        </form>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            people: 0,
            adults: 0,
            kids: 0,
            babies: 0,
            current_year: 0,
            departure_date: null,
            return_date: null,
            departure_hour: null,
            return_hour: null,
            origin: "",
            destination: "",
            discarded_destinations: null,
            hotel: "",
            cancelation_insurance: false,
            trip_insurance: false,
            baggage: 0,
            seat_together: false,
            is_gift: false,
            origin_price: 0,
            day_price: 0,
            discarded_price: 0,
            departure_hour_price: 0,
            return_hour_price: 0,
            cancelation_insurance_price: 0,
            trip_insurance_price: 0,
            hotel_price: 0,
            baggage_price: 0,
            seat_price: 0,
            total_price: 0,
            message: "",
            error_message: false,
            terms: false,
            days: 3,
            extra_days_price: 0,
            discarded_base_price: 0,
            high_hotel_base_price: 0,
            trip_insurance_base_price: 0,
            cancellation_insurance_base_price: 0,
            departure_base_price: 0,
            return_base_price: 0,
            baggage_base_price: 0,
            extra_day_base_price: 0,
            seat_together_base_price: 0,
            criptos: [],
            cripto: '',
            payment_method: 'euros',
            discount_period: false,
            final_price: 0,
        }
    },
    computed: {
        csrf() {
            return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        },
        checked() {
            return this.terms;
        },
        isEuro() {
            return this.payment_method == 'euros';
        },
        // isDiscountPeriod() {
        //     let now = new Date();
        //     let startDiscountDate = new Date(today.getFullYear(), 2, 8); // March is 2 in JavaScript Date
        //     let endDiscountDate = new Date(today.getFullYear(), 2, 10);
        //     console.log(now, now >= startDiscountDate && now <= endDiscountDate);
        //     return this.discount_period = now >= startDiscountDate && now <= endDiscountDate;
        // },
    },
    beforeMount() {
        let mainInfo = JSON.parse(localStorage.getItem("main-data"));
        let calendarInfo = JSON.parse(localStorage.getItem("calendar-data"));
        let destinationsInfo = JSON.parse(localStorage.getItem("destinations-data"));
        let extrasInfo = JSON.parse(localStorage.getItem("extras-data"));
        let today = new Date();
        let now = new Date();
        this.current_year = now.getFullYear();
        this.terms = false;

        let startDiscountDate = new Date(today.getFullYear(), 2, 8); // March is 2 in JavaScript Date
        let endDiscountDate = new Date(today.getFullYear(), 2, 10);

        this.discount_period = now >= startDiscountDate && now <= endDiscountDate;


        if (!mainInfo || !calendarInfo || !destinationsInfo || !extrasInfo) {
            this.$router.push({ name: 'Home' });
        } else if (mainInfo.expires < now.getTime() || calendarInfo.expires < now.getTime() || destinationsInfo.expires < now.getTime() || extrasInfo.expires < now.getTime()) {
            localStorage.removeItem('main-data');
            localStorage.removeItem('calendar-data');
            localStorage.removeItem('destinations-data');
            localStorage.removeItem('extras-data');
            this.$router.push({ name: 'Home' });
        } else {
            //this.getExtraPrices();
            this.people = mainInfo.adults + mainInfo.kids + mainInfo.babies;
            this.adults = mainInfo.adults;
            this.kids = mainInfo.kids;
            this.babies = mainInfo.babies;
            let departure = new Date(calendarInfo.info[0]);
            let departure_day = departure.getDate();
            let departure_month = departure.getMonth() + 1;
            let departure_year = departure.getFullYear();
            this.departure_date = `${departure_day}-${departure_month}-${departure_year}`;
            let date_return = new Date(calendarInfo.info[calendarInfo.info.length - 1]);
            let return_day = date_return.getDate();
            let return_month = date_return.getMonth() + 1;
            let return_year = date_return.getFullYear();
            this.return_date = `${return_day}-${return_month}-${return_year}`;
            this.days = parseInt(mainInfo.days_num);

            this.departure_hour = {
                start: extrasInfo.departure_hour_range.start,
                end: extrasInfo.departure_hour_range.end,
            }

            this.return_hour = {
                start: extrasInfo.return_hour_range.start,
                end: extrasInfo.return_hour_range.end,
            }

            this.origin = mainInfo.origin;

            this.destination = mainInfo.destination;

            this.discarded_destinations = destinationsInfo.discarted;

            this.hotel = extrasInfo.hotel;
            this.cancelation_insurance = extrasInfo.cancellation_insurance;
            this.trip_insurance = extrasInfo.trip_insurance;
            this.baggage = extrasInfo.baggage_num;
            this.is_gift = mainInfo.gift;
            this.seat_together = extrasInfo.seat_together;

            this.origin_price = mainInfo.origin_price * this.people;

            this.day_price = calendarInfo.plus_price;
            this.discarded_price = destinationsInfo.extra_price;
            this.prova(extrasInfo);
            this.$emit('hasPrice', 0);
            //this.calculateFinalPrice(extrasInfo);
        }

        this.payment_method == 'euros';
        let timestamp = now.getTime();
        axios.get("https://payments.smsdata.com/api/v1/currencies",
            {
                headers: {
                    'X-NONCE': timestamp,
                    'X-SIGNATURE': process.env.MIX_X_SIGNATURE,
                    'X-Merchant-Id': process.env.MIX_X_MERCHANT_ID
                }
            }
        )
            .then(res => {
                this.criptos = res.data;
            })





        window.scrollTo(0, 0);
    },
    mounted() {
        this.terms = false;
    },
    methods: {
        chooseCripto() {
            this.cripto = document.getElementById("criptos").value;
        },
        async getExtraPrices() {
            let departure_base_price = await axios.get('/extra-price/Descarte ida');
            this.departure_base_price = departure_base_price.data.price;

            let return_base_price = await axios.get('/extra-price/Descarte vuelta');
            this.return_base_price = return_base_price.data.price;

            let trip_insurance_base_price = await axios.get('/extra-price/Seguro de Viaje')
            this.trip_insurance_base_price = trip_insurance_base_price.data.price;

            let cancellation_insurance_base_price = await axios.get('/extra-price/Seguro de Cancelacion')
            this.cancellation_insurance_base_price = cancellation_insurance_base_price.data.price;

            let baggage_base_price = await axios.get('/extra-price/Equipaje')
            this.baggage_base_price = baggage_base_price.data.price;


            let high_hotel_base_price = await axios.get('/extra-price/Hotel Superior')
            this.high_hotel_base_price = high_hotel_base_price.data.price;

            let discarded_base_price = await axios.get('/extra-price/Destino Descartado')
            this.discarded_base_price = discarded_base_price.data.price;

            let extra_day_base_price = await axios.get('/extra-price/Dia extra')

            this.extra_day_base_price = extra_day_base_price.data.price != undefined ? extra_day_base_price.data.price : 0;

            let seat_together_base_price = await axios.get('/extra-price/Asientos Juntos')
            this.seat_together_base_price = seat_together_base_price.data.price;
        },
        async prova(extrasInfo) {
            await this.getExtraPrices();
            //console.log(this.trip_insurance_base_price);
            this.calculateFinalPrice(extrasInfo);
        },
        openForm(key) {
            let element = document.getElementById("form" + key);
            let img = document.getElementById("open-traveler" + key);
            if (element.classList.contains("hidden")) {
                element.classList.remove("hidden");
                img.src = "/imgs/chevron-up-solid.svg"

            } else {
                element.classList.add("hidden");
                img.src = "/imgs/angle-down-solid.svg"
            }

        },
        openKidForm(key) {
            let element = document.getElementById("kid-form" + key);
            let img = document.getElementById("open-kid-traveler" + key);
            if (element.classList.contains("hidden")) {
                element.classList.remove("hidden");
                img.src = "/imgs/chevron-up-solid.svg"

            } else {
                element.classList.add("hidden");
                img.src = "/imgs/angle-down-solid.svg"
            }

        },
        openBabyForm(key) {
            let element = document.getElementById("baby-form" + key);
            let img = document.getElementById("open-baby-traveler" + key);
            if (element.classList.contains("hidden")) {
                element.classList.remove("hidden");
                img.src = "/imgs/chevron-up-solid.svg"

            } else {
                element.classList.add("hidden");
                img.src = "/imgs/angle-down-solid.svg"
            }

        },
        calculateFinalPrice(extrasInfo) {
            let departure_start = parseInt(extrasInfo.departure_hour_range.start.split(":")[0]);
            let departure_end = parseInt(extrasInfo.departure_hour_range.end.split(":")[0]);
            let departure_hours = departure_end - departure_start;

            if (departure_hours >= 18) {
                this.departure_hour_price = 0;
            } else if (departure_hours >= 12) {
                this.departure_hour_price = this.departure_base_price * this.people;
            } else {
                this.departure_hour_price = this.departure_base_price * 2 * this.people;
            }

            let return_start = parseInt(extrasInfo.return_hour_range.start.split(":")[0]);
            let return_end = parseInt(extrasInfo.return_hour_range.end.split(":")[0]);
            let return_hours = return_end - return_start;

            if (return_hours >= 18) {
                this.return_hour_price = 0;
            } else if (return_hours >= 12) {
                this.return_hour_price = this.return_base_price * this.people;
            } else {
                this.return_hour_price = this.return_base_price * 2 * this.people;
            }

            this.trip_insurance_price = this.trip_insurance ? this.trip_insurance_base_price * this.people : 0;
            //console.log(this.trip_insurance_base_price, this.trip_insurance_price);

            this.hotel_price = this.hotel == "3 o 4 estrellas" ? 0 : this.high_hotel_base_price * this.people;

            this.seat_price = !this.seat_together ? 0 : this.seat_together_base_price * this.people * 2;

            this.baggage_price = this.baggage_base_price * this.baggage * 2;

            this.extra_days_price = (this.days - 3) * this.people * this.extra_day_base_price;

            // console.log(this.extra_day_base_price);
            let booking_total = this.origin_price + this.day_price + this.discarded_price + this.departure_hour_price + this.return_hour_price + this.trip_insurance_price + this.hotel_price + this.baggage_price + this.extra_days_price + this.seat_price;
            // console.log(booking_total);
            // console.log(this.discount_period);
            if(this.discount_period){
                booking_total = booking_total - 30;
            }
            this.cancelation_insurance_price = this.cancelation_insurance ? booking_total * this.cancellation_insurance_base_price / 100 : 0;

            // console.log(this.cancelation_insurance_price + " " + booking_total);
            this.total_price = booking_total + this.cancelation_insurance_price;
        },
        finalize(coin) {
            if (!this.terms) {
                return;
            }

            this.payment_method = coin;

            let observations = document.getElementById("observations").value;

            let bookingInfo = {
                "destination": this.destination,
                "airport": this.origin,
                "departure_date": this.departure_date,
                "return_date": this.return_date,
                "discarded": this.discarded_destinations,
                "hotel": this.hotel,
                "cancellation_insurance": this.cancelation_insurance,
                "trip_insurance": this.trip_insurance,
                "seat_together": this.seat_together,
                "departure_range": this.departure_hour,
                "return_range": this.return_hour,
                "is_gift": this.is_gift,
                "baggage": this.baggage,
                "days": this.days,
                "observations": observations
            };


            let email = document.getElementById("email").value;
            let phone = document.getElementById("phone").value;
            let client_name = document.getElementById("client-name").value;
            let client_surname = document.getElementById("client-surname").value;

            let clientInfo = {
                "email": email,
                "phone": phone,
                "name": client_name,
                "surname": client_surname,
            };

            let adults = document.querySelectorAll(".adults");
            let adultsInfo = [];
            for (let i = 0; i < this.adults; i++) {

                let gender = adults[i].querySelector("input[name='gender" + i + "']:checked").value;

                let name = adults[i].querySelector("input[name='name']").value;

                let surname = adults[i].querySelector("input[name='surname']").value;

                let birthday_day = adults[i].querySelector("input[name='birthday-day']").value;

                let birthday_month = adults[i].querySelector("input[name='birthday-month']").value;

                let birthday_year = adults[i].querySelector("input[name='birthday-year']").value;

                let nationality = adults[i].querySelector("input[name='nationality']").value;

                let document_type = adults[i].querySelector(".document-type").value;

                let document = adults[i].querySelector("input[name='document']").value;

                let expire_day = adults[i].querySelector("input[name='expire-date-day']").value;

                let expire_month = adults[i].querySelector("input[name='expire-date-month']").value;

                let expire_year = adults[i].querySelector("input[name='expire-date-year']").value;

                let adultInfo = {
                    "gender": gender,
                    "name": name,
                    "surname": surname,
                    "birthday-day": birthday_day,
                    "birthday-month": birthday_month,
                    "birthday-year": birthday_year,
                    "nationality": nationality,
                    "document-type": document_type,
                    "document": document,
                    "expire-date-day": expire_day,
                    "expire-date-month": expire_month,
                    "expire-date-year": expire_year
                };

                adultsInfo.push(adultInfo);
            }

            let kids = document.querySelectorAll(".kids");
            let kidsInfo = [];
            for (let i = 0; i < this.kids; i++) {

                let gender = kids[i].querySelector("input[name='genderkid" + i + "']:checked").value;

                let name = kids[i].querySelector("input[name='name']").value;

                let surname = kids[i].querySelector("input[name='surname']").value;

                let birthday_day = kids[i].querySelector("input[name='birthday-day']").value;

                let birthday_month = kids[i].querySelector("input[name='birthday-month']").value;

                let birthday_year = kids[i].querySelector("input[name='birthday-year']").value;

                let nationality = kids[i].querySelector("input[name='nationality']").value;

                let document_type = kids[i].querySelector(".document-type").value;

                let document = kids[i].querySelector("input[name='document']").value;

                let expire_day = kids[i].querySelector("input[name='expire-date-day']").value;

                let expire_month = kids[i].querySelector("input[name='expire-date-month']").value;

                let expire_year = kids[i].querySelector("input[name='expire-date-year']").value;

                let kidInfo = {
                    "gender": gender,
                    "name": name,
                    "surname": surname,
                    "birthday-day": birthday_day,
                    "birthday-month": birthday_month,
                    "birthday-year": birthday_year,
                    "nationality": nationality,
                    "document-type": document_type,
                    "document": document,
                    "expire-date-day": expire_day,
                    "expire-date-month": expire_month,
                    "expire-date-year": expire_year
                };

                kidsInfo.push(kidInfo);
            }

            let babies = document.querySelectorAll(".babies");
            let babiesInfo = [];
            for (let i = 0; i < this.babies; i++) {

                let gender = babies[i].querySelector("input[name='genderbaby" + i + "']:checked").value;

                let name = babies[i].querySelector("input[name='name']").value;

                let surname = babies[i].querySelector("input[name='surname']").value;

                let birthday_day = babies[i].querySelector("input[name='birthday-day']").value;

                let birthday_month = babies[i].querySelector("input[name='birthday-month']").value;

                let birthday_year = babies[i].querySelector("input[name='birthday-year']").value;

                let nationality = babies[i].querySelector("input[name='nationality']").value;

                let document_type = babies[i].querySelector(".document-type").value;

                let document = babies[i].querySelector("input[name='document']").value;

                let expire_day = babies[i].querySelector("input[name='expire-date-day']").value;

                let expire_month = babies[i].querySelector("input[name='expire-date-month']").value;

                let expire_year = babies[i].querySelector("input[name='expire-date-year']").value;

                let babyInfo = {
                    "gender": gender,
                    "name": name,
                    "surname": surname,
                    "birthday-day": birthday_day,
                    "birthday-month": birthday_month,
                    "birthday-year": birthday_year,
                    "nationality": nationality,
                    "document-type": document_type,
                    "document": document,
                    "expire-date-day": expire_day,
                    "expire-date-month": expire_month,
                    "expire-date-year": expire_year
                };

                babiesInfo.push(babyInfo);
            }

            let passengersInfo = {
                "adults": adultsInfo,
                "kids": kidsInfo,
                "babies": babiesInfo
            };


            axios.post("/booking", { "booking": bookingInfo, "client": clientInfo, "passengers": passengersInfo })
                .then((res) => {

                    if (res.data == "Error") {
                        this.error_message = true;
                        this.message = "Asegurate de haber rellenado todos los campos del formulario.";
                    } else {
                        document.getElementById("bookingid").value = res.data;

                        if (this.payment_method == 'peticion') {
                            // axios.post("/send-email", {"data":res.data});
                            // return window.location.href = '/';
                        }

                        if (this.payment_method == 'criptos') {
                            this.payWithCriptos(res.data);
                        } else {
                            document.getElementById("form").submit();
                        }
                        //window.location.href = '/payment/'+res.data;
                        this.error_message = false;
                        //this.message = "Muy pronto podrás reservar con nosotros, estamos en periodo de prueba";
                    }

                }).catch(e => {
                })

        },
        payWithCriptos(id) {
            axios.post("https://payments.smsdata.com/api/v1/orders/", {
                expected_output_amount: this.total_price,
                merchant_urlko: 'https://rubikfly.com/finaliza',
                merchant_urlok: 'https://rubikfly.com/'
            },
                {
                    headers: {
                        'X-Device-Id': process.env.MIX_X_MERCHANT_ID
                    }
                }).then(res => {

                    window.location.href = res.data.web_url;
                }).catch(e => {

                })
        },
        toggleAcceptTerms() {
            let button = document.getElementById("finalize-button");
            // let criptoButton = document.getElementById("cripto-button");
            let checkbox = document.getElementById("accept-terms");
            if (!checkbox.checked) {
                this.terms = false;
                button.classList.add("disabled-button");
                criptoButton.classList.add("disabled-button");
            } else {
                this.terms = true;
                button.classList.remove("disabled-button");
                // criptoButton.classList.remove("disabled-button");
            }
        }
    }
}
</script>
